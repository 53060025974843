// TODO: Remove once hook up with APIs
import { useCallback, useEffect, useMemo, useState } from 'react';
import List from '@components/list';
import {
  StyledContainer,
  StyledFileWrapper,
  StyledFormatWrapper,
  StyledHeaderContainer,
  StyledLastAddedWrapper,
  StyledListItemContainer,
  StyledPaginationButtonWrapper,
  StyledPaginationContainer,
  StyledSearchBarWrapper,
  StyledSourceWrapper,
} from '@components/pages/audit/sections/file-name/styles';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TextField from '@mui/material/TextField';
import { mockFileData, mockFileMoreData } from '../../mockData';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export interface FileListItemProps {
  file: string;
  format: string;
  source: string;
  lastAdded: string;
}

export function FileList() {
  const [pageNumber, setPageNumber] = useState(1);
  const fileData = useMemo(() => loadData(pageNumber), [pageNumber]);
  const [listItems, setListItems] = useState(fileData);
  const [searchValue, setsSearchValue] = useState('');
  const numberOfDisplayedItems = useMemo(() => 15, []);
  const maxPageNumber = useMemo(() => 5, []);
  const totalItems = useMemo(() => 75, []);
  const startItemNumber = useMemo(
    () => 1 + numberOfDisplayedItems * (pageNumber - 1),
    [numberOfDisplayedItems, pageNumber],
  );
  const endItemNumber = useMemo(
    () => numberOfDisplayedItems * pageNumber,
    [numberOfDisplayedItems, pageNumber],
  );

  const decreasePage = useCallback(() => {
    if (pageNumber <= 1) return;

    setPageNumber((prev) => prev - 1);
  }, [pageNumber]);

  const increasePage = useCallback(() => {
    if (pageNumber >= maxPageNumber) return;

    setPageNumber((prev) => prev + 1);
  }, [pageNumber, maxPageNumber]);

  const onSearch = useCallback(
    (value: string) => {
      value = value.toLowerCase();
      setListItems(fileData.filter((data) => data.file.toLowerCase().includes(value)));
      setsSearchValue(value);
    },
    [fileData],
  );

  useEffect(() => {
    onSearch(searchValue);
  }, [onSearch, searchValue]);

  return (
    <StyledContainer>
      <StyledHeaderContainer>
        <StyledSearchBarWrapper>
          <Autocomplete
            freeSolo
            sx={{ width: '100%' }}
            disableClearable
            options={[]}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  InputProps={{ ...params.InputProps, type: 'search' }}
                />
              );
            }}
          />
        </StyledSearchBarWrapper>
        <StyledPaginationContainer>
          <StyledPaginationButtonWrapper>
            <IconButton
              // color={
              //   pageNumber === 1
              //     ? Theme.Color.Neutrals.Border
              //     : Theme.Color.Brand.Primary
              // }
              onClick={() => decreasePage()}
            >
              <ChevronLeftIcon />
            </IconButton>
          </StyledPaginationButtonWrapper>

          <Typography variant="body1">{`${startItemNumber}-${endItemNumber}`}</Typography>
          <Typography variant="body1">of</Typography>
          <Typography variant="body1">{totalItems}</Typography>
          <StyledPaginationButtonWrapper>
            <IconButton
              // color={
              //   pageNumber === maxPageNumber
              //     ? Theme.Color.Neutrals.Border
              //     : Theme.Color.Brand.Primary
              // }
              onClick={() => increasePage()}
            >
              <ChevronRightIcon />
            </IconButton>
          </StyledPaginationButtonWrapper>
        </StyledPaginationContainer>
      </StyledHeaderContainer>
      <List header={<FileListHeader />} hasBorder={true}>
        {listItems.map((listItem: FileListItemProps, i) => (
          <FileListItem key={i} {...listItem} />
        ))}
      </List>
    </StyledContainer>
  );
}

function FileListHeader() {
  return (
    <StyledListItemContainer>
      <StyledFileWrapper>
        <Typography variant="body1">File</Typography>
      </StyledFileWrapper>
      <StyledFormatWrapper>
        <Typography variant="body1">Format</Typography>
      </StyledFormatWrapper>
      <StyledSourceWrapper>
        <Typography variant="body1">Source</Typography>
      </StyledSourceWrapper>
      <StyledLastAddedWrapper>
        <Typography variant="body1">Last added</Typography>
      </StyledLastAddedWrapper>
    </StyledListItemContainer>
  );
}

function FileListItem({ file, format, source, lastAdded }: FileListItemProps) {
  return (
    <StyledListItemContainer>
      <StyledFileWrapper>
        <Typography variant="body1">{file}</Typography>
      </StyledFileWrapper>
      <StyledFormatWrapper>{format}</StyledFormatWrapper>
      <StyledSourceWrapper>
        <Typography variant="body1">{source}</Typography>
      </StyledSourceWrapper>
      <StyledLastAddedWrapper>
        <Typography variant="body1">{lastAdded}</Typography>
      </StyledLastAddedWrapper>
    </StyledListItemContainer>
  );
}

function loadData(pageNumber = 1) {
  // TODO: Hook up with APIs.
  return pageNumber !== 1 ? mockFileMoreData : mockFileData;
}
