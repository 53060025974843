import { ThemeType } from '@shared-types/theme';

export default function Skeleton(theme: ThemeType) {
  return {
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius * 3,
        },
      },
    },
  };
}
