import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export const responseBody = <T>(response: AxiosResponse<T>) => response;

const requests = (instance: AxiosInstance) => ({
  get: <T>(url: string, config?: AxiosRequestConfig) =>
    instance.get<T>(url, config).then(responseBody),
  post: <T>(url: string, body?: unknown, config?: AxiosRequestConfig) =>
    instance.post<T>(url, body, config).then(responseBody),
  put: <T>(url: string) => instance.put<T>(url).then(responseBody),
  delete: <T>(url: string, config?: AxiosRequestConfig) =>
    instance.delete<T>(url, config).then(responseBody),
  patch: <T>(url: string, body: unknown) =>
    instance.patch<T>(url, body).then(responseBody),
});

export default requests;
