import { useContext } from 'react';
import { PortalContext } from '@providers/PortalProvider';

export default function usePortal() {
  const context = useContext(PortalContext);

  if (!context)
    throw new Error(
      'usePortal must be used within a PortalProvider',
    );

  return context;
}
