import {
  StyledCarousel,
  StyledList,
} from '@components/autocomplete-with-carousel/listbox/styles';
import { ListProps } from '@mui/material/List';
import breakpoints from '@theme/breakpoints';
import { Children, forwardRef } from 'react';
import 'react-multi-carousel/lib/styles.css';

interface CustomListProps extends ListProps {
  firstSlide?: React.ReactNode;
}

const ListboxComponent = forwardRef<HTMLUListElement, CustomListProps>(
  function ListboxComponent(props, ref) {
    const { children, firstSlide, ...otherProps } = props;
    const allChildren = [firstSlide, ...Children.toArray(children)];

    return (
      <StyledList disablePadding ref={ref} {...otherProps}>
        <StyledCarousel
          sliderClass="modes-carousel"
          infinite
          slidesToSlide={1}
          itemClass="carousel-item-padding"
          responsive={{
            desktop: {
              breakpoint: {
                max: breakpoints.values.lg * 10, //? 10x for bigger screen size
                min: breakpoints.values.md,
              },
              items: 4,
            },
            tablet: {
              breakpoint: {
                max: breakpoints.values.md,
                min: breakpoints.values.sm,
              },
              items: 3,
            },
            mobile: {
              breakpoint: {
                max: breakpoints.values.sm,
                min: breakpoints.values.xs,
              },
              items: 1,
            },
          }}
        >
          {allChildren}
        </StyledCarousel>
      </StyledList>
    );
  },
);

ListboxComponent.displayName = 'ListboxComponent';

export default ListboxComponent;
