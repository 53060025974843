import usePaymentWithStripe from '@hooks/usePaymentWithStripe';
import CreditQuantity from '@layouts/main/sections/toolbar/components/credit-quantity';
import {
  StyledBox,
  StyledStack,
} from '@layouts/main/sections/toolbar/mini/mobile/sections/credit-usage/styles';
import { ButtonBase } from '@mui/material';

export default function CreditUsage() {
  const { isLoading, handleRedirectToStripe } = usePaymentWithStripe();

  return (
    <ButtonBase
      disableRipple
      disableTouchRipple
      disabled={isLoading}
      onClick={handleRedirectToStripe}
    >
      <StyledBox>
        <StyledStack direction="row" gap={2}>
          <CreditQuantity variant="miniMobile" />
        </StyledStack>
      </StyledBox>
    </ButtonBase>
  );
}
