//TODO: for translation service we will have an issue with Yup validation messages
import en from '@translations/en';
import * as Yup from 'yup';

const createWorkspaceSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(60, en.page.workspace.createWorkspaceModal.step1.form.name.max)
    .required(en.page.workspace.createWorkspaceModal.step1.form.name.required),
});

export default createWorkspaceSchema;
