import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';

const NUMBER_OF_LINES = 5;

export default function Info() {
  return (
    <Card>
      <Stack gap={4}>
        {Array.from({ length: NUMBER_OF_LINES }).map((_, i) => (
          <Typography key={i} variant="body2">
            <Skeleton variant="rectangular" />
          </Typography>
        ))}
      </Stack>
    </Card>
  );
}
