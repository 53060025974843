import { ThemeType } from '@shared-types/theme';

export default function Tab(theme: ThemeType) {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: theme.typography.body2.fontSize,
        },
      },
    },
  };
}
