import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1.7),
  color: theme.palette.text.secondary,
  background: theme.palette.background.card.light,
  borderRadius: theme.spacing(1.5),
  border: '1px solid',
  borderColor: theme.palette.divider,
}));

export default StyledIconButton;
