import { KnowzStepDefinition } from '../../types';

const MessageOnSlackStepDefinition: KnowzStepDefinition = {
  order: 401,
  group: 'Slack',
  step: {
    componentType: 'task',
    type: 'slack-message',
    name: 'Slack Message',
  },
  propertyTypes: [
    {
      id: 'inputs',
      name: 'Message context',
      description: 'Define the different inputs you will need in your message',
      usage: 'input',
      wrapper: 'list',
      types: [
        'ref-json',
        'ref-string',
        'ref-source',
        'ref-thread',
        'ref-history-block',
      ],
    },
    {
      id: 'message',
      name: 'Smart message',
      description: 'Define your message. You can inline the inputs you defined',
      usage: 'definition',
      wrapper: 'item',
      types: ['string'],
    },
    // { TODO
    //   id: 'knowledge',
    //   name: 'Background knowledge',
    //   description:
    //     `Define background knowledge. This knowledge won't be displayed to ` +
    //     'the user, but it may be used for continuing the conversation',
    //   usage: 'definition',
    //   wrapper: 'item',
    //   types: ['string'],
    // },
    {
      id: 'channel',
      name: 'Channel',
      description:
        'Define the channel where the message will be sent. ' +
        'Leave it empty if you want to send private messages',
      usage: 'definition',
      variant: 'short',
      wrapper: 'item',
      types: ['string'],
    },
    {
      id: 'userId',
      name: 'User ID',
      description:
        'Define the user ID of the person you want to send the message to. ' +
        'Leave it empty if you want to send a message to a channel',
      usage: 'definition',
      variant: 'short',
      wrapper: 'item',
      types: ['string'],
    },
  ],
};

export default MessageOnSlackStepDefinition;
