import { styled } from '@mui/material';
import Card from '@mui/material/Card';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';

export const StyledCardStack = styled(Card)(({ theme }) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.background.secondary,
})) as typeof Card & typeof Stack;

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  height: '100%',
  borderRadius: theme.shape.borderRadius * 2,
  borderWidth: 1,
  borderStyle: 'solid',
  backgroundColor: 'transparent!important',
  padding: '0px!important',
  borderColor: theme.palette.background.secondary,
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.card.light,
  padding: theme.spacing(3),
  borderRadius: theme.spacing(4),
  minHeight: 'fit-content',
  [theme.breakpoints.up('md')]: {
    minHeight: 'fit-content',
    padding: theme.spacing(6),
  },
}));

export const StyledListItemStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  width: '100%',
}));

export const StyledListItemImage = styled('img')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  margin: '0 auto',
}));
