import MainWrapper from '@components/pages/side-kick-session/main-wrapper';
import AppBar from '@components/pages/side-kick-session/sections/app-bar';
import Content from '@components/pages/side-kick-session/sections/content';
import Drawer from '@components/pages/side-kick-session/sections/drawer';
import StopRecordingButton from '@components/pages/side-kick-session/sections/stop-recording';
import Wrapper from '@components/pages/side-kick-session/Wrapper';
import { ActionMap } from '@shared-types/utils';
import React, { createContext, useCallback, useMemo, useReducer } from 'react';

type ContextType = {
  isCalculateDrawerOpen: boolean;
  feedIsOpen: boolean;
  smartReviewIsOpen: boolean;
  handleSetFeedDrawer: (value: boolean) => void;
  handleSetSmartReviewDrawer: (value: boolean) => void;
  handleResetDrawers: VoidFunction;
};

export const SideKickContext = createContext<ContextType | null>(null);

type Drawer = {
  feedIsOpen: boolean;
  smartReviewIsOpen: boolean;
  isCalculateDrawerOpen: boolean;
};

const initialState: Drawer = {
  feedIsOpen: false,
  smartReviewIsOpen: false,
  isCalculateDrawerOpen: false,
};

enum Types {
  FEED = 'SET_FEED',
  SMART_REVIEW = 'SET_SMART_REVIEW',
  RESET_DRAWERS = 'RESET_DRAWERS',
}

type SideKickPayload = {
  [Types.FEED]: {
    feedIsOpen: boolean;
  };
  [Types.SMART_REVIEW]: {
    smartReviewIsOpen: boolean;
  };
  [Types.RESET_DRAWERS]: boolean;
};

export type DrawerActions =
  ActionMap<SideKickPayload>[keyof ActionMap<SideKickPayload>];

function reducer(state: Drawer, action: DrawerActions): Drawer {
  switch (action.type) {
    case Types.FEED:
      return { ...state, feedIsOpen: action.payload.feedIsOpen };
    case Types.SMART_REVIEW:
      return { ...state, smartReviewIsOpen: action.payload.smartReviewIsOpen };
    case Types.RESET_DRAWERS:
      return { ...state, feedIsOpen: false, smartReviewIsOpen: false };
    default:
      return state;
  }
}

export default function SideKickProvider({
  children,
}: React.PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleSetFeedDrawer = useCallback(() => {
    handleResetDrawers();
    dispatch({
      type: Types.FEED,
      payload: { feedIsOpen: !state.feedIsOpen },
    });
  }, [state.feedIsOpen]);

  const handleSetSmartReviewDrawer = useCallback(() => {
    handleResetDrawers();
    dispatch({
      type: Types.SMART_REVIEW,
      payload: { smartReviewIsOpen: !state.smartReviewIsOpen },
    });
  }, [state.smartReviewIsOpen]);

  function handleResetDrawers() {
    dispatch({
      type: Types.RESET_DRAWERS,
      payload: true,
    });
  }

  const memoizedValue = useMemo(() => {
    return {
      ...state,
      handleSetFeedDrawer,
      handleSetSmartReviewDrawer,
      handleResetDrawers,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSetFeedDrawer, handleSetSmartReviewDrawer]);

  return (
    <SideKickContext.Provider value={memoizedValue}>
      <Wrapper>{children}</Wrapper>
    </SideKickContext.Provider>
  );
}

SideKickProvider.MainWrapper = MainWrapper;
SideKickProvider.AppBar = AppBar;
SideKickProvider.Content = Content;
SideKickProvider.StopRecordingButton = StopRecordingButton;
SideKickProvider.Drawer = Drawer;
