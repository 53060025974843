import StyledGradientText from '@components/pages/get-the-app/styles';
import {
  StyledCenteredContent,
  StyledViewportHeightStack,
} from '@components/pages/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';

const LOGO_WIDTH = 200;
const LOGO_HEIGHT = 60;

export default function GetTheApp() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <StyledViewportHeightStack>
      <StyledCenteredContent gap={8}>
        <Stack gap={2.5}>
          <Typography
            component="h1"
            variant="display1"
            sx={{ textAlign: 'center' }}
          >
            <StyledGradientText
              component="span"
              sx={{
                background:
                  theme.palette.mode === 'dark'
                    ? theme.palette.gradients('to top').neutral
                    : theme.palette.gradients('to top').black,
              }}
            >
              {t('page.getTheApp.segment1', {
                defaultValue: 'Get Started with AI:',
              })}
            </StyledGradientText>
            &nbsp;
            <StyledGradientText
              component="span"
              sx={{
                background: theme.palette.gradients('to left').primary,
              }}
            >
              {t('page.getTheApp.segment2', {
                defaultValue: 'Unlock the Power of Conversation',
              })}
            </StyledGradientText>
          </Typography>
          <Typography variant="body1" align="center">
            {t('page.home.hero.subtitle', {
              defaultValue: 'Search no more, Knowz better',
            })}
          </Typography>
        </Stack>
        <Stack
          gap={5}
          direction="row"
          sx={{
            backgroundColor: 'background.secondary',
            p: 5,
            borderRadius: 5,
          }}
        >
          <Link
            component={NavLink}
            target="_blank"
            to="https://apps.apple.com/au/app/ai-search-dont-google-knowz/id6514305712"
          >
            <Box
              component="img"
              src="https://assets.knowz.com/assets/images/appstore.png"
              alt="app store"
              loading="lazy"
              width={LOGO_WIDTH}
              height={LOGO_HEIGHT}
            />
          </Link>
          <Link
            component={NavLink}
            target="_blank"
            to="https://play.google.com/store/apps/details?id=com.knowz.mobileapp&pcampaignid=web_share"
          >
            <Box
              component="img"
              src="https://assets.knowz.com/assets/images/googleplay.png"
              alt="app store"
              loading="lazy"
              width={LOGO_WIDTH}
              height={LOGO_HEIGHT}
            />
          </Link>
        </Stack>
      </StyledCenteredContent>
    </StyledViewportHeightStack>
  );
}
