import {
  StyledContainer,
  StyledHeaderWrapper,
  StyledItemContainer,
  StyledItemWrapper,
} from '@components/list/styles';

type Props = {
  header?: React.ReactNode;
  children: React.ReactNode[];
  hasBorder?: boolean;
  hasHover?: boolean;
  onClick?: VoidFunction;
};

export default function List({
  header,
  children,
  hasBorder = false,
  hasHover = false,
  onClick,
}: Props) {
  return (
    <StyledContainer>
      {header && (
        <StyledHeaderWrapper>
          <StyledItemContainer hasBorder={true}>
            <StyledItemWrapper hasHover={false}>{header}</StyledItemWrapper>
          </StyledItemContainer>
        </StyledHeaderWrapper>
      )}
      {children.map((child, index) => (
        <StyledItemContainer
          key={`list-item-${index}`}
          hasBorder={hasBorder}
          onClick={onClick}
        >
          <StyledItemWrapper hasHover={hasHover}>{child}</StyledItemWrapper>
        </StyledItemContainer>
      ))}
    </StyledContainer>
  );
}
