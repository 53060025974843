import { alpha, styled } from '@mui/material/styles';
import { ButtonBase, ButtonBaseProps } from '@mui/material';
import { LinkProps } from 'react-router-dom';

const BUTTON_SIZE = 40;

interface BaseButtonProps extends ButtonBaseProps {
  isSelected: boolean;
}

export const StyledList = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'right',
});

export const StyledNav = styled('nav')(() => ({
  width: '100%',
}));

export const StyledPageButton = styled(ButtonBase, {
  shouldForwardProp: (props) => props !== 'isSelected',
})<BaseButtonProps & LinkProps>(({ isSelected, theme }) => ({
  backgroundColor: isSelected
    ? alpha(theme.palette.primary.main, 0.08)
    : undefined,
  color: isSelected ? alpha(theme.palette.primary.main, 0.8) : undefined,
  fontWeight: isSelected ? 'bold' : undefined,
  height: BUTTON_SIZE,
  width: BUTTON_SIZE,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.body1.fontSize,
  borderRadius: theme.shape.borderRadius * 1.5,
}));

export const StyledNextAndPrevButton = styled(ButtonBase)<
  ButtonBaseProps & LinkProps
>(({ theme, disabled }) => ({
  color: disabled ? theme.palette.action.disabled : theme.palette.text.primary,
  width: BUTTON_SIZE,
  height: BUTTON_SIZE,
}));
