import { AssistantsSessionContext } from '@layouts/assistants-session/providers/AssistantsSessionProvider';
import { useContext } from 'react';

export default function useAssistantsSession() {
  const context = useContext(AssistantsSessionContext);

  if (!context) {
    throw new Error(
      'useAssistantsSession must be used within a AssistantsSessionProvider',
    );
  }

  return context;
}
