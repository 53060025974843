import {
  KnowzStepDefinition,
  PropertyType,
  StepType,
  TriggerType,
} from '@lib/step/types';
import { HashMap } from '@shared-types/utils';
import * as StepsDefinitions from '@lib/step';

export const stepsConfig = {
  iconUrlProvider: (componentType: string, type: string) => {
    if (type.match(/-trigger$/))
      return 'https://static.webrand.com/icons/v69/svgs/lightning.svg';

    return (
      (
        {
          'slack-message': 'https://www.svgrepo.com/show/327394/logo-slack.svg',
          'update-manifest':
            'https://static.webrand.com/icons/v81/svgs/diskette.svg',
          'load-manifest':
            'https://static.webrand.com/icons/v81/svgs/cell-align---20.svg',
          code: 'https://static.webrand.com/icons/v81/svgs/page-number-format.svg',
          structured:
            'https://static.webrand.com/icons/v81/svgs/group---20.svg',
          'dynamic-switch':
            'https://static.webrand.com/icons/v81/svgs/share.svg',
          'reference-iterator':
            'https://static.webrand.com/icons/v81/svgs/arrows-circle.svg',
          transient: 'https://static.webrand.com/icons/v81/svgs/ai---stars.svg',
          assist: 'https://static.webrand.com/icons/v81/svgs/ai---stars.svg',
          match: 'https://static.webrand.com/icons/v81/svgs/search---stars.svg',
          'design-automation':
            'https://static.webrand.com/icons/v81/svgs/fill-form-pencil.svg',
          'send-email': 'https://static.webrand.com/icons/v81/svgs/email.svg',
        } as HashMap<string>
      )[type] || 'https://static.webrand.com/icons/v81/svgs/effects.svg'
    );
  },

  isDraggable: (step, parentSequence) => {
    return !step.type.match(/-trigger$/);
  },

  isDeletable: (step, parentSequence) => {
    return true;
  },

  isDuplicable: (step, parentSequence) => {
    return true;
  },

  canInsertStep: (step, targetSequence, targetIndex) => {
    return true;
  },

  canMoveStep: (sourceSequence, step, targetSequence, targetIndex) => {
    return true;
  },

  canDeleteStep: (step, parentSequence) => {
    return step.name !== 'x';
  },
};

export const validatorConfig = {
  // all validators are optional

  step: (step, parentSequence, definition) => {
    return true;
  },

  root: (definition) => {
    return true;
  },
};

export const toolboxConfig = (function () {
  const groups = [];
  const fingerprints = [];

  for (const key in StepsDefinitions) {
    const def: KnowzStepDefinition = StepsDefinitions[key];

    const fingerprint = getStepFingerprint(def.step);
    let group: any = groups.find((group) => group.name === def.group);

    if (fingerprints.includes(fingerprint)) {
      console.error(`Step with fingerprint ${fingerprint} already exists`);
      continue;
    }

    if (!group) {
      group = {
        name: def.group,
        steps: [],
      };

      groups.push(group);
    }

    group.steps.push({ ...def.step, properties: {} });
  }

  groups.sort((a, b) =>
    getGroupOrder(a.name) > getGroupOrder(b.name) ? 1 : -1,
  );

  for (const group of groups) {
    group.steps.sort((a, b) => (getStepOrder(a) > getStepOrder(b) ? 1 : -1));
  }

  return {
    groups,
    isCollapsed: false,
  };
})();

function getStepOrder(step): number {
  const fingerprint = getStepFingerprint(step);
  const key = Object.keys(StepsDefinitions).find((key) => {
    const def = StepsDefinitions[key];
    return getStepFingerprint(def.step) === fingerprint;
  });

  return StepsDefinitions[key]?.order || 0;
}

function getGroupOrder(group: string): number {
  const stepKey = Object.keys(StepsDefinitions).find(
    (key) => StepsDefinitions[key].group === group,
  );

  return StepsDefinitions[stepKey].order;
}

function getStepFingerprint(step: StepType): string {
  return `${step.componentType}-${step.type}`;
}

export function getStepPropertiesDefinition(
  step: StepType,
): Array<PropertyType> {
  const fingerprint = getStepFingerprint(step);
  const key = Object.keys(StepsDefinitions).find((key) => {
    const def = StepsDefinitions[key];
    return getStepFingerprint(def.step) === fingerprint;
  });

  return key ? StepsDefinitions[key].propertyTypes : [];
}

export function getTriggerProperties(definition: any): TriggerType[] {
  const trigger = definition.sequence.find((step: StepType) =>
    step.type.match(/-trigger$/),
  );
  if (!trigger) return [];

  const fingerprint = getStepFingerprint(trigger);
  const key = Object.keys(StepsDefinitions).find((key) => {
    const def = StepsDefinitions[key];
    return getStepFingerprint(def.step) === fingerprint;
  });

  return StepsDefinitions[key]?.triggerProperties || [];
}
