import {
  StyledStack,
  StyledClickableAvatar,
  StyledListItem,
} from '@components/workspace/workspace-selector/triggers/styles';
import useAuth from '@hooks/useAuth';
import useWorkspace from '@hooks/useWorkspace';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Stack from '@mui/material/Stack';
import EllipsisTypography from '@components/ellipsis-typography';
import { forwardRef } from 'react';

const SHARED_PROPS = {
  disablePadding: true,
  disableGutters: true,
};

const Default = forwardRef<HTMLDivElement>((props, ref) => {
  const { workspace } = useWorkspace();
  const { me } = useAuth();

  return (
    <StyledStack direction="row" spacing={1} ref={ref} {...props}>
      {workspace && (
        <StyledListItem {...SHARED_PROPS}>
          <ListItemButton>
            <Stack direction="row" gap={2} sx={{ alignItems: 'center' }}>
              <StyledClickableAvatar
                sx={{ pointerEvents: 'none' }}
                src={workspace.profilePicture || ''}
              >
                {workspace.name[0].toUpperCase()}
              </StyledClickableAvatar>

              <ListItemText
                sx={{ m: 0 }}
                primary={
                  <EllipsisTypography
                    variant="body2"
                    lineClamp={1}
                    sx={{ wordBreak: 'break-word' }}
                    title={workspace.name}
                    component="span"
                  >
                    {workspace.name}
                  </EllipsisTypography>
                }
                primaryTypographyProps={{
                  variant: 'body2',
                }}
              />
            </Stack>
          </ListItemButton>
        </StyledListItem>
      )}

      {!workspace && me && (
        <StyledListItem {...SHARED_PROPS}>
          <ListItemButton>
            <ListItemIcon>
              <HomeOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="My Space"
              primaryTypographyProps={{
                variant: 'body2',
              }}
            />
          </ListItemButton>
        </StyledListItem>
      )}

      {/* TODO: skeleton */}
    </StyledStack>
  );
});

Default.displayName = 'Default';

export default Default;
