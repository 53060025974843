/* eslint-disable import/prefer-default-export */
import requests from '@lib/agent/requests';
import axiosDesygnerInstance from '@lib/agent/services/desygner/axiosInstance';
import {
  CreateThreadParamsType,
  DeleteThreadParamsType,
  GetThreadsHistoryParamsType,
  ThreadsHistoryType,
  ThreadType,
  UpdateThreadParamsType,
  UpdateThreadsHistoryParamsType,
} from '@shared-types/threads';
import { TemplateType } from '@shared-types/desygner';
import { AxiosResponse } from 'axios';

const DEFAULT_WORKSPACE_ID = String(import.meta.env.VITE_WORKSPACE_ID);
const DEFAULT_BOT_ID = String(import.meta.env.VITE_BOT_ID);

export const legacyThreads = {
  getOneById: (id: string) =>
    requests(axiosDesygnerInstance).get<ThreadType>(
      `/business/messages/hub_thread/${id}`,
    ),

  deleteOneById: ({
    threadId,
    workspaceId = DEFAULT_WORKSPACE_ID,
  }: DeleteThreadParamsType) =>
    requests(axiosDesygnerInstance).delete(
      `/brand/companies/${workspaceId}/hub/threads/${threadId}`,
    ),

  createOne: ({
    workspaceId = DEFAULT_WORKSPACE_ID,
    body,
  }: CreateThreadParamsType): Promise<AxiosResponse<{ id: number }>> =>
    requests(axiosDesygnerInstance).post(
      `/brand/companies/${workspaceId}/hub/threads`,
      body,
    ),

  updateOneThreadById: ({ threadId, body }: UpdateThreadParamsType) =>
    requests(axiosDesygnerInstance).post<ThreadType>(
      `/business/messages/hub_thread/${threadId}`,
      body,
    ),
};

export const legacyThreadsHistory = {
  getAllByBotId: ({
    workspaceId = DEFAULT_WORKSPACE_ID,
    botId = DEFAULT_BOT_ID,
  }: GetThreadsHistoryParamsType) =>
    requests(axiosDesygnerInstance).get<Array<ThreadsHistoryType>>(
      `/brand/companies/${workspaceId}/hub/threads/me?bot=${botId}`,
    ),
  updateOneById: ({
    workspaceId = DEFAULT_WORKSPACE_ID,
    threadId,
    body,
  }: UpdateThreadsHistoryParamsType) =>
    requests(axiosDesygnerInstance).patch<ThreadsHistoryType>(
      `/brand/companies/${workspaceId}/hub/threads/${threadId}`,
      body,
    ),
};

export const templates = {
  getAll: ({ workspaceId = DEFAULT_WORKSPACE_ID }: { workspaceId: string }) =>
    requests(axiosDesygnerInstance).get<TemplateType[]>(
      `/brand/companies/${workspaceId}/templates`,
    ),
};
