import AssistantsSessionLayout from '@layouts/assistants-session';
import KnowledgeBaseLayout from '@layouts/knowledge-base';
import MainLayout from '@layouts/main';
import Header from '@layouts/main/sections/header';
import { StyledStack } from '@layouts/styles';

type Props = React.PropsWithChildren<{
  variant?: 'main' | 'assistants-session' | 'knowledge-base';
}>;

export default function Layout({ variant, children }: Props) {
  if (variant === 'main') {
    return <MainLayout>{children}</MainLayout>;
  }

  if (variant === 'assistants-session') {
    return <AssistantsSessionLayout>{children}</AssistantsSessionLayout>;
  }

  if (variant === 'knowledge-base') {
    return <KnowledgeBaseLayout>{children}</KnowledgeBaseLayout>;
  }

  return (
    <StyledStack>
      <Header />
      {children}
    </StyledStack>
  );
}
