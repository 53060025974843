import { styled } from '@mui/material';

export const StyledContainer = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.palette.neutral.border};
  border-radius: ${({ theme }) => theme.shape.borderRadius * 8};
  height: 80vh;
  padding: ${({ theme }) => theme.spacing(6)};
  gap: ${({ theme }) => theme.spacing(8)};
`;

export const StyledHeaderContainer = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(6)};
  align-items: center;
`;

export const StyledSearchBarWrapper = styled('div')`
  display: flex;
  width: 100%;
`;

export const StyledPaginationContainer = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  align-items: center;
  user-select: none;
  margin-left: auto;
  text-wrap: nowrap;
`;

export const StyledListItemContainer = styled('div')`
  display: flex;
  padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(2)};
`;

export const StyledPaginationButtonWrapper = styled('div')`
  cursor: pointer;
`;

export const StyledFileWrapper = styled('div')`
  display: flex;
  width: 260px;
`;

export const StyledFormatWrapper = styled('div')`
  display: flex;
  width: 76px;
`;

export const StyledSourceWrapper = styled('div')`
  display: flex;
  width: 168px;
`;

export const StyledLastAddedWrapper = styled('div')`
  display: flex;
  width: 120px;
`;
