import CONFIG from '@components/pages/history/config';
import Search from '@components/pages/history/sections/search';
import Transcript from '@components/pages/history/sections/transcript';
import { StyledContainerForFixedHeader } from '@components/pages/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import useAuth from '@hooks/useAuth';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState } from 'react';

const TAB_PANELS = [<Search />, <Transcript />];

export default function History() {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const { me } = useAuth();
  function handleTabChange(_: React.SyntheticEvent, newValue: number) {
    setTabValue(newValue);
  }

  return (
    <StyledContainerForFixedHeader maxWidth="xl">
      <Paper sx={{ backgroundColor: 'background.card.light' }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          {CONFIG.TAB_LIST_ITEM.filter(
            ({ isAlpha }) => me?.alpha || !isAlpha,
          ).map((tab, index) => (
            <Tab
              disableRipple
              sx={{ flexGrow: 1, maxWidth: 'unset' }}
              key={index}
              label={t(tab.mappedLabel, {
                defaultValue: tab.defaultLabel,
              })}
              id={tab.defaultLabel}
              aria-controls={tab.defaultLabel}
              disabled={tab.defaultLabel === 'transcript (coming soon)'}
            />
          ))}
        </Tabs>
        <Divider orientation="horizontal" />
        <Box role="tabpanel" sx={{ marginTop: 5 }}>
          {TAB_PANELS[tabValue]}
        </Box>
      </Paper>
    </StyledContainerForFixedHeader>
  );
}
