import CONFIG from '@layouts/main/config';
import { convertToRem } from '@lib/fonts';
import { styled, keyframes } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

export const StyledAnimatedBox = styled(Box)(({ theme }) => {
  const animatedBackground = keyframes`
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  `;

  return {
    // animation: `${animatedBackground} 5s linear infinite alternate`,
    backgroundPosition: '100% 0%',
    backgroundSize: '120% 100%',
    backgroundRepeat: 'no-repeat',
    opacity: theme.palette.mode === 'dark' ? 1 : 0.5,
    backgroundImage: `linear-gradient(90deg,
      #BDFFDF 30%,
      ${theme.palette.neutral.paper} 35%,
      #9CEDFF 30%,
      #9CEDFF 75%,
      ${theme.palette.neutral.paper} 83%,
      #FFBDE8 70%
    ), linear-gradient(to left, #000000a8, #000000a8)`,
    backgroundBlendMode: theme.palette.mode === 'dark' && 'overlay',
    position: 'absolute',
    top: `calc(0% - ${convertToRem(CONFIG.HEADER.HEIGHT)})`,
    height: theme.spacing(170),
    width: '70%',
    left: '50%',
    transform: 'translateX(-50%)',
    borderBottomLeftRadius: '100%',
    borderBottomRightRadius: '100%',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    filter: 'blur(100px)',
    zIndex: -1,
    willChange: 'background-position',
  };
});

export const StyledViewportHeightStack = styled(Stack)(() => ({
  justifyContent: 'center',
  height: '100vh',
}));

export const StyledCenteredContent = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledContainerForFixedHeader = styled(Container)(({ theme }) => ({
  marginTop: convertToRem(CONFIG.HEADER.HEIGHT + CONFIG.HEADER.PADDING_Y),
  paddingBottom: theme.spacing(35), //? extra padding to prevent content from being hidden behind the footer
}));
