import { useTranslation } from '@desygner/ui-common-translation';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type Props = {
  navListItems: Array<{
    label: string;
    defaultLabel: string;
    link: string;
  }>;
};

export default function ListItems({ navListItems }: Props) {
  const { t } = useTranslation();

  return (
    <Stack
      subheader={<Typography variant="h3">Menu</Typography>}
      component={List}
      direction="column"
      sx={{
        py: { xs: 5 },
      }}
    >
      {navListItems.map(({ label, defaultLabel, link }, index) => {
        return (
          <ListItem key={index} disableGutters disablePadding>
            <ListItemButton
              sx={{ '&:hover': { backgroundColor: 'transparent' } }}
              href={link}
              target="_blank"
            >
              <ListItemText
                primaryTypographyProps={{
                  variant: 'body1',
                }}
              >
                {t(label, { defaultValue: defaultLabel })}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        );
      })}
    </Stack>
  );
}
