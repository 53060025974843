import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

const axiosInstance = applyCaseMiddleware(
  axios.create({
    baseURL: import.meta.env.VITE_DESYGNER_API_URL || '',
  }),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error, '🚫🚫🚫🚫🚫🚫🚫🚫');
    return Promise.reject(error || 'Something went wrong');
  },
);

axiosInstance.interceptors.request.use(
  (request) => request,
  (error) => {
    console.error(error, '🚫🚫🚫🚫🚫🚫🚫🚫');
    return Promise.reject(error || 'Something went wrong');
  },
);

export default axiosInstance;
