const TEXTAREA = {
  HEIGHT: 100,
  WIDTH: {
    MD: 500,
  },
};

const CONFIG = {
  TEXTAREA,
} as const;

export default CONFIG;
