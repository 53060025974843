import SOCIALS from '@layouts/main/sections/toolbar/socials/config';
import StyledStackSocials from '@layouts/main/sections/toolbar/socials/styles';
import IconButton from '@mui/material/IconButton';

export default function Socials() {
  return (
    <StyledStackSocials gap={2} direction="row">
      {SOCIALS.map(({ href, icon: SocialIcon, title }) => (
        <IconButton key={title} target="_blank" href={href} color="inherit">
          {SocialIcon}
        </IconButton>
      ))}
    </StyledStackSocials>
  );
}
