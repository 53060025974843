import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import IconButton from '@mui/material/IconButton';

type Props = {
  code: string;
};
function CopyButton({ code }: Props) {
  return (
    <IconButton>
      <CopyToClipboard text={code} onCopy={() => alert('Copied!')}>
        <ContentCopyOutlinedIcon />
      </CopyToClipboard>
    </IconButton>
  );
}

export default CopyButton;
