import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

const StyledStack = styled(Stack, {
  shouldForwardProp: (props) => props !== 'isAi',
})<{ isAi: boolean }>(({ theme, isAi }) => ({
  justifyContent: 'flex-start',
  flexDirection: isAi ? 'row' : 'row-reverse',
  gap: theme.spacing(2),
}));

export default StyledStack;
