import { ThemeType } from '@shared-types/theme';

export default function Link(theme: ThemeType) {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          color:
            theme.palette.mode === 'dark'
              ? theme.palette.neutral.grey
              : theme.palette.neutral.black,
        },
      },
      defaultProps: {
        underline: 'none',
      },
    },
  };
}
