import useSearchParamsState from '@hooks/useSearchParamsState';
import { payments } from '@lib/agent';
import { useQuery } from '@tanstack/react-query';

export default function useGetCreditAsPercentage() {
  const [, setSearchParams] = useSearchParamsState('action', '');

  const { data: creditsData, isLoading: isCreditLoading } = useQuery({
    queryKey: ['credits'],
    queryFn: async () => {
      try {
        const response = await payments.getCredit();
        const creditAsPercentage =
          (response.data.quantity / response.data.initialQuantity) * 100;
        if (creditAsPercentage <= 0) {
          setSearchParams('upgrade-account');
        }
        return creditAsPercentage;
      } catch (error) {
        console.error(error);
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  return { creditsData, isCreditLoading };
}
