import EllipsisTypography from '@components/ellipsis-typography';
import useGetThreadIdFromQueryParam from '@hooks/useGetThreadFromQueryParam';
import { StyledCard } from '@layouts/assistants-session/drawer/conversation-history/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import paths from '@router/paths';
import { GetAllThreadsResponseType } from '@shared-types/threads';
import { formatRelative } from 'date-fns';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

const ConversationListitem = forwardRef<
  HTMLAnchorElement,
  GetAllThreadsResponseType
>(({ id, description, name, lastInteraction }, ref) => {
  const { threadId } = useGetThreadIdFromQueryParam();

  return (
    <StyledCard
      sx={{
        ':hover': {
          backgroundColor: 'background.secondary',
        },
        backgroundColor: threadId === id ? 'background.secondary' : '',
      }}
      ref={ref}
      component={Link}
      to={`${paths.assistantsSession.pathname}?t=${id}`}
    >
      <Stack gap={4}>
        <Typography variant="subtitle2">{name}</Typography>
        {description !== null && (
          <EllipsisTypography lineClamp={2} variant="body2">
            {description}
          </EllipsisTypography>
        )}
        <Stack
          direction="row"
          gap={1}
          sx={{ alignItems: 'center', height: 20 }}
        >
          <AccessTimeIcon color="disabled" />
          <Typography color="text.secondary" variant="caption">
            {formatRelative(lastInteraction, new Date())}
          </Typography>
        </Stack>
      </Stack>
    </StyledCard>
  );
});

ConversationListitem.displayName = 'ConversationListitem';

export default ConversationListitem;
