import EllipsisTypography from '@components/ellipsis-typography';
import { useTranslation } from '@desygner/ui-common-translation';
import useWorkspace from '@hooks/useWorkspace';
import Typography, { TypographyOwnProps } from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const SHARED_PROPS: TypographyOwnProps & { lineClamp: number } = {
  lineClamp: 1,
  variant: 'body2',
  color: 'text.secondary',
};

export default function WorkspaceOwner() {
  const { t } = useTranslation();
  const { workspace } = useWorkspace();

  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between' }} gap={4}>
      <EllipsisTypography {...SHARED_PROPS} sx={{ wordBreak: 'break-word' }}>
        {workspace?.createdBy.email}
      </EllipsisTypography>
      <Typography {...SHARED_PROPS}>
        {t('page.workspace.createWorkspaceModal.step3.owner', {
          defaultValue: 'Owner',
        })}
      </Typography>
    </Stack>
  );
}
