import { OAuthConfigType, PathsType } from '@shared-types/sso';

export type ProviderType = {
  id?: string;
  options: OAuthConfigType;
  paths: PathsType;
};

export type OAuthOptionType = {
  type: 'oauthConfiguration';
  label: string;
  key:
    | 'config.clientId'
    | 'config.clientSecret'
    | 'config.authorizationUrl'
    | 'config.accessTokenUrl'
    | 'config.infosUrl'
    | 'config.csrf'
    | 'config.authWithOneUrl'
    | 'config.useCommasInScope'
    | 'config.useAuthorizationToGetToken'
    | 'config.refreshOnExpire'
    | 'config.sandbox'
    | 'config.includeEmail'
    | 'config.appsecretProof'
    | 'config.useBearerAuthorization'
    | 'config.attrName'
    | 'config.tokenPath'
    | 'config.realm'
    | 'config.requestTokenUrl'
    | 'config.revokeTokenUrl'
    | 'config.scope'
    | 'config.application'
    | 'config.scopes'
    | 'config.signatureMethod'
    | 'config.duration'
    | 'config.perms'
    | 'config.userResourceUrl'
    | 'config.hd'
    | 'config.requestVisibleActions'
    | 'config.accessType'
    | 'config.approvalPrompt'
    | 'config.display'
    | 'config.loginHint'
    | 'config.prompt'
    | 'config.emailsUrl'
    | 'config.responseType'
    | 'config.applicationKey'
    | 'config.fields'
    | 'config.meUrl'
    | 'config.emailUrl'
    | 'config.authType'
    | 'config.responseMode'
    | 'config.authKey'
    | 'config.keyId'
    | 'config.teamId'
    | 'config.format'
    | 'config.apiVersion'
    | 'config.site'
    | 'config.protocol'
    | 'config.version'
    | 'config.infosSessionUrl'
    | 'config.paths.identifier'
    | 'config.paths.firstname'
    | 'config.paths.email'
    | 'config.paths.nickname'
    | 'config.paths.realname'
    | 'config.paths.lastname';
  element: 'text' | 'checkbox';
  hidden: boolean;
  required: boolean;
  advanced: boolean;
  default?: boolean | string;
};

export const OAUTH_FIELDS: OAuthOptionType[] = [
  {
    type: 'oauthConfiguration',
    label: 'Client id',
    key: 'config.clientId',
    element: 'text',
    required: true,
    advanced: false,
    hidden: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'Client secret',
    key: 'config.clientSecret',
    element: 'text',
    required: true,
    advanced: false,
    hidden: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'Authorization url',
    key: 'config.authorizationUrl',
    element: 'text',
    required: true,
    advanced: false,
    hidden: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'Access token url',
    key: 'config.accessTokenUrl',
    element: 'text',
    required: true,
    advanced: false,
    hidden: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'Infos url',
    key: 'config.infosUrl',
    element: 'text',
    required: true,
    advanced: false,
    hidden: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'Attribute Path: Identifier',
    key: 'config.paths.identifier',
    element: 'text',
    required: true,
    advanced: false,
    hidden: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'Attribute Path: First Name',
    key: 'config.paths.firstname',
    element: 'text',
    required: false,
    advanced: false,
    hidden: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'Attribute Path: Email',
    key: 'config.paths.email',
    element: 'text',
    required: false,
    advanced: false,
    hidden: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'Attribute Path: Nickname',
    key: 'config.paths.nickname',
    element: 'text',
    required: false,
    advanced: true,
    hidden: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'Attribute Path: Full Name',
    key: 'config.paths.realname',
    element: 'text',
    required: false,
    advanced: true,
    hidden: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'Attribute Path: Last Name',
    key: 'config.paths.lastname',
    element: 'text',
    required: true,
    advanced: true,
    hidden: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'Csrf',
    key: 'config.csrf',
    element: 'checkbox',
    required: false,
    advanced: true,
    hidden: false,
    default: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Auth with one url',
    key: 'config.authWithOneUrl',
    element: 'checkbox',
    required: false,
    advanced: true,
    hidden: true,
    default: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'Use commas in scope',
    key: 'config.useCommasInScope',
    element: 'checkbox',
    required: false,
    advanced: true,
    hidden: true,
    default: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'UseAuthorization to get token',
    key: 'config.useAuthorizationToGetToken',
    element: 'checkbox',
    required: false,
    advanced: true,
    hidden: true,
    default: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Refresh on expire',
    key: 'config.refreshOnExpire',
    element: 'checkbox',
    required: false,
    advanced: true,
    hidden: true,
    default: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'Sandbox',
    key: 'config.sandbox',
    element: 'checkbox',
    required: false,
    advanced: true,
    hidden: true,
    default: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'Include email',
    key: 'config.includeEmail',
    element: 'checkbox',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Appsecret proof',
    key: 'config.appsecretProof',
    element: 'checkbox',
    required: false,
    advanced: true,
    hidden: true,
    default: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'Use bearer authorization',
    key: 'config.useBearerAuthorization',
    element: 'checkbox',
    required: false,
    advanced: true,
    hidden: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'Attr name',
    key: 'config.attrName',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
    default: 'access_token',
  },
  {
    type: 'oauthConfiguration',
    label: 'Token Path',
    key: 'config.tokenPath',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Realm',
    key: 'config.realm',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Request token url',
    key: 'config.requestTokenUrl',
    element: 'text',
    required: false,
    advanced: true,
    hidden: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'Revoke token url',
    key: 'config.revokeTokenUrl',
    element: 'text',
    required: false,
    advanced: true,
    hidden: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'Scope',
    key: 'config.scope',
    element: 'text',
    required: false,
    advanced: false,
    hidden: false,
  },
  {
    type: 'oauthConfiguration',
    label: 'Application',
    key: 'config.application',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Signature method',
    key: 'config.signatureMethod',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Duration',
    key: 'config.duration',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Perms',
    key: 'config.perms',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'User resource url',
    key: 'config.userResourceUrl',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Hd',
    key: 'config.hd',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Request visible actions',
    key: 'config.requestVisibleActions',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Access type',
    key: 'config.accessType',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Approval prompt',
    key: 'config.approvalPrompt',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Display',
    key: 'config.display',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Login hint',
    key: 'config.loginHint',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Prompt',
    key: 'config.prompt',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Emails url',
    key: 'config.emailsUrl',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Response type',
    key: 'config.responseType',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
    default: 'code',
  },
  {
    type: 'oauthConfiguration',
    label: 'Application key',
    key: 'config.applicationKey',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Fields',
    key: 'config.fields',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Me url',
    key: 'config.meUrl',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Email url',
    key: 'config.emailUrl',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Auth type',
    key: 'config.authType',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Response mode',
    key: 'config.responseMode',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Auth key',
    key: 'config.authKey',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Key id',
    key: 'config.keyId',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Team id',
    key: 'config.teamId',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Format',
    key: 'config.format',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
    default: 'json',
  },
  {
    type: 'oauthConfiguration',
    label: 'Api version',
    key: 'config.apiVersion',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Site',
    key: 'config.site',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Protocol',
    key: 'config.protocol',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Version',
    key: 'config.version',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
  {
    type: 'oauthConfiguration',
    label: 'Infos session url',
    key: 'config.infosSessionUrl',
    element: 'text',
    required: false,
    advanced: true,
    hidden: true,
  },
];
