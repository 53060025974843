//TODO: for now I just hard coded speaker later we change it to dynamic 🚀
//TODO: maybe we need to break ternary operators for tags and make it simple
import LabelChip from '@components/pages/side-kick-session/sections/content/label-chip';
import Avatar from '@mui/material/Avatar';
import MuiListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ColoredSentiments, MonologueType } from '@shared-types/sidekick';
import { memo } from 'react';

type Props = Omit<MonologueType, 'id' | 'tags' | 'labels'> & {
  coloredSentiment: ColoredSentiments | null;
};

export default memo(function ListItem({
  content,
  time,
  coloredSentiment,
  fact,
}: Props) {
  return (
    <MuiListItem disableGutters>
      <Stack gap={3}>
        <Stack
          direction="row"
          gap={3}
          sx={{ alignItems: 'center', flexWrap: 'wrap' }}
        >
          <Avatar alt="John Doe" sx={{ width: 24, height: 24 }} />
          <Typography variant="subtitle2">Speaker</Typography>
          <Typography variant="body2" color="text.secondary">
            {time.minutes}:{time.seconds}
          </Typography>
          <LabelChip fact={fact} coloredSentiment={coloredSentiment} />
        </Stack>
        <Typography sx={{ ml: 9 }} variant="body2">
          {content}
        </Typography>
      </Stack>
    </MuiListItem>
  );
});
