import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type UserType = {
  text: string;
  owner: {
    id: string;
    firstName: string | null;
    lastName: string | null;
    profilePicture: string | null;
  };
};

type AssistantResultState = {
  askAIErrored: {
    status: number;
  } | null;
  user: UserType;
};

const initialState: AssistantResultState = {
  askAIErrored: null,
  user: {
    text: '',
    owner: {
      id: '',
      firstName: '',
      lastName: '',
      profilePicture: '',
    },
  },
};

const assistantResult = createSlice({
  name: 'assistantResult',
  initialState,
  reducers: {
    userUpdated: (state, action: PayloadAction<UserType>) => {
      return {
        ...state,
        user: {
          text: action.payload.text,
          owner: {
            firstName: '',
            id: '',
            lastName: '',
            profilePicture: '',
          },
        },
      };
    },
    askAIErrored: (
      state,
      action: PayloadAction<AssistantResultState['askAIErrored']>,
    ) => {
      if (action.payload == null) return state;

      return {
        ...state,
        askAIErrored: { status: action.payload.status },
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { askAIErrored, userUpdated, reset } = assistantResult.actions;

export default assistantResult.reducer;
