import Logo from '@components/logo';
import DesktopNav from '@layouts/main/sections/header/desktop-nav';
import { StyledStack } from '@layouts/main/sections/header/styles';
import Box from '@mui/material/Box';
import { useEffect, useRef } from 'react';
import usePortal from '@hooks/usePortal';
import { useLocation } from 'react-router-dom';
import paths from '@router/paths';
import useResponsive from '@hooks/useResponsive';
import CONFIG from '@layouts/main/sections/header/config';

export default function LogoZone() {
  const mainPortal = useRef(null);

  const { handleSetNavbarMain } = usePortal();

  const { pathname } = useLocation();

  const isItDesktop = useResponsive('up', 'md');

  const isHomePage = pathname === paths.home.pathname;

  const commandPath = paths.editCommand.pathname.replace(':id', '\\d+');

  const commandPathRegex = new RegExp(commandPath);

  const isCommandsEditor = commandPathRegex.test(pathname);

  const menuType = !isItDesktop || isCommandsEditor ? 'clean' : 'default';

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleSetNavbarMain(mainPortal?.current), [mainPortal]);

  return (
    <StyledStack direction="row" gap={5}>
      <Logo isLink={!isHomePage} />
      {menuType === 'default' && <DesktopNav navListItems={CONFIG.NAV_ITEMS} />}
      <Box ref={mainPortal} />
    </StyledStack>
  );
}
