import InvitationForm from '@components/invitation-form';
import Heading from '@components/workspace/setup/components/heading';
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';
import { useTranslation } from '@desygner/ui-common-translation';
import useQueryParams from '@hooks/useQueryParams';
import Button from '@mui/material/Button';
import paths from '@router/paths';
import { useNavigate } from 'react-router-dom';

export default function InviteMembers() {
  const { t } = useTranslation();
  const { removeQueryParamByKey } = useQueryParams();
  const { handleReset, handleBack } = useWorkspaceSetup();
  const navigateTo = useNavigate();

  function onSubmitHandler() {
    removeQueryParamByKey('action');
    handleReset();
    navigateTo(paths.users.pathname);
  }

  return (
    <InvitationForm
      onSubmitHandler={onSubmitHandler}
      skippable
      secondaryButton={
        <Button variant="outlined" onClick={handleBack}>
          {t('page.workspace.createWorkspaceModal.backButton', {
            defaultValue: 'Back',
          })}
        </Button>
      }
      heading={
        <Heading
          title={t(
            'page.workspace.createWorkspaceModal.step3.form.email.label',
            {
              defaultValue: 'Workspace Name',
            },
          )}
          subtitle={t(
            'page.workspace.createWorkspaceModal.step3.form.description',
            {
              defaultValue: 'Invite your team members through email',
            },
          )}
          isOptional
        />
      }
    />
  );
}
