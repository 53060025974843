import breakpoints from '@theme/breakpoints';

export const BASE_FONT_SIZE = 14;

export function convertToRem(number: number) {
  return `${(number / 14).toFixed(2)}rem`;
}

export function responsiveFontSize({
  xs,
  sm,
  md,
  lg,
}: {
  xs: number;
  sm: number;
  md: number;
  lg: number;
}) {
  return {
    [`@media screen and (min-width:${breakpoints.values.xs}px)`]: {
      fontSize: convertToRem(xs),
    },
    [`@media screen and (min-width:${breakpoints.values.sm}px)`]: {
      fontSize: convertToRem(sm),
    },
    [`@media screen and (min-width:${breakpoints.values.md}px)`]: {
      fontSize: convertToRem(md),
    },
    [`@media screen and (min-width:${breakpoints.values.lg}px)`]: {
      fontSize: convertToRem(lg),
    },
  };
}
