import useGetMembershipIdFromQueryParam from '@components/pages/users/hooks/useGetMembershipIdFromQueryParam';
import FormProvider from '@components/react-hook-form/FormProvider';
import { RHFCheckbox } from '@components/react-hook-form/RHFCheckbox';
import RHFTextField from '@components/react-hook-form/RHFTextField';
import FormHeading from '@components/user-authentication/components/form-heading';
import useLogin from '@components/user-authentication/hooks/useLogin';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';
import { enterPasswordSchema } from '@components/user-authentication/schemas';
import { useTranslation } from '@desygner/ui-common-translation';
import { yupResolver } from '@hookform/resolvers/yup';
import useAcceptInvitation from '@hooks/useAcceptInvitation';
import useAuth from '@hooks/useAuth';
import useQueryParams from '@hooks/useQueryParams';
import useWorkspace from '@hooks/useWorkspace';
import { setSession } from '@lib/jwt';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { queryClient } from '@providers/ReactQueryProvider';
import { isAxiosError } from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const defaultValues = {
  password: '',
  rememberMe: false,
};

type EnterPasswordFormType = {
  password: string;
  rememberMe: boolean;
};

export default function EnterPassword() {
  const [showPassword, setShowPassword] = useState(false);

  const { email } = useUserAuth();

  const { handleUpdateMe } = useAuth();

  const { mutateAsync: mutateAsyncLogin, isPending: isLoginPending } =
    useLogin();

  const { t } = useTranslation();

  const { removeQueryParamsByKeys } = useQueryParams();

  const { handleIsUserAuthenticated, handleAttemptedToLogin } = useAuth();

  const methods = useForm<EnterPasswordFormType>({
    resolver: yupResolver(enterPasswordSchema),
    defaultValues,
  });

  const { resetWorkspaceToIndividualRole } = useWorkspace();

  const membershipId = useGetMembershipIdFromQueryParam();

  const { mutateAcceptInvitationAsync } = useAcceptInvitation({
    onSuccess: () => {
      toast.success(
        t('page.knowledgeBase.member.membership.accepted', {
          defaultValue: 'You have successfully accepted this invitation.',
        }),
      );
    },
  });

  const {
    formState: { errors, isDirty, isSubmitting },
    handleSubmit,
  } = methods;

  const isBusy = isLoginPending || isSubmitting;

  async function onSubmit(data: EnterPasswordFormType) {
    try {
      const { data: loginData } = await mutateAsyncLogin({
        email,
        password: data.password,
        auth_type: 'email',
        remember_me: data.rememberMe,
      });

      window.localStorage.removeItem('fingerPrintId');
      resetWorkspaceToIndividualRole();
      setSession(loginData.token);
      handleUpdateMe(loginData.token);
      handleIsUserAuthenticated(true);
      handleAttemptedToLogin(true);
      removeQueryParamsByKeys(['action', 'subscription']);

      if (membershipId) {
        await mutateAcceptInvitationAsync({ membershipId });
      }

      queryClient.invalidateQueries({ queryKey: ['credits'] });
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 401) {
        toast.error(
          t('response.errors.code.401', {
            defaultValue: 'Invalid credentials. Please try again.',
          }),
        );
        return;
      }
      toast.error(
        t('response.errors.code.520', {
          defaultValue: 'Something went wrong. Please try again later.',
        }),
      );
      console.error(error);
    }
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <FormHeading
        heading={t('layout.auth.desktop.modal.steps.enterPassword.label')}
        withBackButton
      />
      <Stack gap={5}>
        <Typography variant="body2">
          {t(
            'layout.auth.desktop.modal.steps.enterPassword.form.password.label',
            {
              defaultValue: 'Enter your password',
            },
          )}
        </Typography>
        <RHFTextField
          type={showPassword ? 'text' : 'password'}
          fullWidth
          size="small"
          name="password"
          disabled={isBusy}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          helperText={errors.password?.message}
        />
        <RHFCheckbox name="rememberMe" label="Remember me" />
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          fullWidth
          disabled={!isDirty || isBusy}
          loading={isBusy}
        >
          {t('layout.auth.desktop.modal.steps.enterPassword.form.button', {
            defaultValue: 'login',
          })}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
