import AssistantsDrawerProvider from '@components/pages/assistants/providers/AssistantsDrawerProvider';

export default function Assistants() {
  return (
    <AssistantsDrawerProvider>
      <AssistantsDrawerProvider.Drawer />
      <AssistantsDrawerProvider.Main />
    </AssistantsDrawerProvider>
  );
}
