import { FlowRunContext } from '@providers/FlowRunContext';
import { useContext } from 'react';

export default function useFlowRun() {
  const context = useContext(FlowRunContext);

  if (!context) {
    throw new Error(
      'useFlowRun must be used within a FlowRunProvider',
    );
  }

  return context;
}
