const FILE_TYPES = [
  'audio/mpeg',
  'audio/ogg',
  'audio/wav',
  'audio/webm',
  'audio/x-m4a',
  'audio/x-wav',
  'audio/x-ms-wma',
  'text/plain',
  'text/csv',
  'text/html',
  'text/markdown',
  'application/pdf',
  'application/msword',
  'application/vnd.ms-excel',
  'application/json',
  'application/xml',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const MAX_FILE_SIZE = {
  PAID: 300 * 1024 * 1024, // 300MB
  NOT_PAID: 100 * 1024 * 1024, // 100MB
};

const MAX_NUMBER_OF_FILES = 6;

const CONFIG = {
  FILE_TYPES,
  MAX_FILE_SIZE,
  MAX_NUMBER_OF_FILES,
} as const;

export default CONFIG;
