import { AutocompleteValueOnChangeType } from '@components/pages/side-kick/types';

const SIDE_KICK_MODES: AutocompleteValueOnChangeType = [
  {
    type: 'training-sets',
    image: 'https://assets.knowz.com/assets/images/Action-Points-Logger.png',
    title: 'Action Points Logger',
    description:
      'The Action Points Logger is trained to track and record specific actions or decisions made during dialogues or interactions with users.',
    cats: [
      'meeting-elements',
      'action-points',
      'question-detection',
      'decision-points',
      'meeting-notes',
      'follow-up-items',
      'urgency-signals',
      'sentiment-detection',
    ],
  },
  {
    type: 'training-sets',
    image: 'https://assets.knowz.com/assets/images/Conversation-Moderation.png',
    title: 'Conversation Moderation',
    description:
      'Conversation Moderation involves the systematic management and oversight of dialogues or interactions, typically in digital environments, to ensure they adhere to specific guidelines, rules, or standard',
    cats: [
      'moderation-category',
      'sentiment-detection',
      'content-sensitivity',
      'bias-detection',
      'nsfw-detection',
      'fallacy-detection',
    ],
  },
  {
    type: 'training-sets',
    image: 'https://assets.knowz.com/assets/images/Minutes-Tracker.png',
    title: 'Minutes Tracker',
    description:
      'The Minutes Tracker is trained to systematically record and document the proceedings and decisions made during meetings or sessions',
    cats: [
      'meeting-elements',
      'place-detection',
      'time-detection',
      'rsvp-detection',
      'sentiment-detection',
    ],
  },
  {
    type: 'training-sets',
    image:
      'https://assets.knowz.com/assets/images/Sales-Conversation-Analyser.png',
    title: 'Sales Conversation Analyser',
    description:
      'The Sales Conversation Analyzer is a specialized set designed to analyze and evaluate sales conversations to improve effectiveness and outcomes',
    cats: [
      'customer-sentiment',
      'question-detection',
      'product-interest-level',
      'objection-handling',
      'urgency-signals',
      'compliance-adherence',
      'upsell-opportunities',
      'key-phrase-detection',
    ],
  },
];

const FACT_CHECK: AutocompleteValueOnChangeType = [
  {
    type: 'fact-check',
    image: 'https://assets.knowz.com/assets/images/fact-check.png',
    title: 'Fact Checker',
    description:
      'The Fact Checker is designed to verify the accuracy of statements or claims made during dialogues or interactions',
  },
];

export default {
  SIDE_KICK_MODES,
  FACT_CHECK,
};
