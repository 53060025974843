//TODO: for translation service we will have an issue with Yup validation messages
import en from '@translations/en';
import * as Yup from 'yup';

const messageFieldSchema = Yup.object().shape({
  text: Yup.string()
    .trim()
    .required(en.page.assistantsSession.messageField.required),
});

export default messageFieldSchema;
