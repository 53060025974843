import CONFIG from '@layouts/main/sections/header/config';
import ListItems from '@layouts/main/sections/header/navbar-drawer/ListItems';
import { NavListItemsType } from '@layouts/main/sections/header/types';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';

type Props = {
  navListItems: NavListItemsType;
};

export default function NavbarDrawer({ navListItems }: Props) {
  const [open, setOpen] = useState(false);

  function handleOpenNav() {
    setOpen(!open);
  }

  return (
    <>
      <IconButton onClick={handleOpenNav}>
        <MenuIcon />
      </IconButton>
      <Drawer
        PaperProps={{ sx: { width: CONFIG.DRAWER_WIDTH, p: 3 } }}
        anchor="right"
        open={open}
        onClose={handleOpenNav}
      >
        <ListItems navListItems={navListItems} />
      </Drawer>
    </>
  );
}
