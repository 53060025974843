import { styled } from '@mui/material';
import Tab, { TabProps } from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';

type StyledTabProps = TabProps & {
  label: string;
  value: string;
  selected?: boolean;
};

export const StyledTab = styled(({ ...otherProps }: StyledTabProps) => (
  <Tab disableRipple {...otherProps} />
))(({ theme, selected }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  backgroundColor: 'none',
  border: 'none',
  '&.Mui-selected': {
    color: theme.palette.text.primary,
  },
  position: selected ? 'relative' : 'static',
  zIndex: selected ? 1 : 0,
  marginInline: theme.spacing(1),
  padding: 0,
  height: theme.spacing(8),
  minHeight: theme.spacing(8),
}));

export const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  marginBlockStart: theme.spacing(4),
  padding: 0,
}));

export const StyledTabList = styled(TabList)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.divider,
  minHeight: theme.spacing(8.75),
  height: theme.spacing(8.75),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(3),
}));
