import StyledIconButton from '@components/pages/knowledge-base/components/action-button/styles';
import { IconButtonProps } from '@mui/material';

export default function ActionButton({
  children,
  ...otherProps
}: IconButtonProps) {
  return (
    <StyledIconButton disableRipple {...otherProps}>
      {children}
    </StyledIconButton>
  );
}
