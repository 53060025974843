import { styled } from '@mui/material';
import Card from '@mui/material/Card';

export const StyledImage = styled('img')(({ theme }) => ({
  borderRadius: theme.spacing(1),
  objectFit: 'cover',
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  display: 'block',
  backgroundColor: theme.palette.background.secondary,
  minHeight: theme.spacing(41.5),
  padding: theme.spacing(3),
})) as typeof Card;
