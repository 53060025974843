import AsyncAutocomplete, {
  OptionType,
} from '@components/pages/command-designer/entity-selectors/AsyncAutocomplete';
import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';
import { sources } from '@lib/agent';
import { SourceType } from '@shared-types/sources';

export interface SourceProps {
  value: number | null;
  onChange: (value: SourceType | null) => void;
}

export default function Source(props: SourceProps) {
  const { data, isLoading } = useQueryAfterWorkspaceLoaded({
    queryFn: () =>
      sources.getAll({
        limit: 100,
        page: 0,
        folder: null, // TODO: check if this brings all or just root
      }),
    queryKey: ['sources'],
  });

  return (
    <AsyncAutocomplete
      options={(data?.data || []) as OptionType[]}
      isLoading={isLoading}
      placeholder="Select a source"
      {...props}
    ></AsyncAutocomplete>
  );
}
