import { ThemeType } from '@shared-types/theme';

export default function ListItem(theme: ThemeType) {
  return {
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        },
      },
    },
  };
}
