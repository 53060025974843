import Heading from '@components/pages/side-kick-session/sections/drawer/heading';
import { useTranslation } from '@desygner/ui-common-translation';
import Typography from '@mui/material/Typography';

export default function Feed() {
  const { t } = useTranslation();
  return (
    <>
      <Heading
        title={t('page.sideKickSession.drawer.feed.heading', {
          defaultValue: 'Feed',
        })}
      />
      <Typography variant="body1">
        {t('comingSoon', {
          defaultValue: 'Coming Soon!',
        })}
      </Typography>
    </>
  );
}
