import { ThemeType } from '@shared-types/theme';

export default function Drawer(theme: ThemeType) {
  return {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          padding: 0,
          backgroundColor: theme.palette.background.default,
        },
      },
    },
  };
}
