import { auth } from '@lib/agent';
import { setSession } from '@lib/jwt';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import { isAxiosError } from 'axios';

export async function login() {
  const authId =
    window.localStorage.getItem('fingerPrintId') || (await getFingerprint());

  const response = await auth.login({
    auth_type: 'web_fingerprint',
    auth_id: authId,
  });

  return response;
}

export async function signUp(recaptchaToken: string) {
  const fingerPrintId = (await getFingerprint()) as string;

  window.localStorage.setItem('fingerPrintId', fingerPrintId);

  const response = await auth
    .signUp({
      auth_type: 'web_fingerprint',
      code: recaptchaToken,
      auth_id: fingerPrintId,
    })
    .catch(async (error) => {
      if (isAxiosError(error) && error.response?.status === 409) {
        const response = await login();
        setSession(response.data.token);
        return response;
      }
      console.error(error);
    });

  return response;
}
