import { alpha, styled } from '@mui/material';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import { NavLinkProps, To } from 'react-router-dom';
import Box from '@mui/material/Box';

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadius * 100,
  padding: 0,
  marginBottom: theme.spacing(2),
})) as React.ComponentType<
  ListItemProps & Omit<NavLinkProps, 'to'> & { to?: To }
>;

export const StyledAddWorkspaceIcon = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.light, 0.1),
  color: theme.palette.primary.light,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius * 100,
}));
