import { styled } from '@mui/material';
import ImageListItem from '@mui/material/ImageListItem';

const StyledImageListItem = styled(ImageListItem)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  img: {
    height: '100px!important',
  },
}));

export default StyledImageListItem;
