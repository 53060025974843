import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

export interface AsyncAutocompleteProps {
  value: number|null;
  options: OptionType[];
  isLoading: boolean;
  placeholder?: string;
  onChange: (value: any) => void;
}

export interface OptionType {
  id: number;
  name: string;
}

export default function AsyncAutocomplete({
  value,
  onChange,
  isLoading,
  options,
  placeholder
}: AsyncAutocompleteProps) {
  const [open, setOpen] = useState(false);

  return (
    <Autocomplete
      open={open}
      size="small"
      value={options.find((option) => option.id === value) || null}
      onChange={(event) => onChange(options[event.target.value])}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option === value}
      options={options}
      getOptionLabel={(option) => option.name}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
