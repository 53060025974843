import CONFIG_TOOLBAR from '@layouts/main/sections/toolbar/config';
import { StyledGlobalToolbar } from '@layouts/main/sections/toolbar/styles';
import { styled } from '@mui/material';

const StyledMobileToolbar = styled(StyledGlobalToolbar)(({ theme }) => ({
  zIndex: theme.zIndex.appBar,
  bottom: 0,
  left: '50%',
  position: 'fixed',
  transform: 'translateX(-50%)',
  background: theme.palette.background.card.light,
  width: '100%',
  height: CONFIG_TOOLBAR.TOOLBAR_MINI.MOBILE.HEIGHT,
  boxShadow: theme.customShadows.dropdown,
}));

export default StyledMobileToolbar;
