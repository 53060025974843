import { styled } from '@mui/material';
import MuiTooltip, {
  tooltipClasses,
  TooltipProps,
} from '@mui/material/Tooltip';

const StyledMuiTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
  },
});

export default StyledMuiTooltip;
