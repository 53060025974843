import { SetupModeType } from '@providers/WorkspaceProvider';

//? on: setup mode is enabled (admin), off: setup mode is disabled (member), null: individual role
export const ROLES: { [key: string]: SetupModeType[] } = {
  HOME: [null, 'off'],
  SEARCH: [null, 'off'],
  SIDE_KICK: [null, 'off'],
  ASSISTANTS: [null, 'off'],
  HISTORY: [null, 'off'],
  COMMANDS: ['on'],
  COMMANDS_EDITOR: ['on'],
  CONNECTORS: ['on'],
  USERS: ['on'],
  KNOWLEDGE_BASE: ['on', 'off'],
  GET_APP: [null, 'off', 'on'],
  SSO: ['on'],
};

export const ROLES_BY_PATHNAME = {
  '/': ROLES.HOME,
  '/app/search': ROLES.SEARCH,
  '/app/side-kick': ROLES.SIDE_KICK,
  '/app/side-kick/session': ROLES.SIDE_KICK,
  '/app/assistants': ROLES.ASSISTANTS,
  '/app/assistants/session': ROLES.ASSISTANTS,
  '/app/history': ROLES.HISTORY,
  '/app/commands': ROLES.COMMANDS,
  '/app/commands/editor/*': ROLES.COMMANDS_EDITOR,
  '/app/settings/connectors': ROLES.CONNECTORS,
  '/app/settings/knowledge-base': ROLES.KNOWLEDGE_BASE,
  '/get-the-app': ROLES.GET_APP,
  '/app/workspace/users': ROLES.USERS,
  '/app/settings/sso/*': ROLES.SSO,
} as const;
