import WorkspaceSetupProvider from '@components/workspace/setup/providers/WorkspaceSetupProvider';
import useResponsive from '@hooks/useResponsive';

export default function WorkspaceSetup() {
  const isItMobile = useResponsive('down', 'sm');

  return (
    <WorkspaceSetupProvider>
      {isItMobile ? (
        <WorkspaceSetupProvider.MobileModalStepper>
          <WorkspaceSetupProvider.ActiveStep />
        </WorkspaceSetupProvider.MobileModalStepper>
      ) : (
        <WorkspaceSetupProvider.DesktopModalStepper>
          <WorkspaceSetupProvider.ActiveStep />
        </WorkspaceSetupProvider.DesktopModalStepper>
      )}
    </WorkspaceSetupProvider>
  );
}
