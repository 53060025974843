import ActiveForm from '@components/user-authentication/components/ActiveForm';
import DesktopAuthButton from '@components/user-authentication/sections/auth-button/desktop';
import DesktopModalStepper from '@components/user-authentication/sections/modal-stepper/desktop';
import MobileAuthButton from '@components/user-authentication/sections/auth-button/mobile';
import MobileModalStepper from '@components/user-authentication/sections/modal-stepper/mobile';
import useAuth from '@hooks/useAuth';
import useQueryParams from '@hooks/useQueryParams';
import { ActionMap } from '@shared-types/utils';
import { createContext, useEffect, useMemo, useReducer, useState } from 'react';

type AuthenticationJourney = 'login' | 'register' | null;

type ContextType = {
  activeStep: number; //? between 0 | 1 | 2 | 3
  handleNextStep: VoidFunction;
  handleBackStep: VoidFunction;
  handleCustomStep: (step: number) => void;
  handleSetEmail: (email: string) => void;
  handlePassword: (password: string) => void;
  handleSetCode: (code: string) => void;
  isUserAlreadyRegistered: boolean;
  handleIsUserAlreadyRegistered: (value: boolean) => void;
  handleIsUserSubscribe: (value: boolean) => void;
  email: string;
  code: string;
  password: string;
  name: string;
  isSubscribe: boolean;
  authenticationJourney: AuthenticationJourney;
  handleAuthenticationJourney: (value: AuthenticationJourney) => void;
  resetForm: VoidFunction;
};

export const UserAuthenticationContext = createContext<ContextType | null>(
  null,
);

type UserAuth = {
  email: string;
  code: string;
  password: string;
  isSubscribe: boolean;
  name: string;
  isUserAlreadyRegistered: boolean;
  authenticationJourney: AuthenticationJourney;
};

enum Types {
  EMAIL = 'SET_EMAIL',
  CODE = 'SET_CODE',
  PASSWORD = 'SET_PASSWORD',
  NAME = 'SET_NAME',
  SUBSCRIBE = 'SET_SUBSCRIBE',
  IS_USER_ALREADY_REGISTERED = 'SET_IS_USER_ALREADY_REGISTERED',
  AUTHENTICATION_JOURNEY = 'SET_AUTHENTICATION_JOURNEY',
  RESET_FORM = 'SET_RESET_FORM',
}

type UserAuthPayload = {
  [Types.EMAIL]: {
    email: string;
  };
  [Types.CODE]: {
    code: string;
  };
  [Types.PASSWORD]: {
    password: string;
  };
  [Types.NAME]: {
    name: string;
  };
  [Types.SUBSCRIBE]: {
    isSubscribe: boolean;
  };
  [Types.IS_USER_ALREADY_REGISTERED]: {
    isUserAlreadyRegistered: boolean;
  };
  [Types.AUTHENTICATION_JOURNEY]: {
    authenticationJourney: AuthenticationJourney;
  };
  [Types.RESET_FORM]: {
    shouldReset: boolean;
  };
};

export type UserAuthActions =
  ActionMap<UserAuthPayload>[keyof ActionMap<UserAuthPayload>];

function reducer(state: UserAuth, action: UserAuthActions): UserAuth {
  switch (action.type) {
    case Types.EMAIL:
      return { ...state, email: action.payload.email };
    case Types.CODE:
      return { ...state, code: action.payload.code };
    case Types.PASSWORD:
      return { ...state, password: action.payload.password };
    case Types.NAME:
      return { ...state, name: action.payload.name };
    case Types.SUBSCRIBE:
      return { ...state, isSubscribe: action.payload.isSubscribe };
    case Types.IS_USER_ALREADY_REGISTERED:
      return {
        ...state,
        isUserAlreadyRegistered: action.payload.isUserAlreadyRegistered,
      };
    case Types.AUTHENTICATION_JOURNEY:
      return {
        ...state,
        authenticationJourney: action.payload.authenticationJourney,
      };
    case Types.RESET_FORM:
      return {
        ...state,
        email: '',
        password: '',
        code: '',
        isSubscribe: false,
        name: '',
        isUserAlreadyRegistered: false,
        authenticationJourney: null,
      };
    default:
      return state;
  }
}

const initialState: UserAuth = {
  email: '',
  code: '',
  password: '',
  isSubscribe: false,
  name: '',
  isUserAlreadyRegistered: false,
  authenticationJourney: null,
};

const INITIAL_ACTIVE_STEP = 0;

export default function UserAuthenticationProvider({
  children,
}: React.PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [activeStep, setActiveStep] = useState(INITIAL_ACTIVE_STEP);
  const { setQueryParam } = useQueryParams();
  const { attemptedToLogin } = useAuth();

  function handleNextStep() {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 3) return prevActiveStep;
      return prevActiveStep + 1;
    });
  }

  function handleBackStep() {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 0) return prevActiveStep;
      return prevActiveStep - 1;
    });
  }

  function handleCustomStep(step: number) {
    setActiveStep(step);
  }

  function handleSetEmail(email: string) {
    dispatch({ type: Types.EMAIL, payload: { email } });
  }

  function handleSetCode(code: string) {
    dispatch({ type: Types.CODE, payload: { code } });
  }

  function handleIsUserAlreadyRegistered(value: boolean) {
    dispatch({
      type: Types.IS_USER_ALREADY_REGISTERED,
      payload: { isUserAlreadyRegistered: value },
    });
  }

  function handlePassword(password: string) {
    dispatch({ type: Types.PASSWORD, payload: { password } });
  }

  function handleIsUserSubscribe(value: boolean) {
    dispatch({ type: Types.SUBSCRIBE, payload: { isSubscribe: value } });
  }

  function handleAuthenticationJourney(value: AuthenticationJourney = null) {
    dispatch({
      type: Types.AUTHENTICATION_JOURNEY,
      payload: { authenticationJourney: value },
    });
  }

  function resetForm() {
    dispatch({
      type: Types.RESET_FORM,
      payload: { shouldReset: true },
    });
    setActiveStep(INITIAL_ACTIVE_STEP);
  }

  useEffect(() => {
    if (attemptedToLogin == null) return;
    if (attemptedToLogin) return;
    setQueryParam('action', 'secure-account');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attemptedToLogin]);

  const value = useMemo(
    () => ({
      ...state,
      activeStep,
      handleNextStep,
      handleBackStep,
      handleCustomStep,
      handleSetEmail,
      handleSetCode,
      handlePassword,
      handleIsUserAlreadyRegistered,
      handleIsUserSubscribe,
      handleAuthenticationJourney,
      resetForm,
    }),
    [activeStep, state],
  );

  return (
    <UserAuthenticationContext.Provider value={value}>
      {children}
    </UserAuthenticationContext.Provider>
  );
}

UserAuthenticationProvider.DesktopAuthButton = DesktopAuthButton;
UserAuthenticationProvider.DesktopModalStepper = DesktopModalStepper;

UserAuthenticationProvider.MobileAuthButton = MobileAuthButton;
UserAuthenticationProvider.MobileModalStepper = MobileModalStepper;

UserAuthenticationProvider.ActiveForm = ActiveForm;
