import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';
import { folders } from '@lib/agent';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { TreeViewBaseItem } from '@mui/x-tree-view/models';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { FolderBodyType, FolderType } from '@shared-types/folders';
import { HashMap } from '@shared-types/utils';
import { useCallback, useEffect, useState } from 'react';

export interface FolderProps extends Pick<FolderBodyType, 'parent'> {
  value: number | null;
  onChange: (value: FolderType | null) => void;
}

const FULL_KNOWLEDGE_BASE = 'all';

export default function Folder({ value, onChange }: FolderProps) {
  const { data, isLoading } = useQueryAfterWorkspaceLoaded({
    queryKey: ['folders'],
    queryFn: () => folders.getAll(),
  });

  const [flatItems, setFlatItems] = useState<HashMap<TreeViewBaseItem>>({});

  const [treeItems, setTreeItems] = useState<TreeViewBaseItem[]>([]);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const handleSelectedItemsChange = (
    _event: React.SyntheticEvent,
    ids: string[],
  ) => {
    const selection: string[] = [];
    const unselected = selectedItems.find((id) => !ids.includes(id));

    if (!unselected) {
      const selected = ids.find((id) => !selectedItems.includes(id));

      if (selected) {
        selection.push(...getRecursiveSelection(selected));
      }
    }

    setSelectedItems(selection);

    if (selection.length) {
      const folder =
        data?.data?.find((item) => `${item.id}` === selection[0]) || null;

      onChange(folder);
    } else {
      onChange(null);
    }
  };

  const getRecursiveSelection = useCallback(
    function (selected: string) {
      const selection: string[] = [selected];
      const item = flatItems[selected];

      if (item && item.children) {
        for (const child of item.children) {
          selection.push(...getRecursiveSelection(child.id));
        }
      }

      return selection;
    },
    [flatItems],
  );

  useEffect(() => {
    setSelectedItems(
      getRecursiveSelection(value ? `${value}` : FULL_KNOWLEDGE_BASE),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRecursiveSelection]);

  useEffect(() => {
    const { data: items } = data || {};

    if (items) {
      const tItems: TreeViewBaseItem[] = [];
      const fItems: HashMap<TreeViewBaseItem> = {
        all: {
          id: FULL_KNOWLEDGE_BASE,
          label: 'Knowledge Base',
          children: tItems,
        },
      };

      for (const item of items) {
        fItems[item.id] = {
          id: `${item.id}`,
          label: item.name,
          children: [],
        };
      }

      for (const item of items) {
        if (item.parentId) {
          if (typeof fItems[item.parentId] !== 'undefined') {
            fItems[item.parentId].children!.push(fItems[item.id]);
          }
        } else {
          tItems.push(fItems[item.id]);
        }
      }

      setFlatItems(fItems);
      setTreeItems([fItems[FULL_KNOWLEDGE_BASE]]);
    }
  }, [data, isLoading]);

  if (isLoading) {
    return (
      <Box sx={{ m: 2 }}>
        <CircularProgress color="inherit" size={20} />
      </Box>
    );
  }

  return (
    <RichTreeView
      sx={{ marginBottom: 1 }}
      checkboxSelection
      selectedItems={selectedItems}
      onSelectedItemsChange={handleSelectedItemsChange}
      multiSelect
      items={treeItems}
    />
  );
}
