import { createContext, useCallback, useState } from 'react';

type PortalContextType = {
  navbarMain: React.RefObject<HTMLElement>|null; // TODO
  handleSetNavbarMain: (value: React.RefObject<HTMLElement>|null) => void;
};

export const PortalContext = createContext<PortalContextType | null>(null);

export default function PortalProvider({
  children,
}: React.PropsWithChildren) {
  const [navbarMain, setNavbarMain] = useState<React.RefObject<HTMLElement>|null>(null);

  const handleSetNavbarMain = useCallback((value: React.RefObject<HTMLElement>|null) => {
    setNavbarMain(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PortalContext.Provider value={{ navbarMain, handleSetNavbarMain }}>
      {children}
    </PortalContext.Provider>
  );
}
