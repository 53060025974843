import { styled } from '@mui/material';

const StyledCanvas = styled('canvas')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: -1,
  display: theme.palette.mode === 'dark' ? 'block' : 'none',
}));

export default StyledCanvas;
