import { alpha } from '@mui/material';
import { ThemeType } from '@shared-types/theme';

export default function Backdrop(theme: ThemeType) {
  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(theme.palette.common.black, 0.45),
        },
      },
    },
  };
}
