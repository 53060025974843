import { KnowzStepDefinition } from '../types';

const CodeStepDefinition: KnowzStepDefinition = {
  order: 301,
  group: 'Logic',
  step: {
    componentType: 'task',
    type: 'code',
    name: 'Smart Code',
  },
  propertyTypes: [
    {
      id: 'scope',
      name: 'Scope',
      description: 'Select the content you want to use in your code',
      usage: 'input',
      wrapper: 'list',
      types: [
        'ref-json',
        'ref-string',
        'ref-source',
        'ref-thread',
        'ref-history-block',
      ],
    },
    {
      id: 'code',
      name: 'Smart JS code',
      description:
        'Write JavaScript code to be executed in a sandbox with the selected scope. You can use and modify the scope variables directly. You can also define a return output.',
      usage: 'definition',
      variant: 'code',
      wrapper: 'item',
      types: ['string'],
    },
    {
      id: 'output',
      name: 'Result reference',
      description: 'Name the resulting data',
      usage: 'output',
      wrapper: 'item',
      types: ['ref-json'],
    },
  ],
};

export default CodeStepDefinition;
