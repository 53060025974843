import IconButton, { IconButtonProps } from '@mui/material/IconButton';

type TextToSpeechIconButtonProps = IconButtonProps & {
  text?: string;
  id?: string;
  disabled?: boolean;
};

export default function TextToSpeechIconButton({
  text,
  id,
  children,
  ...otherProps
}: TextToSpeechIconButtonProps) {
  return (
    <IconButton {...otherProps} aria-label="text to speech">
      {children}
    </IconButton>
  );
}
