import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

export const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.background.card.light,
}));
