import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

export const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(6),
  borderRadius: theme.shape.borderRadius * 2,
}));

export const StyledImg = styled('img')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
}));
