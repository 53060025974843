import SectionHeading from '@components/pages/search/components/SectionHeading';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import { useTranslation } from '@desygner/ui-common-translation';
import TextToSpeechIconButton from '@components/pages/search/components/TextToSpeechIconButton';
import RecordVoiceOverRoundedIcon from '@mui/icons-material/RecordVoiceOverRounded';

const NUMBER_OF_LINES = 18;

const ORB_ICON_ELEMENT = (
  <Icon>
    <img
      src="https://assets.knowz.com/assets/svg/orb---small.png"
      alt="answer section"
    />
  </Icon>
);

export default function Answer() {
  const { t } = useTranslation();

  return (
    <SectionHeading
      title={
        <>
          {t('page.search.sections.answer', {
            defaultValue: 'Answer',
          })}
          &nbsp;
          {typeof window.MediaSource === 'function' && (
            <TextToSpeechIconButton disabled>
              <RecordVoiceOverRoundedIcon />
            </TextToSpeechIconButton>
          )}
        </>
      }
      icon={ORB_ICON_ELEMENT}
    >
      <Stack gap={4}>
        {Array.from({ length: NUMBER_OF_LINES }).map((_, i) => (
          <Typography key={i} variant="body2">
            <Skeleton variant="rectangular" />
          </Typography>
        ))}
      </Stack>
    </SectionHeading>
  );
}
