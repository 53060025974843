import { styled } from '@mui/material';
import Chip from '@mui/material/Chip';

const StyledChip = styled(Chip)(({ theme, label }) => ({
  borderRadius: theme.spacing(1.5),
  width: 'fit-content',
  height: 30,
  padding: theme.spacing(1.5),
}));

export default StyledChip;
