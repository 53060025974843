import { ToolbarContext } from '@layouts/main/sections/toolbar/providers/ToolbarProvider';
import { useContext } from 'react';

export default function useToolbar() {
  const context = useContext(ToolbarContext);

  if (!context)
    throw new Error('useToolbar must be used within a ToolbarProvider');

  return context;
}
