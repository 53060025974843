import { ActionMap } from '@shared-types/utils';
import { useEffect, useReducer } from 'react';

enum Types {
  SET_POSITION = 'SET_POSITION',
  SET_ERROR = 'SET_ERROR',
  SET_START = 'SET_START',
}

type GeolocationState = {
  position: {
    lat: number;
    lng: number;
  };
  error: GeolocationPositionError | Error | null;
  status: 'idle' | 'pending' | 'resolved' | 'rejected';
}

function reducer(state: GeolocationState, action: GeolocationActions): GeolocationState {
  switch (action.type) {
    case Types.SET_POSITION: {
      return { ...state, status: 'resolved', position: action.payload.position };
    }
    case Types.SET_ERROR: {
      return { ...state, status: 'rejected', error: action.payload.error };
    }
    case Types.SET_START: {
      return { ...state, status: 'pending' };
    }
    default:
      action satisfies never;
      return state;
  }
}

type GeolocationPayload = {
  [Types.SET_POSITION]: {
    position: {
      lat: number;
      lng: number;
    };
  };
  [Types.SET_ERROR]: {
    error: GeolocationPositionError | Error | null;
  };
  [Types.SET_START]: undefined;
};

export type GeolocationActions =
  ActionMap<GeolocationPayload>[keyof ActionMap<GeolocationPayload>];


export default function useGeolocation(fallbackPosition: { lat: number; lng: number } = { lat: 0, lng: 0 }) {
  const initialState: GeolocationState = {
    position: fallbackPosition,
    error: null,
    status: 'idle',
  };

  const [{error, position, status}, dispatch] = useReducer(reducer, initialState);


  useEffect(() => {
    if (!navigator.geolocation) {
      dispatch({ type: Types.SET_ERROR, payload: { error: new Error('Geolocation is not supported') } });
      return;
    }

    function handleSuccess(position: GeolocationPosition) {
      dispatch({
        type: Types.SET_POSITION,
        payload: {
          position: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
        }
      });
    }

    function handleError(error: GeolocationPositionError) {
      console.error(error);
      dispatch({ type: Types.SET_ERROR, payload: { error } });
    }

    dispatch({ type: Types.SET_START});

    const watchId = navigator.geolocation.watchPosition(
      handleSuccess,
      handleError,
    );

    return () => navigator.geolocation.clearWatch(watchId);
  }, []);

  return {
    position,
    error,
    status,
  };
}
