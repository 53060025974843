import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';
import { sourceConfigurations } from '@lib/agent';

export default function useGetAllSourceConfigurations() {
  const {
    data: sourceConfigs,
    isLoading,
    isError,
    refetch,
  } = useQueryAfterWorkspaceLoaded({
    queryKey: ['sourceConfigurations'],
    queryFn: async () => {
      const { data } = await sourceConfigurations.getAllSourceConfigurations();
      return data;
    },
  });

  return { sourceConfigs, isLoading, isError, refetch };
}
