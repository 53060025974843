const STATUS = ['Collected', 'Invited', 'Accepted', 'Removed'];

const PAGINATION = {
  LIMIT: 10,
};

export type ColumnProps = {
  align?: 'left' | 'right' | 'center';
  width?: string;
};

const COLUMN_STYLES: { [key: string]: ColumnProps } = {
  inviteDate: { align: 'center', width: '150px' },
  status: { align: 'center', width: '80px' },
  permissions: { align: 'center', width: '60px' },
  options: { align: 'right', width: '52px' },
};

const CONFIG = {
  STATUS,
  PAGINATION,
  COLUMN_STYLES,
} as const;

export default CONFIG;
