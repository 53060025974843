import { threads } from '@lib/agent';
import { queryClient } from '@providers/ReactQueryProvider';
import { AskAIType } from '@shared-types/threads';

export default async function askAIFetcher(args: AskAIType) {
  if (args.type === 'search') {
    const { context, prompt, thread, mode, type, tts } = args;
    return queryClient.fetchQuery({
      queryKey: ['askAI', 'search', thread],
      queryFn: async () =>
        await threads.askAI({
          type,
          thread,
          prompt,
          mode,
          context,
          tts,
        }),
      gcTime: 0,
    });
  }

  const { prompt, thread, type, use_public_knowledge = true } = args;
  //? type === 'assistant'
  return queryClient.fetchQuery({
    queryKey: ['askAI', 'assistant', thread],
    queryFn: async () =>
      await threads.askAI({
        type,
        prompt,
        thread,
        use_public_knowledge, //TODO: camelCase
      }),
    gcTime: 0,
  });
}
