import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, styled } from '@mui/material/styles';

export const StyledLink = styled(Button)(({ theme }) => ({
  '&, &:hover': {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.neutral.grey
        : theme.palette.neutral.black,
    borderRadius: theme.shape.borderRadius * 10,
    border: `1px solid ${alpha(theme.palette.common.white, 0.06)}`,
    backgroundColor:
      theme.palette.mode === 'light'
        ? `${theme.palette.common.white}!important`
        : `${alpha(theme.palette.common.white, 0.1)}!important`,
  },
})) as typeof Button;

export const StyledGradientText = styled(Box)(() => ({
  backgroundClip: 'text!important',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
})) as typeof Box;
