import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import useSideKick from '@components/pages/side-kick-session/hooks/useSideKick';

type Props = {
  title: string;
};

export default function Heading({ title }: Props) {
  const { handleResetDrawers } = useSideKick();

  return (
    <>
      <Stack
        direction="row"
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography variant="subtitle1">{title}</Typography>
        <IconButton onClick={handleResetDrawers}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider orientation="horizontal" sx={{ my: 4 }} />
    </>
  );
}
