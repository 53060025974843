const VOICE_SUGGESTIONS = {
  label: 'Tone of Voice',
  name: 'voice',
  suggestions: [
    'Professional and Formal',
    'Casual and Conversational',
    'Enthusiastic and Motivational',
    'Empathetic and Supportive',
    'Witty and Humorous',
    'Direct and Concise',
  ] as const,
};

const PERSONALITY_SUGGESTIONS = {
  label: 'Mood & Personality',
  name: 'personality',
  suggestions: [
    'Intellectual and Analytical',
    'Cheerful and Optimistic',
    'Calm and Soothing',
    'Creative and Imaginative',
    'Pragmatic and Realistic',
    'Empathetic and Supportive',
  ] as const,
};

const RESPONSE_STYLE_SUGGESTIONS = {
  label: 'Response Length',
  name: 'response_style',
  suggestions: [
    'Moderate and Informative',
    'Brief and Concise',
    'Detailed and Comprehensive',
    'Bullet-Point Summary',
    'Interactive and Conversational',
    'Visual and Graphical',
  ] as const,
};

const COMMUNICATION_STYLES = [
  VOICE_SUGGESTIONS,
  PERSONALITY_SUGGESTIONS,
  RESPONSE_STYLE_SUGGESTIONS,
] as const;

const CONFIG = { COMMUNICATION_STYLES };

export default CONFIG;
