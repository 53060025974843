import { Card, ImageList, ImageListItem, Skeleton } from '@mui/material';

const NUMBERS_OF_PHOTOS = 8;
const IMAGE_SIZE = 100;

export default function Photos() {
  return (
    <Card>
      <ImageList gap={8} cols={2}>
        {Array.from({ length: NUMBERS_OF_PHOTOS }).map((_, i) => (
          <ImageListItem key={i}>
            <Skeleton variant="rectangular" height={IMAGE_SIZE} />
          </ImageListItem>
        ))}
      </ImageList>
    </Card>
  );
}
