import CONFIG_MAIN_LAYOUT from '@layouts/main/config';
import CONFIG_TOOLBAR from '@layouts/main/sections/toolbar/config';
import { StyledGlobalToolbar } from '@layouts/main/sections/toolbar/styles';
import { styled } from '@mui/material';
import List from '@mui/material/List';

export const StyledDesktopMiniToolbar = styled(StyledGlobalToolbar)(
  ({ theme }) => ({
    top: '50%',
    left: CONFIG_MAIN_LAYOUT.MUI_CONTAINER_PADDING_X / 2,
    transform: 'translateY(-50%)',
    borderRadius: theme.spacing(1000),
    background: theme.palette.background.card.main,
    width: CONFIG_TOOLBAR.TOOLBAR_MINI.DESKTOP.WIDTH,
    height: 'fit-content',
    order: 1,
    position: 'fixed',
  }),
);

export const StyledListStack = styled(List)(() => ({
  width: '100%',
  alignSelf: 'flex-start',
})) as typeof List;
