import SectionHeading from '@components/pages/search/components/SectionHeading';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Icon from '@mui/material/Icon';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useTranslation } from '@desygner/ui-common-translation';

const HEIGHT = 120;

type Props = {
  displayLimit: number;
  spaceBetween: number;
  mdSpace: number;
  xsSpace: number;
};

export default function Links({
  displayLimit,
  spaceBetween,
  mdSpace,
  xsSpace,
}: Props) {
  const { t } = useTranslation();

  return (
    <SectionHeading
      title={t('page.search.sections.sources', {
        defaultValue: 'Sources',
      })}
      icon={
        <Icon>
          <FormatListBulletedIcon />
        </Icon>
      }
    >
      <Grid container spacing={spaceBetween}>
        {Array.from({ length: displayLimit }, (_, i) => {
          return (
            <Grid item key={i} xs={xsSpace} md={mdSpace}>
              <Skeleton variant="rounded" height={HEIGHT} key={i} />
            </Grid>
          );
        })}
        <Grid item xs={xsSpace} md={mdSpace}>
          <Skeleton variant="rounded" height={HEIGHT} />
        </Grid>
      </Grid>
    </SectionHeading>
  );
}
