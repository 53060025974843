import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const StyledGradientText = styled(Box)(() => ({
  backgroundClip: 'text!important',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
})) as typeof Box;

export default StyledGradientText;
