import { styled } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import CONFIG from '@layouts/main/config';

const FIT_UNDER_HEADER = 20;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  top: CONFIG.HEADER.HEIGHT + FIT_UNDER_HEADER,
  padding: 0,
  zIndex: 1,
  backgroundColor: theme.palette.background.card.light,
}));

export default StyledAppBar;
