import { BreadcrumbItemType } from '@components/pages/knowledge-base/components/breadcrumb/types';
import useSearchParamsState from '@hooks/useSearchParamsState';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import paths from '@router/paths';
import qs from 'qs';
import { Link } from 'react-router-dom';

type Props = {
  items: BreadcrumbItemType[];
};

export default function BasicBreadcrumb({ items }: Props) {
  const [searchParamsPath] = useSearchParamsState('path', '');

  function calculateBreadcrumb({ path }: { path: string }) {
    const arrayOfPaths = searchParamsPath.split('/');

    if (arrayOfPaths.includes(path)) {
      const index = arrayOfPaths.indexOf(path);
      const slicedArray = arrayOfPaths.slice(0, index + 1);
      const concatenatedPath = slicedArray.join('/');

      return concatenatedPath;
    } else {
      return ''; //? we are at the root folder
    }
  }

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<ChevronRightOutlinedIcon color="disabled" />}
    >
      {items.map((breadcrumb, i) => {
        const lastItem = i === items.length - 1;
        if (lastItem) {
          return (
            <MuiLink
              key={`${breadcrumb.name}-${i}`}
              aria-disabled
              sx={{ pointerEvents: lastItem ? 'none' : 'auto' }}
            >
              <Typography
                variant="body1"
                component={Stack}
                direction="row"
                gap={2}
                color="text.primary"
              >
                {breadcrumb.icon}
                {breadcrumb.name}
              </Typography>
            </MuiLink>
          );
        }

        return (
          <MuiLink
            component={Link}
            key={`${breadcrumb.name}-${i}`}
            to={{
              pathname: paths.knowledgeBase.pathname,
              search: qs.stringify({
                page: 1,
                path: calculateBreadcrumb({ path: breadcrumb.path }),
              }),
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Typography
              variant="body1"
              component={Stack}
              direction="row"
              gap={2}
              color={lastItem ? 'text.primary' : 'text.disabled'}
            >
              {breadcrumb.icon}
              {breadcrumb.name}
            </Typography>
          </MuiLink>
        );
      })}
    </Breadcrumbs>
  );
}
