import Switch from '@components/switch';
import { useTranslation } from '@desygner/ui-common-translation';
import useWorkspace from '@hooks/useWorkspace';
import { ROLES_BY_PATHNAME } from '@lib/roles';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import paths from '@router/paths';
import { useLocation, useNavigate } from 'react-router-dom';

const SETUP_MODE_DESCRIPTION =
  'Setup Mode puts you in control. Manage user access, customize tools, and oversee your workspace effortlessly. Optimize functionality and security with powerful controls designed for efficient team management.';

//TODO: skeleton loader
export default function SetupMode() {
  const { setupMode, workspace, isWorkspaceAdmin, handleSetupMode } =
    useWorkspace();

  const { pathname } = useLocation();

  const navigateTo = useNavigate();

  const { t } = useTranslation();

  function handleSwitchSetupMode(
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) {
    const calculateChosenSetupMode = checked ? 'on' : 'off';

    handleSetupMode(calculateChosenSetupMode);
    const canAccess = ROLES_BY_PATHNAME[
      pathname as keyof typeof ROLES_BY_PATHNAME
    ].includes(calculateChosenSetupMode);

    if (!canAccess && calculateChosenSetupMode === 'on') {
      //? setup mode is enabled, send them to knowledge base
      navigateTo(paths.knowledgeBase.pathname);
    }

    if (!canAccess && calculateChosenSetupMode === 'off') {
      //? setup mode is disabled, send them to the home page
      navigateTo(paths.home.pathname);
    }
  }

  if (!workspace || !isWorkspaceAdmin) return null;

  return (
    <Tooltip
      title={
        <Stack gap={2} sx={{ p: 2 }}>
          <Stack direction="row" gap={2}>
            <Typography variant="body1">
              {t('main.sections.header.setupMode.label', {
                defaultValue: 'Setup Mode',
              })}
            </Typography>
          </Stack>
          <Typography variant="caption">
            {t('main.sections.header.setupMode.description', {
              defaultValue: SETUP_MODE_DESCRIPTION,
            })}
          </Typography>
        </Stack>
      }
      placement="bottom-end"
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 15],
            },
          },
        ],
      }}
    >
      <Stack direction="row" gap={2}>
        <Switch
          checked={setupMode === 'on'}
          onChange={handleSwitchSetupMode}
          label={t('main.sections.header.setupMode.label', {
            defaultValue: 'Setup Mode',
          })}
          labelPlacement="start"
        />
      </Stack>
    </Tooltip>
  );
}
