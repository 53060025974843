import { styled } from '@mui/material';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';

export const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  alignItems: 'center',
  width: '100%',
}));

export const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.drawer,
}));
