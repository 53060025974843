import {
  StyledMuiTableHeadCell,
  StyledPaper,
} from '@components/pages/knowledge-base/components/table/styles';
// import {
//   StyledCheckbox,
//   StyledCheckboxIcon,
//   StyledIntermittentCheckbox,
// } from '@components/pages/knowledge-base/styles';
import { StyledContainerForFixedHeader } from '@components/pages/styles';
import CONFIG from '@components/pages/users/config';
import Header from '@components/pages/users/header';
import useGetAllMemberships from '@components/pages/users/hooks/useGetAllMemberships';
import Skeleton from '@components/pages/users/loading-skeleton';
import { StyledStack } from '@components/pages/users/styles';
// import UserOptions from '@components/pages/users/UserOptions';
import Pagination from '@components/pagination';
import useQueryParams from '@hooks/useQueryParams';
import calculateFullName from '@lib/calculateFullName';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
// import SwapVertIcon from '@mui/icons-material/SwapVert';
import CheckIcon from '@mui/icons-material/Check';
// import Checkbox from '@mui/material/Checkbox';
import MuiTable from '@mui/material/Table';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';
// import MuiTableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import { MinimalUserType } from '@shared-types/auth';
import { MembershipType } from '@shared-types/memberships';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import SidePanel from '@components/pages/users/side-panel';
import IconButton from '@mui/material/IconButton';

export default function Users() {
  const { getQueryParamByKey } = useQueryParams();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedMembership, setSelectedMembership] =
    useState<MembershipType | null>(null);

  const { allMembershipsData, areAllMembershipsLoading } = useGetAllMemberships(
    {
      limit: CONFIG.PAGINATION.LIMIT,
      page: parseInt(getQueryParamByKey('page', '1')),
    },
  );

  const columns = useMemo<ColumnDef<MembershipType>[]>(
    () => [
      // {
      //   id: 'select',
      //   header: ({ table }) => {
      //     return (
      //       <Checkbox
      //         color="primary"
      //         icon={<StyledCheckboxIcon />}
      //         checkedIcon={<StyledCheckbox />}
      //         indeterminateIcon={<StyledIntermittentCheckbox />}
      //         checked={table.getIsAllRowsSelected()}
      //         indeterminate={table.getIsSomeRowsSelected()}
      //         onChange={table.getToggleAllRowsSelectedHandler()}
      //       />
      //     );
      //   },
      //   cell: ({ row }) => (
      //     <Checkbox
      //       icon={<StyledCheckboxIcon />}
      //       checkedIcon={<StyledCheckbox />}
      //       indeterminateIcon={<StyledIntermittentCheckbox />}
      //       checked={row.getIsSelected()}
      //       disabled={!row.getCanSelect()}
      //       indeterminate={row.getIsSomeSelected()}
      //       onChange={row.getToggleSelectedHandler()}
      //     />
      //   ),
      // },
      {
        header: 'User ID',
        accessorKey: 'id',
        cell: ({ row }) => {
          const user = row.original.user as MinimalUserType;
          const hasUser = Boolean(user);

          return (
            <Typography variant="body2" color="text.primary">
              {hasUser ? user.email : row.original.emailInvite}
            </Typography>
          );
        },
      },
      {
        header: 'Name',
        accessorKey: 'user',
        cell: ({ getValue }) => {
          const user = getValue<MinimalUserType>();

          return (
            <Typography variant="body2" color="text.primary">
              {calculateFullName({
                firstName: user?.firstName,
                lastName: user?.lastName,
              })}
            </Typography>
          );
        },
      },
      {
        header: 'Invite Date',
        accessorKey: 'inviteDate',
        cell: ({ getValue, column }) => {
          const inviteDate = getValue<string>();
          return (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                textAlign: CONFIG.COLUMN_STYLES[column.id]?.align || 'left',
              }}
            >
              {format(new Date(inviteDate), 'MMM dd, yyyy')}
            </Typography>
          );
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: ({ getValue, column }) => {
          const status = getValue<number>();
          return (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                textAlign: CONFIG.COLUMN_STYLES[column.id]?.align || 'left',
              }}
            >
              {CONFIG.STATUS[status]}
            </Typography>
          );
        },
      },
      {
        header: 'Admin',
        accessorKey: 'permissions',
        cell: ({ getValue, column, row }) => {
          const permission = getValue<MembershipType['permissions']>();
          return (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                textAlign: CONFIG.COLUMN_STYLES[column.id]?.align || 'left',
              }}
            >
              {'admin' in permission && permission.admin && <CheckIcon />}
            </Typography>
          );
        },
      },
      {
        header: '',
        accessorKey: 'options',
        // console.log(row.original);
        cell: ({ row }) => {
          const membership = row.original as MembershipType;
          const isAdmin =
            'admin' in membership.permissions && membership.permissions.admin;

          return (
            <IconButton
              aria-label="details"
              color="inherit"
              onClick={() => {
                setSelectedMembership(membership);
                setIsDrawerOpen(true);
              }}
            >
              <TroubleshootIcon />
            </IconButton>
            // <UserOptions
            //   options={[
            //     {
            //       text: 'Details',
            //       value: 'details',
            //       onClick: () => {
            //         setSelectedMembership(membership);
            //         setIsDrawerOpen(true);
            //       },
            //     },
            //    {
            //      text: 'Impersonate',
            //      value: 'impersonate',
            //      onClick: () => {},
            //    },
            //    isAdmin
            //      ? {
            //          text: 'Demote',
            //          value: 'demote',
            //          onClick: () => {},
            //        }
            //      : {
            //          text: 'Promote',
            //          value: 'promote',
            //          onClick: () => {},
            //        },
            //    {
            //      text: 'Remove',
            //      value: 'remove',
            //      onClick: () => {},
            //    },
            //   ]}
            // />
          );
        },
      },
    ],
    [],
  );

  const tableData = useReactTable({
    data: allMembershipsData?.data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    enableRowSelection: true,
  });

  return (
    <StyledContainerForFixedHeader disableGutters maxWidth={false}>
      <SidePanel
        membershipId={selectedMembership?.id || null}
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
      />

      <StyledStack gap={6}>
        <Header />
        <StyledPaper>
          <MuiTable>
            <MuiTableHead>
              <MuiTableRow>
                {tableData?.getHeaderGroups().map((headerGroup, i) => {
                  return headerGroup.headers.map((header) => {
                    // const isItSelectCell = header.id === 'select';
                    // const areTheySelectOrActionCell =
                    //   isItSelectCell || header.id === 'action';

                    return (
                      <StyledMuiTableHeadCell
                        align={
                          CONFIG.COLUMN_STYLES[header.column.id]?.align ||
                          'left'
                        }
                        key={header.column.id}
                        sx={
                          {
                            // ...(isItSelectCell && {
                            //   width: '10px',
                            // }),
                          }
                        }
                      >
                        {/* <MuiTableSortLabel
                          active={!areTheySelectOrActionCell}
                          hideSortIcon={areTheySelectOrActionCell}
                          IconComponent={SwapVertIcon}
                          sx={{
                            width: '100%',
                            justifyContent: 'space-between',
                          }}
                        > */}
                        <span>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </span>
                        {/* </MuiTableSortLabel> */}
                      </StyledMuiTableHeadCell>
                    );
                  });
                })}
              </MuiTableRow>
            </MuiTableHead>

            <MuiTableBody>
              {areAllMembershipsLoading ? (
                <Skeleton
                  headItemsConfig={tableData
                    .getAllColumns()
                    .map((column) => CONFIG.COLUMN_STYLES[column.id])}
                  headItems={tableData
                    .getAllColumns()
                    .map((column) => column.id)}
                  numberOfRows={CONFIG.PAGINATION.LIMIT}
                />
              ) : (
                tableData.getRowModel().rows.map((row) => (
                  <MuiTableRow key={row.original.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <MuiTableCell
                          key={cell.id}
                          sx={{
                            ...(CONFIG.COLUMN_STYLES[cell.column.id]?.width
                              ? {
                                  width:
                                    CONFIG.COLUMN_STYLES[cell.column.id]?.width,
                                }
                              : {}),
                          }}
                          align={
                            CONFIG.COLUMN_STYLES[cell.column.id]?.align ||
                            'left'
                          }
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </MuiTableCell>
                      );
                    })}
                  </MuiTableRow>
                ))
              )}
            </MuiTableBody>
          </MuiTable>
        </StyledPaper>

        {allMembershipsData &&
          allMembershipsData.meta.pagination.pageCount > 0 && (
            <Pagination
              pageCount={allMembershipsData.meta.pagination.pageCount}
            />
          )}
      </StyledStack>
    </StyledContainerForFixedHeader>
  );
}
