import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import CONFIG_MAIN from '@layouts/main/config';
import CONFIG_TOOLBAR from '@layouts/main/sections/toolbar/config';
import { convertToRem } from '@lib/fonts';

import 'sequential-workflow-designer/css/designer-dark.css';
import 'sequential-workflow-designer/css/designer-light.css';
import 'sequential-workflow-designer/css/designer.css';

const CONFIG = {
  PADDING: CONFIG_MAIN.HEADER.PADDING_Y / 2,
  TOP_MENUS: CONFIG_MAIN.HEADER.HEIGHT + CONFIG_MAIN.HEADER.PADDING_Y,
  CONTROL_BAR: {
    TOP: 21,
  },
  EDITOR_WIDTH: 400,
};

export const StyledApp = styled('div', {
  shouldForwardProp: (props) => props !== 'isRunning' && props !== 'isLoggedIn',
})<{ isRunning: boolean, isLoggedIn: boolean }>(({ theme, isRunning, isLoggedIn }) => ({
  height: '100vh',

  ...(isRunning && {
    pointerEvents: 'none',
    opacity: 0.8,
  }),

  '.sqd-designer': {
    background: 'transparent',
  },

  '.sqd-line-grid-path': {
    // stroke: theme.palette.background.paper,
    stroke: 'transparent',
  },

  '.sqd-workspace': {
    // background: theme.palette.background.paper,
    background: 'transparent',
  },

  '.sqd-designer-react': {
    height: '100%',
  },

  '.sqd-editor': {
    height: '100%',
  },

  // TODO: this is really hacky. Instead, we should extend Header
  '.sqd-control-bar': {
    border: 'none',
    boxShadow: 'none',
    top: CONFIG.CONTROL_BAR.TOP,
    right: isLoggedIn ? '170px' : '220px',
    left: 'initial',
    bottom: 'initial',
    zIndex: theme.zIndex.drawer,
    background: 'transparent',
  },

  '.sqd-control-bar-button': {
    border: 'none',
    background: 'transparent',

    '&:hover': {
      background: 'transparent',
    },

    '& .sqd-icon-path': {
      fill: theme.palette.text.primary,
    },
  },

  '.sqd-toolbox-toggle-icon': {
    fill: theme.palette.text.primary,
  },

  '.sqd-toolbox-header-title': {
    fontSize: '1.14286rem', // TODO: use theme
    fontWeight: 400,
    lineHeight: 1.5,
  },

  '.sqd-toolbox': {
    zIndex: theme.zIndex.drawer,
    top: CONFIG.TOP_MENUS,
    left: CONFIG.PADDING,
    padding: CONFIG.PADDING,
    boxShadow: theme.customShadows.card,
    borderRadius: theme.shape.borderRadius * 4,
    width: convertToRem(CONFIG_TOOLBAR.WIDTH_EXPANDED),
    background: theme.palette.background.card.light,
  },

  '.sqd-scrollbox-body': {
    width: '100%',
  },

  '.sqd-toolbox-filter': {
    width: '100%',
    margin: '0 0 0.5rem 0',
    lineHeight: 1.5, // TODO: use theme
    fontSize: '1rem',
  },

  '.sqd-toolbox-item': {
    width: '100%',
    margin: 0,
    border: 'none',
    boxShadow: 'none',
    lineHeight: 1.5, // TODO: use theme
    fontSize: '1rem',
    background: 'transparent',

    '.sqd-toolbox-item-text': {
      color: theme.palette.text.primary,
    },

    '&:hover': {
      background: theme.palette.action.hover,
    },

    '.sqd-toolbox-item-icon-image': {
      // TODO: remove this filter once we support icons instead of images
      filter: theme.palette.mode === 'dark' ? 'invert(1);' : ''
    }
  },

  '.sqd-toolbox-group-title': {
    display: 'none',
  },

  '.sqd-smart-editor-toggle': {
    display: 'none',
  },

  '.sqd-smart-editor': {
    position: 'fixed',
    width: CONFIG.EDITOR_WIDTH,
    top: CONFIG.TOP_MENUS,
    right: CONFIG.PADDING,
    overflow: 'hidden',
    boxShadow: theme.customShadows.card,
    borderRadius: theme.shape.borderRadius * 4,
    height: `calc(100% - ${convertToRem(CONFIG.PADDING)} - ${
      CONFIG.TOP_MENUS
    }px)`,
  },

  '.sqd-step-editor': {
    height: '100%',
  },

  '.sqd-editor-react': {
    // paddingTop: 68,
    paddingTop: 22,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    background: theme.palette.background.card.light,
  },

  // Canvas
  '.sqd-join': {
    strokeWidth: 1,
    stroke: theme.palette.background.card.dark,
  },

  '.sqd-step-task .sqd-step-task-rect.sqd-selected': {
    strokeWidth: 1,
    stroke: theme.palette.brand.purple.main,
  },

  '.sqd-region.sqd-selected': {
    strokeWidth: 1,
    stroke: theme.palette.brand.purple.main,
    strokeDasharray: 1,
  },

  '.sqd-region': {
    stroke: '#cecece',
    strokeWidth: 1,
    strokeDasharray: 3,
  },

  '.sqd-root-start-stop-circle': {
    fill: theme.palette.brand.purple.main,
  },
}));

export const StyledMenuToggle = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: CONFIG.TOP_MENUS,
  right: CONFIG.PADDING,
  zIndex: theme.zIndex.drawer,
  background: theme.palette.background.card.light,
  padding: '27px 22px', // 12 + 15, 12 + 10
  borderRadius: theme.shape.borderRadius * 4,
  width: CONFIG.EDITOR_WIDTH,
  cursor: 'pointer',

  '&.collapsed': {
    boxShadow: theme.customShadows.card,
  },

  '& > div': {
    position: 'relative',
  },
}));

export const StyledAlert = styled(Alert)(({ theme }) => ({
  position: 'fixed',
  bottom: CONFIG.PADDING,
  left: '50%',
  zIndex: theme.zIndex.drawer + 1,
  transform: 'translateX(-50%)',
}));

export default StyledApp;
