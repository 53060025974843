const ASSISTANTS = {
  IMAGE: {
    SIZE: 60,
  },
  CARD: {
    WIDTH: 220,
    HEIGHT: 160,
  },
  DRAWER: {
    DESKTOP: {
      WIDTH: 500,
    },
    MOBILE: {
      WIDTH: 350,
    },
  },
};

const CONFIG = {
  ASSISTANTS,
} as const;

export default CONFIG;
