import { useContext } from 'react';
import { ThemeSettingsContext } from '@providers/ThemeSettingsProvider';

export default function useThemeSettings() {
  const context = useContext(ThemeSettingsContext);

  if (!context)
    throw new Error(
      'useThemeSettings must be used within a ThemeSettingsProvider',
    );

  return context;
}
