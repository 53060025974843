import { useMemo } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import List from '@components/list';
import {
  StyledContainer,
  StyledHeaderContainer,
  StyledButtonWrapper,
  StyledListItemContainer,
  StyledListWorkspaceIconWrapper,
  StyledRightIconWrapper,
} from '@components/pages/audit/sections/account-list/styles';
import { mockAccountData } from '@components/pages/audit/mockData';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Stack from '@mui/material/Stack';

export interface AccountListItemProps {
  workspaceName: string;
  workspaceIcon: string;
  userName: string;
}

export interface AccountListProps {
  toggleAppStatus: () => void;
}

export function AccountList({ toggleAppStatus }: AccountListProps) {
  const listItems = useMemo(() => createListItem(), []);

  return (
    <StyledContainer>
      <StyledHeaderContainer direction="row">
        <Typography variant="body1">Connected accounts</Typography>
        <IconButton size="small">
          <AddCircleOutlineIcon />
        </IconButton>
      </StyledHeaderContainer>
      <List children={listItems} hasHover={true} />
      <StyledButtonWrapper>
        <Button onClick={toggleAppStatus} variant="outlined">
          See app status
        </Button>
      </StyledButtonWrapper>
    </StyledContainer>
  );
}

function AccountListItem({
  workspaceName,
  workspaceIcon,
  userName,
}: AccountListItemProps) {
  return (
    <StyledListItemContainer>
      <StyledListWorkspaceIconWrapper>
        {workspaceIcon}
      </StyledListWorkspaceIconWrapper>
      <Stack direction="column" gap={1}>
        <Typography variant="body1">{workspaceName}</Typography>
        <Typography
          variant="body2"
          // color={Theme.Color.Neutrals.Text}
        >
          {userName}
        </Typography>
      </Stack>
      <StyledRightIconWrapper>
        <IconButton>
          <ChevronRightIcon />
        </IconButton>
      </StyledRightIconWrapper>
    </StyledListItemContainer>
  );
}

function loadData() {
  // TODO: Hook up with APIs.
  return mockAccountData;
}

function createListItem() {
  const data = loadData();
  const items: React.ReactNode[] = [];

  data.forEach((itemProps: AccountListItemProps, i) =>
    items.push(<AccountListItem key={i} {...itemProps} />),
  );

  return items;
}
