import { AssistantResultsType } from '@shared-types/assistants';
import { SearchResultsType } from '@shared-types/search-result/types';

export enum WSEvents {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  ERROR = 'error',
  MESSAGE = 'message',
}

export type WSResponseType = {
  assistant: AssistantResultsType;
  searchResult: SearchResultsType;
};
