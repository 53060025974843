import CONFIG from '@layouts/main/config';
import { convertToRem } from '@lib/fonts';
import { styled } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';

export const StyledStack = styled(Stack)(() => ({
  justifyContent: 'space-between',
}));

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'transparent',
  nav: {
    boxShadow: theme.customShadows.card,
  },
}));

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.background.card.light,
  borderRadius: theme.shape.borderRadius * 4,
  height: convertToRem(CONFIG.HEADER.HEIGHT),
  justifyContent: 'space-between',
  width: '100%',
})) as typeof Toolbar;
