import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

const StyledStack = styled(Stack)(() => ({
  width: '100%',
  margin: '0 auto',
  alignItems: 'center',
}));

export default StyledStack;
