import TextareaAutosize, {
  TextareaAutosizeProps,
} from '@mui/material/TextareaAutosize';
import { Controller, useFormContext } from 'react-hook-form';

type Props = TextareaAutosizeProps & {
  name: string;
};

export default function RHFTextarea({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextareaAutosize
          value={field.value}
          onChange={field.onChange}
          {...other}
        />
      )}
    />
  );
}
