import { StyledPopper } from '@components/dropdown/styles';
import EllipsisTypography from '@components/ellipsis-typography';
import UserAuthentication from '@components/user-authentication';
import { useTranslation } from '@desygner/ui-common-translation';
import useAuth from '@hooks/useAuth';
import usePaymentWithStripe from '@hooks/usePaymentWithStripe';
import useSearchParamsState from '@hooks/useSearchParamsState';
import useThemeSettings from '@hooks/useThemeSettings';
import {
  StyledListItemText,
  StyledPaper,
} from '@layouts/main/sections/header/sections/user-account-popover/styles';
import { StyledStack } from '@layouts/main/sections/header/styles';
import ThemeMode from '@layouts/main/sections/header/them-mode';
import calculateFullName from '@lib/calculateFullName';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import paths from '@router/paths';
import { useRef, useState } from 'react';

const AVATAR_SIZE = 32;

const STAR_SIZE = 21;

const SHARED_PROPS: ListItemProps = {
  disablePadding: true,
  disableGutters: true,
};

export default function UserAccountPopover() {
  const { t } = useTranslation();

  const { me } = useAuth();

  const { isLoading, handleRedirectToStripe } = usePaymentWithStripe();

  const [openSettings, setOpenSettings] = useState(false);

  const [, setSecureAccountParams] = useSearchParamsState('action', '');

  const handleClose = (event: Event | React.SyntheticEvent) => {
    const isClickingInside = anchorRef.current?.contains(
      event.target as HTMLElement,
    );
    isClickingInside || setOpenSettings(false);
  };

  const hasSecuredAccount =
    me && (me.auth_type === 'email' || me.auth_type === 'oauth2');

  const anchorRef = useRef<HTMLButtonElement>(null);

  const { toggleThemeMode, themeMode } = useThemeSettings();

  function handleThemeChange() {
    toggleThemeMode(themeMode === 'light' ? 'dark' : 'light');
  }

  function handleSecureAccountParams() {
    setSecureAccountParams('secure-account');
  }

  const fullName = calculateFullName({
    firstName: me?.first_name,
    lastName: me?.last_name,
  });

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={() => setOpenSettings(!openSettings)}
      >
        <Avatar
          sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
          src={me?.profile_picture}
          alt={`${fullName} avatar`}
        />
      </IconButton>

      <StyledPopper
        anchorEl={anchorRef.current}
        open={openSettings}
        keepMounted
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 20],
            },
          },
        ]}
        placement="bottom-end"
      >
        <Grow in>
          <StyledPaper square>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <ListItem {...SHARED_PROPS}>
                  <StyledStack direction="row" gap={2} sx={{ px: 4, py: 2 }}>
                    <Avatar
                      sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
                      src={me?.profile_picture}
                      alt={`${fullName} avatar`}
                    />
                    <Box>
                      <StyledListItemText
                        primary={
                          <EllipsisTypography
                            component="span"
                            lineClamp={1}
                            title={fullName}
                          >
                            {fullName}
                          </EllipsisTypography>
                        }
                        primaryTypographyProps={{
                          variant: 'body2',
                        }}
                      />

                      {!hasSecuredAccount && (
                        <ListItemButton
                          disableGutters
                          disableRipple
                          disableTouchRipple
                          sx={{
                            color: 'primary.main',
                            '&:hover': {
                              bgcolor: 'transparent',
                            },
                            p: 0,
                          }}
                          onClick={handleSecureAccountParams}
                        >
                          <StyledListItemText
                            primary={t(
                              'layout.main.sections.userAccount.createYourAccount',
                              {
                                defaultValue: 'Create your account',
                              },
                            )}
                            primaryTypographyProps={{
                              variant: 'body2',
                            }}
                          />
                        </ListItemButton>
                      )}
                    </Box>
                  </StyledStack>
                </ListItem>
                <Divider />
                <ListItem {...SHARED_PROPS}>
                  <ListItemButton
                    onClick={handleRedirectToStripe}
                    disabled={isLoading}
                  >
                    <StyledStack direction="row" gap={2}>
                      <img
                        src="https://assets.knowz.com/assets/images/star.png"
                        alt="buy more credits"
                        width={STAR_SIZE}
                        height={STAR_SIZE}
                        loading="lazy"
                      />
                      <StyledListItemText
                        primary={t(
                          'layout.main.sections.userAccount.butMoreCredits',
                          {
                            defaultValue: 'Buy more credits',
                          },
                        )}
                        primaryTypographyProps={{
                          variant: 'body2',
                        }}
                      />
                    </StyledStack>
                  </ListItemButton>
                </ListItem>

                <ListItem {...SHARED_PROPS}>
                  <ListItemButton
                    disableRipple
                    disableTouchRipple
                    onClick={handleThemeChange}
                    sx={{ justifyContent: 'start', gap: 1 }}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <StyledStack direction="row" gap={2}>
                        {themeMode === 'light' ? (
                          <WbSunnyOutlinedIcon />
                        ) : (
                          <DarkModeOutlinedIcon />
                        )}
                        <StyledListItemText
                          primary={t('main.sections.header.theme', {
                            defaultValue: 'Theme',
                          })}
                          primaryTypographyProps={{
                            variant: 'body2',
                          }}
                        />
                      </StyledStack>
                      <ThemeMode />
                    </Stack>
                  </ListItemButton>
                </ListItem>

                <ListItem {...SHARED_PROPS}>
                  <ListItemButton href={paths.tos.pathname} target="_blank">
                    <StyledStack direction="row" gap={2}>
                      <ContentPasteIcon />
                      <StyledListItemText
                        primary={t('main.sections.userAccount.tos', {
                          defaultValue: 'Terms of Service',
                        })}
                        primaryTypographyProps={{
                          variant: 'body2',
                        }}
                      />
                    </StyledStack>
                  </ListItemButton>
                </ListItem>

                {hasSecuredAccount && (
                  <>
                    <Divider />
                    <ListItem {...SHARED_PROPS}>
                      <UserAuthentication />
                    </ListItem>
                  </>
                )}
              </Box>
            </ClickAwayListener>
          </StyledPaper>
        </Grow>
      </StyledPopper>
    </>
  );
}
