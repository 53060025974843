import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_KNOWZ_AUTH_URL || '',
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    return Promise.reject(error || 'Something went wrong');
  },
);

axiosInstance.interceptors.request.use(
  (request) => request,
  (error) => {
    console.error(error);
    return Promise.reject(error || 'Something went wrong');
  },
);

export default axiosInstance;
