import { MenuItemProps } from '@mui/material';
import { ThemeColorType, ThemeType } from '@shared-types/theme';

type Props = MenuItemProps & {
  ownerState: unknown;
};

export default function MenuItem(theme: ThemeType) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: (props: Props) => {
          const ownerState = props.ownerState as { color: ThemeColorType };
          const color = ownerState.color;
          return {
            color: color
              ? theme.palette[ownerState.color].main
              : theme.palette.text.primary,
            padding: theme.spacing(1.5, 2),
            '&:hover': {
              borderRadius: theme.shape.borderRadius * 1.25,
            },
          };
        },
      },
    },
  };
}
