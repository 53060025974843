//TODO: there are some static CSS rule sets that need to be dynamic.
import useGetHistoryBlocksByThreadIdLazily from '@components/pages/hooks/useGetHistoryBlocksByThreadIdLazily';
import LoadingSkeleton from '@components/pages/search/components/loading-skeleton';
import AnswerBlock from '@components/pages/search/components/shared-blocks/answer';
import FollowupsBlock from '@components/pages/search/components/shared-blocks/followups';
import InfoBlock from '@components/pages/search/components/shared-blocks/info';
import Layout from '@components/pages/search/components/shared-blocks/layout';
import LinksBlock from '@components/pages/search/components/shared-blocks/links';
import PhotosBlock from '@components/pages/search/components/shared-blocks/photos';
import PromptBlock from '@components/pages/search/components/shared-blocks/prompt';
import SinglePhotosCol from '@components/pages/search/components/shared-blocks/single-photos-col';
import TermsOfService from '@components/pages/search/components/shared-blocks/tos';
import { useTranslation } from '@desygner/ui-common-translation';
import useQueryParams from '@hooks/useQueryParams';
import Alert from '@mui/material/Alert';
import { HistoryBlocksDataType } from '@shared-types/search-result/history-blocks';
import { useMemo } from 'react';

export default function HistoryBlocks() {
  const { getQueryParamByKey } = useQueryParams();

  const { t } = useTranslation();

  const {
    historyBlocksQueryData,
    isHistoryBlocksLoading,
    isHistoryBlocksError,
  } = useGetHistoryBlocksByThreadIdLazily<HistoryBlocksDataType>({
    threadId: getQueryParamByKey('t'),
  });

  const userHistoryBlocks = useMemo(
    () => (historyBlocksQueryData || []).filter((item) => item.role !== 'user'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [historyBlocksQueryData?.length],
  );

  if (isHistoryBlocksLoading) {
    return <LoadingSkeleton />;
  }

  if (isHistoryBlocksError) {
    return (
      <Alert severity="error">
        {t('response.errors.code.520', {
          defaultMessage: 'Unknown error. Please try again later.',
        })}
      </Alert>
    );
  }

  return userHistoryBlocks?.map((item, index: number, originalArray) => {
    if (item.tos != null) {
      return <TermsOfService tos={item.tos} />;
    }

    const layoutData = item.layout;
    const photosData = layoutData.photos;
    const infoData = layoutData.info;
    const lastFollowup = originalArray.length - 1 === index;
    const hasAnswer = Boolean(
      layoutData.answer && layoutData.answer.response.length > 0,
    );
    const hasAtLeastOneInfoCard = Boolean(infoData.length > 0); //? for now we get one info card

    return (
      <Layout
        key={index}
        answer={
          hasAnswer ? (
            <AnswerBlock
              type="history"
              answer={layoutData.answer!.response}
              id={layoutData.answer!.id}
            />
          ) : undefined
        }
        info={
          <InfoBlock
            text={hasAtLeastOneInfoCard ? infoData[0].response : null}
          />
        }
        followups={
          lastFollowup ? (
            <FollowupsBlock followups={item.followups} />
          ) : undefined
        }
        prompt={<PromptBlock prompt={layoutData.userQuery} />}
        photos={
          <PhotosBlock
            hasAnswer={hasAnswer}
            hasInfo={hasAtLeastOneInfoCard}
            photosData={photosData}
          />
        }
        links={<LinksBlock links={layoutData.links} />}
        singlePhotosCol={
          <SinglePhotosCol
            hasAnswer={hasAnswer}
            hasInfo={hasAtLeastOneInfoCard}
            photosData={photosData}
          />
        }
      />
    );
  });
}
