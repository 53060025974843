import { styled } from '@mui/material/styles';

// eslint-disable-next-line import/prefer-default-export
export const StyledTextArea = styled('textarea')(() => ({
  appearance: 'none',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  resize: 'none',
  background: 'transparent',
  fontSize: '13px',
  lineHeight: '13px',
  padding: '7px 6px',
  fontFamily: 'Work Sans Variable, sans-serif;',
  color: 'transparent',
  caretColor: 'white',
  outline: 'none',
  overflow: 'hidden',
  border: 'none',
}));
