import useAssistantsDrawer from '@components/pages/assistants/hooks/useAssistantsDrawer';
import AddAssistantsDrawer from '@components/pages/assistants/sections/drawer/add-assistants';
import IntegrateAssistantsDrawer from '@components/pages/assistants/sections/drawer/integrate-assistants';
import Wrapper from '@components/pages/assistants/sections/drawer/wrapper';

export default function Drawer() {
  const { isAddNewAssistantsOpen, openIntegrateAssistantsById } =
    useAssistantsDrawer();
  let DrawerToOpen;

  if (isAddNewAssistantsOpen) {
    DrawerToOpen = <AddAssistantsDrawer />;
  }

  if (typeof openIntegrateAssistantsById === 'string')
    DrawerToOpen = <IntegrateAssistantsDrawer />;

  return <Wrapper>{DrawerToOpen}</Wrapper>;
}
