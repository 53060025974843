import { KnowzStepDefinition } from '@lib/step/types';

// Manifest is just a source that is upcreated and maintained by the command
// We will refer to them by aliases (so we can configure even when not existing yet)
const LoadManifestStepDefinition: KnowzStepDefinition = {
  order: 201,
  group: 'Knowz Workspace',
  step: {
    componentType: 'task',
    type: 'load-manifest',
    name: 'Load Manifest',
  },
  propertyTypes: [
    {
      id: 'name',
      name: 'Manifest Name',
      description: 'Name of the manifest to load',
      usage: 'definition',
      wrapper: 'item',
      variant: 'short',
      types: ['string'],
    },
    {
      id: 'output',
      name: 'Source reference',
      description:
        'Assign a name to the source for later reference it in your command',
      usage: 'output',
      wrapper: 'item',
      types: ['ref-source'],
    },
  ],
};

export default LoadManifestStepDefinition;
