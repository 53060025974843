import { KnowzStepDefinition } from '@lib/step/types';

const TransientStepDefinition: KnowzStepDefinition = {
  order: 201,
  group: 'AI',
  step: {
    componentType: 'task',
    type: 'transient',
    name: 'AI Transient',
  },
  propertyTypes: [
    {
      id: 'inputs',
      name: 'Prompt context',
      description: 'Define the different inputs for the prompt',
      usage: 'input',
      wrapper: 'list',
      types: [
        'ref-json',
        'ref-string',
        'ref-source',
        'ref-thread',
        'ref-history-block',
      ],
    },
    {
      id: 'prompt',
      name: 'Smart prompt',
      description:
        'Describe the task to the AI model, inlining the inputs you defined',
      usage: 'definition',
      wrapper: 'item',
      types: ['string'],
    },
    {
      id: 'output',
      name: 'Result reference',
      description:
        'Assign a name to the resulting text, for later referencing in your command',
      usage: 'output',
      wrapper: 'item',
      types: ['ref-string'],
    },
  ],
};

export default TransientStepDefinition;
