import { styled } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

export const StyledList = styled(List)(({ theme }) => ({
  width: '100%',
  maxHeight: theme.spacing(37.5),
  overflowY: 'auto',
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}));
