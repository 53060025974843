import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import paths from '@router/paths';
import { FileList } from '@components/pages/audit/sections/file-name';
import { AccountList } from '@components/pages/audit/sections/account-list';
import Stack from '@mui/material/Stack';

export default function Audit() {
  const navigate = useNavigate();

  return (
    <Stack direction="column">
      <Stack direction="column" gap={2}>
        <Typography component="h1" variant="h1">
          Audit Trail
        </Typography>
        <Typography
          component="p"
          variant="body1"
          sx={{ color: 'red' }}
          //color={Theme.Color.Neutrals.Typography}
        >
          Connect all your tools now!
        </Typography>
      </Stack>

      <Stack direction="row" gap={4}>
        <FileList />
        <AccountList toggleAppStatus={() => navigate(paths.status.pathname)} />
      </Stack>
    </Stack>
  );
}
