import { alpha, styled } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { NavLink } from 'react-router-dom';

export const StyledGlobalToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  justifyContent: 'center',
  padding: theme.spacing(0, 1.25),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1.25),
  },
}));

export const StyledNavLink = styled(NavLink)(({ theme }) => ({
  //? the .active class comes from NavLink more here => https://beta.reactrouter.com/en/dev/start/tutorial#active-link-styling
  '&.active': {
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
    svg: {
      color: theme.palette.primary.light,
    },
  },
}));
