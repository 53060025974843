import { useTranslation } from '@desygner/ui-common-translation';
import useWorkspace from '@hooks/useWorkspace';
import { folders } from '@lib/agent';
import { queryClient } from '@providers/ReactQueryProvider';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

export default function useDeleteFolderById() {
  const { t } = useTranslation();
  const { getNamespacedQueryKey } = useWorkspace();

  const { mutate: mutateFolder } = useMutation({
    mutationFn: async ({ id }: { id: number }) => {
      const { data } = await folders.deleteOne({
        id,
      });
      return data;
    },
    onError: (error) => {
      if (
        isAxiosError(error) &&
        error.response?.status === 400 &&
        error.response?.data?.message === 'Folder has sources'
      ) {
        toast.error(
          t(
            'page.knowledgeBase.admin.actions.delete.folder.toast.deleteConstraint',
            {
              defaultValue: 'Folder has got some sources in it.',
            },
          ),
        );
      }
      throw new Error(`An error occurred while deleting a folder: ${error}`);
    },
    onSuccess: async (_data) => {
      toast.success(
        t('page.knowledgeBase.admin.actions.delete.folder.toast.success', {
          defaultValue: 'Folder has been deleted successfully.',
        }),
      );
      //TODO: we can remove if from cache instead of invalidating folders
      queryClient.invalidateQueries({
        queryKey: getNamespacedQueryKey('folders'),
      });
    },
  });

  return {
    mutateFolder,
  };
}
