import { ThemeType } from '@shared-types/theme';

export default function Toolbar(theme: ThemeType) {
  return {
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: `${theme.spacing(0)}!important`,
        },
      },
    },
  };
}
