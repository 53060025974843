import { DependencyList, useEffect } from 'react';

export default function useScrollIntoView(
  ref: React.RefObject<HTMLElement>,
  deps: Partial<DependencyList> = [],
  condition = true,
) {
  useEffect(() => {
    if (condition && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
