import Wrapper from '@layouts/assistants-session/components/wrapper';
import AssistantsSessionProvider from '@layouts/assistants-session/providers/AssistantsSessionProvider';
import { StyledStack } from '@layouts/styles';

export default function AssistantsSessionLayout({
  children,
}: React.PropsWithChildren) {
  return (
    <AssistantsSessionProvider>
      <AssistantsSessionProvider.Header />
      <StyledStack direction="row">
        <Wrapper>
          <AssistantsSessionProvider.Main>
            {children}
          </AssistantsSessionProvider.Main>
          <AssistantsSessionProvider.Drawer />
        </Wrapper>
      </StyledStack>
    </AssistantsSessionProvider>
  );
}
