import SetupModeAlert from '@layouts/components/header-wrapper/setup-mode-alert';
import { StyledAppBar, StyledToolbar } from '@layouts/styles';
import { AppBarProps } from '@mui/material';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

export default function HeaderWrapper({
  children,
  position = 'fixed',
}: React.PropsWithChildren<{ position?: AppBarProps['position'] }>) {
  return (
    <Container maxWidth={false}>
      <StyledAppBar position={position}>
        <Stack sx={{ width: '100%' }}>
          <SetupModeAlert />
          <StyledToolbar component="nav" sx={{ px: { md: 6 }, py: 3 }}>
            {children}
          </StyledToolbar>
        </Stack>
      </StyledAppBar>
    </Container>
  );
}
