import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const SOCIALS = [
  {
    title: 'Facebook',
    href: 'https://www.facebook.com/knowzit',
    icon: <FacebookIcon />,
  },
  {
    title: 'Instagram',
    href: 'https://www.instagram.com/knowzai',
    icon: <InstagramIcon />,
  },
  {
    title: 'LinkedIn',
    href: 'https://www.linkedin.com/company/knowzsearch',
    icon: <LinkedInIcon />,
  },
];

export default SOCIALS;
