import SearchableDropdown from '@components/dropdown/search';
import CONFIG from '@components/workspace/config';
import WorkspaceSetup from '@components/workspace/setup';
import { ButtonVariantsType } from '@components/workspace/types';
import Default from '@components/workspace/workspace-selector/triggers/Default';
import Mini from '@components/workspace/workspace-selector/triggers/Mini';
import Mobile from '@components/workspace/workspace-selector/triggers/Mobile';
import { StyledListItem } from '@components/workspace/workspace-selector/triggers/styles';
import useQueryParams from '@hooks/useQueryParams';
import useWorkspace from '@hooks/useWorkspace';
import useGetAllWorkspaces from '@layouts/main/sections/toolbar/hooks/useGetAllWorkspaces';
import { StyledAddWorkspaceIcon } from '@layouts/main/sections/toolbar/mini/styles';
import AddIcon from '@mui/icons-material/Add';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { alpha, PopperPlacementType, useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';

type WorkspaceType = {
  id: string;
  name: string;
};

type DropdownType = Record<
  ButtonVariantsType,
  { offset: number[]; placement: PopperPlacementType }
>;

const DROPDOWN: DropdownType = {
  mobile: { offset: [0, 15], placement: 'top-end' },
  mini: { offset: [0, 15], placement: 'right' },
  default: { offset: [-4, 8], placement: 'right-start' },
};

const SHARED_PROPS = {
  disablePadding: true,
  disableGutters: true,
};

const TRIGGER_BUTTONS: Record<ButtonVariantsType, React.ReactNode> = {
  default: <Default />,
  mini: <Mini />,
  mobile: <Mobile />,
};

const SHARED_SIZES = {
  width: CONFIG.AVATAR_SIZE,
  height: CONFIG.AVATAR_SIZE,
};

type Props = {
  variant: keyof typeof DROPDOWN;
};

export default function WorkspaceSelector({ variant }: Props) {
  const theme = useTheme();
  const { handleSetWorkspace } = useWorkspace();
  const { setQueryParam } = useQueryParams();

  const {
    refetchWorkspaces,
    isWorkspaceLoading,
    workspaceData,
    hasWorkspaceError,
  } = useGetAllWorkspaces();

  function handleRefetchWorkspaces() {
    workspaceData || refetchWorkspaces();
  }

  function handleSelectWorkspace(
    { id: workspaceId }: Pick<WorkspaceType, 'id'> = { id: '' },
  ) {
    handleSetWorkspace(
      workspaceData?.find(({ id }) => id === workspaceId) || null,
    );
    setQueryParam('path', ''); //? go to the root folder when workspace is changed
  }

  function handleOpenWorkspaceSetup() {
    setQueryParam('action', 'setup-workspace');
  }

  //TODO: we need to work a bit on html tags here to make it more readable and accessible.
  return (
    <>
      <SearchableDropdown
        sx={{
          menuItemSx: {
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.light, 0.1),
            },
          },
          paperSx: {
            padding: 1.5,
          },
        }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: DROPDOWN[variant].offset,
            },
          },
        ]}
        trigger={{
          onClick: handleRefetchWorkspaces,
          element: TRIGGER_BUTTONS[variant],
        }}
        placement={DROPDOWN[variant].placement as PopperPlacementType}
        areOptionsLoading={isWorkspaceLoading}
        haveOptionsError={hasWorkspaceError}
        options={[
          {
            icon: {
              iconStart: (
                <StyledListItem
                  component="div"
                  {...SHARED_PROPS}
                  sx={SHARED_SIZES}
                >
                  <HomeOutlinedIcon />
                </StyledListItem>
              ),
            },
            textForSearch: 'My Space',
            element: 'My Space',
            onClick: () => handleSelectWorkspace(),
            value: 'My Space',
          },
          ...(workspaceData
            ? workspaceData.map(({ name, id }) => ({
                textForSearch: name,
                element: name,
                value: id,
                onClick: () => handleSelectWorkspace({ id }),
                icon: {
                  iconStart: (
                    <Avatar sx={SHARED_SIZES}>{name[0].toUpperCase()}</Avatar>
                  ),
                },
              }))
            : []),
          {
            icon: {
              iconStart: (
                <StyledAddWorkspaceIcon sx={SHARED_SIZES}>
                  <AddIcon />
                </StyledAddWorkspaceIcon>
              ),
            },
            textForSearch: 'add workspace',
            element: 'add workspace',
            onClick: handleOpenWorkspaceSetup,
            value: 'add workspace',
          },
        ]}
      />
      <WorkspaceSetup />
    </>
  );
}
