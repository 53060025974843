import { threads } from '@lib/agent';
import { queryClient } from '@providers/ReactQueryProvider';
import { Dispatch, ListenerEffectAPI } from '@reduxjs/toolkit';
import { MonologueType } from '@shared-types/sidekick';
import { dialogueUpdated, initialStateType } from '@state/slices/transcription';
import { PaymentRequiredReasonType } from '@state/utils/types';
import { produce } from 'immer';

export default async function processAsyncFactCheckOnlyAndDispatchDialogueUpdated(
  currentDialogue: MonologueType,
  listenerApi: ListenerEffectAPI<
    { transcription: initialStateType },
    Dispatch,
    unknown
  >,
) {
  const theMostUpdatedDialogue = listenerApi.getState().transcription.dialogue;

  const factCheckPromise = threads.getFactCheck({
    prompt: currentDialogue.content,
    mode: 'regular',
  });
  const [factCheckResponse] = await Promise.allSettled([factCheckPromise]);

  const updatedDialogue = produce(theMostUpdatedDialogue, (draft) => {
    const index = draft.findIndex((d) => d.id === currentDialogue.id);
    if (index !== -1) {
      if (factCheckResponse.status === 'fulfilled') {
        draft[index].fact = factCheckResponse.value.data?.response || null;
      }

      if (
        factCheckResponse.status === 'rejected' &&
        (factCheckResponse.reason as PaymentRequiredReasonType).response.data
          .code === 402
      ) {
        draft[index].content =
          draft[index].content +
          ' [Fact Check is unavailable due to credit limit]';
        draft[index].fact = false;
      }

      return draft;
    }
  });

  listenerApi.dispatch(
    dialogueUpdated({ reset: true, dialogue: updatedDialogue }),
  );
}
