import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

const StyledStack = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.card.light,
}));

export default StyledStack;
