import { ThemeType } from '@shared-types/theme';

export default function ListItemButton(theme: ThemeType) {
  return {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius * 2,
        },
      },
    },
  };
}
