import IndicatorLoading from '@components/indicator-loading';
import Modal from '@components/modal';
import useAuth from '@hooks/useAuth';
import useCreateRefreshToken from '@hooks/useGetRefreshToken';
import useQueryParams from '@hooks/useQueryParams';
import { setSession } from '@lib/jwt';
import { useEffect } from 'react';

type Props = React.PropsWithChildren<{
  width: number | string;
  height: number | string;
}>;

export default function ModalWrapper({ height, width, children }: Props) {
  const { getQueryParamByKey } = useQueryParams();

  const { handleUpdateMe } = useAuth();

  const actionQueryParam = getQueryParamByKey<'secure-account'>('action');

  const subscriptionParams = getQueryParamByKey('subscription');

  const { mutateCreateRefreshTokenAsync, refreshTokenStatus } =
    useCreateRefreshToken((variables) => {
      const token = variables.data.token;
      setSession(token);
      handleUpdateMe(token);
    });

  useEffect(() => {
    if (subscriptionParams === 'success') {
      (async function runOnSubscriptionSuccess() {
        await mutateCreateRefreshTokenAsync();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionParams]);

  return (
    <Modal
      keepMounted
      open={actionQueryParam === 'secure-account'}
      width={width}
      height={height}
    >
      {refreshTokenStatus === 'pending' ? (
        <IndicatorLoading sx={{ margin: 'auto' }} />
      ) : (
        children
      )}
    </Modal>
  );
}
