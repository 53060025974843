import { socketInstance } from '@state/middleware/websocket';

export default function subscribeToThread(
  threadId: string,
  subscribeThread: string | null,
) {
  if (subscribeThread !== threadId) {
    if (subscribeThread !== null) {
      socketInstance.socket.emit('unsubscribe', `thread_${subscribeThread}`);
    }

    socketInstance.socket.emit('subscribe', `thread_${threadId}`);
  }

  return threadId;
}
