import paths from '@router/paths';
import StyledLink from './styles';
import { SxProps } from '@mui/material';
import useWorkspace from '@hooks/useWorkspace';
import Box from '@mui/material/Box';

type Props = Partial<{
  isLink: boolean;
  variant: 'mini' | 'full';
  sx: SxProps;
}>;

const MINI_LOGO_SIZE = 45;

const FULL_LOGO_WIDTH_SIZE = 100;
const FULL_LOGO_HEIGHT_SIZE = 25;

export default function Logo({ variant, isLink = false, sx }: Props) {
  const isMini = variant === 'mini';

  const { setupMode } = useWorkspace();

  let logo;

  if (isMini) {
    logo = (
      <Box
        sx={sx}
        component="img"
        width={MINI_LOGO_SIZE}
        height={MINI_LOGO_SIZE}
        alt="knowz"
        src="https://assets.knowz.com/assets/svg/knowz-favicon---gradient.svg"
      />
    );
  }

  if (!isMini) {
    logo = (
      <Box
        component="img"
        sx={{
          filter: (theme) =>
            theme.palette.mode === 'light' ? 'invert(1)' : 'invert(0)',
          ...sx,
        }}
        width={FULL_LOGO_WIDTH_SIZE}
        height={FULL_LOGO_HEIGHT_SIZE}
        alt="knowz"
        src="https://assets.knowz.com/assets/svg/knowz---solid.svg"
      />
    );
  }

  return isLink ? (
    <StyledLink
      to={
        setupMode === 'on' ? paths.knowledgeBase.pathname : paths.home.pathname
      }
    >
      {logo}
    </StyledLink>
  ) : (
    logo
  );
}
