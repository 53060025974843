import { auth } from '@lib/agent';
import { queryClient } from '@providers/ReactQueryProvider';

async function getRefreshToken() {
  const token = window.localStorage.getItem('token');

  if (token == null) return;
  try {
    const response = await queryClient.fetchQuery({
      queryKey: ['refreshToken'],
      queryFn: async () => await auth.refreshToken(token),
      gcTime: 0,
    });
    return response;
  } catch (error) {
    console.error('Failed to refresh token:', error);
  }
}

export default getRefreshToken;
