import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

export const StyledBox = styled(Box)(({ theme }) => ({
  width: '80vw',
  maxWidth: '1200px',
  height: '80svh',
  borderRadius: theme.spacing(3.75),
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  backgroundColor: 'rgb(36, 35, 35)',
}));

export const StyledModal = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
}));
