import Logo from '@components/logo';
import HeaderWrapper from '@layouts/components/header-wrapper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import HistoryIcon from '@mui/icons-material/History';
import EditNoteIcon from '@mui/icons-material/EditNote';
import useAssistantsSession from '@layouts/assistants-session/hooks/useAssistantsSession';
import useGetOneAssistantByBotId from '@components/pages/hooks/useGetOneAssistantByBotId';
import Skeleton from '@mui/material/Skeleton';
import useCreateOneThread from '@hooks/useCreateOneThread';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from '@desygner/ui-common-translation';
import paths from '@router/paths';

export default function Header() {
  const { toggleHistoryDrawer } = useAssistantsSession();
  const navigateTo = useNavigate();

  function toggleHistoryPanel() {
    toggleHistoryDrawer();
  }
  const { t } = useTranslation();
  const {
    mutateAsync: mutateCreateOneThreadAsync,
    isPending: isCreateOneThreadPending,
  } = useCreateOneThread();

  const {
    oneAssistantData,
    isGetOneAssistantLoading,
    isGetOneAssistantFetching,
  } = useGetOneAssistantByBotId();

  async function handleNewAssistantSession() {
    const name = new Date().toISOString();
    const { data } = await mutateCreateOneThreadAsync({
      name,
      type: 'assistant',
      bot: Number(oneAssistantData?.id),
    });

    navigateTo(`${paths.assistantsSession.pathname}?t=${data.id}`, {
      state: {
        isUserRedirected: true,
      },
    });
  }

  return (
    <HeaderWrapper>
      <Logo isLink />
      {isGetOneAssistantLoading || isGetOneAssistantFetching ? (
        <Skeleton variant="text" width={100} />
      ) : (
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          {oneAssistantData?.name}
        </Typography>
      )}
      <Stack direction="row" gap={2}>
        <Tooltip
          arrow
          title={t('layout.assistantSession.header.tooltip.newThread', {
            defaultValue: 'New thread',
          })}
        >
          <IconButton
            disabled={isCreateOneThreadPending}
            onClick={handleNewAssistantSession}
          >
            <EditNoteIcon />
          </IconButton>
        </Tooltip>

        <Tooltip
          arrow
          title={t('layout.assistantSession.header.tooltip.history', {
            defaultValue: 'History',
          })}
        >
          <IconButton onClick={toggleHistoryPanel}>
            <HistoryIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </HeaderWrapper>
  );
}
