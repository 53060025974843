import { useTranslation } from '@desygner/ui-common-translation';
import useWorkspace from '@hooks/useWorkspace';
import { sources } from '@lib/agent';
import { queryClient } from '@providers/ReactQueryProvider';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

export default function useDeleteSourceById() {
  const { t } = useTranslation();
  const { getNamespacedQueryKey } = useWorkspace();

  const { mutate: mutateSource } = useMutation({
    mutationFn: async ({ id }: { id: number }) => {
      const { data } = await sources.deleteOne({
        id,
      });
      return data;
    },
    onError: (error) => {
      throw new Error(`An error occurred while deleting a folder: ${error}`);
    },
    onSuccess: (_data) => {
      toast.success(
        t('page.knowledgeBase.admin.actions.delete.source.toast.success', {
          defaultValue: 'Source has been deleted successfully.',
        }),
      );
      //TODO: we can remove if from cache instead of invalidating sources
      queryClient.invalidateQueries({
        queryKey: getNamespacedQueryKey('sources'),
      });
    },
  });

  return {
    mutateSource,
  };
}
