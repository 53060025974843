const SIZES = {
  TITLE_HEIGHT: 22,
  DESCRIPTION_HEIGHT: 44,
  IMAGE_STACK_HEIGHT: 32,
  DATE: 20,
};

const SEARCH = {
  defaultLabel: 'search',
  mappedLabel: 'page.history.tabs.search',
  isAlpha: false,
};

const TRANSCRIPT = {
  defaultLabel: 'transcript (coming soon)',
  mappedLabel: 'page.history.tabs.transcript',
  isAlpha: true,
};

const TAB_LIST_ITEM = [SEARCH, TRANSCRIPT];

const CONFIG = {
  TAB_LIST_ITEM,
  SIZES,
} as const;

export default CONFIG;
