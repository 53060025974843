import PhotosBlock from '@components/pages/search/components/shared-blocks/photos';

import { WSResponseType } from '@state/middleware/types';
import { RootType } from '@state/store';
import { useSelector } from 'react-redux';

export default function StreamingPhotos() {
  const searchResult = useSelector<RootType, WSResponseType>(
    (state) => state.websocket.WSResponse,
  );

  const photosData = searchResult.searchResult.layout.photo;
  const infoData = searchResult.searchResult.layout.infos;
  const answerData = searchResult.searchResult.layout.answer;

  if (photosData == null) return null;

  return (
    photosData.photos &&
    photosData.photos.length > 0 && (
      <PhotosBlock
        hasAnswer={Boolean(answerData != null && answerData.text.length > 0)}
        hasInfo={Boolean(infoData != null && infoData.infosData.length > 0)}
        photosData={photosData}
      />
    )
  );
}
