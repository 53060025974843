import StyledContainer from '@layouts/assistants-session/components/wrapper/styles';
import Stack from '@mui/material/Stack';

export default function Wrapper({ children }: React.PropsWithChildren) {
  return (
    <StyledContainer maxWidth="xl" disableGutters>
      <Stack direction="row">{children}</Stack>
    </StyledContainer>
  );
}
