import { TranslationProvider as DesygnerTranslationProvider } from '@desygner/ui-common-translation';
import en from '@translations/en';
import { PropsWithChildren } from 'react';

export default function TranslationProvider({ children }: PropsWithChildren) {
  return (
    <DesygnerTranslationProvider translationContent={en} language="en">
      {children}
    </DesygnerTranslationProvider>
  );
}
