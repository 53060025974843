import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';
import CreateWorkspace from '@components/workspace/setup/sections/steps/create-workspace';
import InviteMembers from '@components/workspace/setup/sections/steps/invite-members';
import UploadAvatarWorkspace from '@components/workspace/setup/sections/steps/upload-avatar-workspace';

const JOURNEY = [
  <CreateWorkspace />,
  <UploadAvatarWorkspace />,
  <InviteMembers />,
];

export default function ActiveStep() {
  const { activeStep } = useWorkspaceSetup();

  const validStepIndex = Math.min(activeStep, JOURNEY.length - 1);

  return JOURNEY[validStepIndex];
}
