class Timer {
  seconds: number;
  minutes: number;
  startTime: number;
  running: boolean;

  constructor() {
    this.seconds = 0;
    this.minutes = 0;
    this.startTime = 0;
    this.running = false;
  }

  startTimer() {
    this.startTime = Date.now();
    this.running = true;
    this.updateTimer();
  }

  updateTimer() {
    if (!this.running) return;

    const currentTime = Date.now();
    const elapsedTime = currentTime - this.startTime;

    this.seconds = Math.floor(elapsedTime / 1000) % 60;
    this.minutes = Math.floor(elapsedTime / 60000);

    requestAnimationFrame(() => this.updateTimer());
  }

  stopTimer() {
    this.running = false;
  }

  resetTimer() {
    this.seconds = 0;
    this.minutes = 0;
    this.startTime = 0;
    this.running = false;
  }

  getTime() {
    return {
      minutes: this.minutes.toString().padStart(2, '0'),
      seconds: this.seconds.toString().padStart(2, '0'),
    };
  }
}

export default Timer;
