import { styled } from '@mui/material';

export const StyledContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: auto;
  padding: 0 ${({ theme }) => theme.spacing(3)};
`;

export const StyledHeaderWrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const StyledItemWrapper = styled('div')<{
  hasHover: boolean;
}>`
  display: flex;
  border-radius: ${({ theme }) => theme.shape.borderRadius * 2};
  width: 100%;
  align-items: center;

  ${({ theme, hasHover }) =>
    hasHover &&
    `
    &:hover {
      background: ${theme.palette.neutral.lightGrey};
      transition: all 500ms;
      cursor: pointer;
    }
    `}
`;

export const StyledItemContainer = styled('div')<{
  hasBorder: boolean;
}>`
  display: flex;
  align-items: center;
  border-bottom: ${({ theme, hasBorder }) =>
    hasBorder && '1px solid' + theme.palette.neutral.border};
`;
