import CustomFileRender from '@components/pages/knowledge-base/components/table/cells/action/source-viewer/custom-file-render';
import useGetSourceDetailsByIdLazily from '@components/pages/knowledge-base/hooks/useGetSourceDetailsByIdLazily';
import useGetCredentialByKey from '@hooks/useGetCredentialByKey';
import { useDeferredValue, useState, useEffect, useRef } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Markdown from '@components/markdown';
import {
  StyledBox,
  StyledLoadingBox,
} from '@components/pages/knowledge-base/components/table/cells/action/source-viewer/doc-viewer/style';
import DocViewer, {
  PDFRenderer,
  DocViewerRenderers,
} from '@cyntler/react-doc-viewer';
import { useTranslation } from '@desygner/ui-common-translation';

type DocProps = {
  id: number;
};

export default function Doc({ id }: DocProps) {
  const { sourceDetailsData } = useGetSourceDetailsByIdLazily({ id });
  const { credentialData, isCredentialDataLoading } = useGetCredentialByKey({
    key: sourceDetailsData?.path,
  });
  const deferredQuery = useDeferredValue(credentialData?.data.url);

  const [content, setContent] = useState('');
  const [fileType, setFileType] = useState<string | undefined>('');
  const cacheRef = useRef<{ [key: string]: string }>({});

  const { t } = useTranslation();

  useEffect(() => {
    if (deferredQuery) {
      if (cacheRef.current[deferredQuery]) {
        setContent(cacheRef.current[deferredQuery]);
        setFileType(sourceDetailsData?.fileType);
      } else {
        fetch(deferredQuery)
          .then((response) => response.text())
          .then((data) => {
            cacheRef.current[deferredQuery] = data;
            setContent(data);
            setFileType(sourceDetailsData?.fileType);
          })
          .catch((err) => console.error(err));
      }
    }
  }, [deferredQuery, sourceDetailsData?.fileType]);

  if (!deferredQuery || isCredentialDataLoading) {
    return (
      <StyledLoadingBox>
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </StyledLoadingBox>
    );
  }

  const renderContent = () => {
    const isJsonType = fileType === 'application/json' || fileType === 'json';
    const isTextType = fileType === 'txt' || fileType === 'md';

    if (isJsonType) {
      try {
        const formattedJson = JSON.stringify(JSON.parse(content), null, 2);
        return <StyledBox>{formattedJson}</StyledBox>;
      } catch (error) {
        console.error('JSON parsing error:', error);
        return (
          <StyledBox>
            {t('pages.knowledge-base.source-viewer.error', {
              defaultValue: 'Invalid JSON format',
            })}
          </StyledBox>
        );
      }
    }

    if (isTextType) {
      return (
        <StyledBox>
          <Markdown>{content}</Markdown>
        </StyledBox>
      );
    }

    return (
      <DocViewer
        documents={[
          {
            uri: deferredQuery,
            fileType: fileType,
          },
        ]}
        pluginRenderers={[PDFRenderer, CustomFileRender, ...DocViewerRenderers]}
        style={{ overflow: 'auto' }}
      />
    );
  };

  return renderContent();
}
