import useSideKick from '@components/pages/side-kick-session/hooks/useSideKick';
import StyledAppBar from '@components/pages/side-kick-session/sections/app-bar/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { useScrollTrigger } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';

export default function AppBar() {
  const { t } = useTranslation();
  const {
    handleSetSmartReviewDrawer,
    handleSetFeedDrawer,
    feedIsOpen,
    smartReviewIsOpen,
  } = useSideKick();

  function handleSmartReviewOpen() {
    handleSetSmartReviewDrawer(true);
  }

  function handleFeedOpen() {
    handleSetFeedDrawer(true);
  }

  return (
    <HideOnScroll>
      <StyledAppBar position="sticky">
        <Toolbar
          sx={{ gap: 2, mb: 5, width: '100%', alignItems: 'stretch' }}
          disableGutters
        >
          <Autocomplete
            options={[]}
            clearIcon={null}
            popupIcon={null}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search dialogue"
                InputProps={{
                  startAdornment: <SearchIcon />,
                }}
              />
            )}
          />
          <Tooltip
            title={t('page.sideKickSession.drawer.smartReview.heading', {
              defaultValue: 'Smart Review',
            })}
            arrow
          >
            <Button
              onClick={handleSmartReviewOpen}
              variant="outlined"
              size="small"
              color={smartReviewIsOpen ? 'primary' : 'inherit'}
            >
              <FormatListBulletedIcon />
            </Button>
          </Tooltip>
          <Tooltip
            title={t('page.sideKickSession.drawer.feed.heading', {
              defaultValue: 'Feed',
            })}
            arrow
          >
            <Button
              onClick={handleFeedOpen}
              variant="outlined"
              size="small"
              color={feedIsOpen ? 'primary' : 'inherit'}
            >
              <NotificationsOutlinedIcon />
            </Button>
          </Tooltip>
        </Toolbar>
      </StyledAppBar>
    </HideOnScroll>
  );
}

//TODO: we might need to have the same solution for search bar in search result page.
type Props = {
  window?: () => Window;
  children: React.ReactElement;
};

function HideOnScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
