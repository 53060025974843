//TODO: we need to hooke up useTranscription later on
import EmptyState from '@components/empty-state';
import useGetSentimentLabelsFromCDN from '@components/pages/side-kick-session/hooks/useGetSentimentLabelsFromCDN';
import useSpeechRecognitionWithHistory from '@components/pages/side-kick-session/hooks/useSpeechRecognitionWithHistory';
import ListItem from '@components/pages/side-kick-session/sections/content/listitem';
import { useTranslation } from '@desygner/ui-common-translation';
import useScrollIntoView from '@hooks/useScrollIntoView';
import useTimer from '@hooks/useTimer';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import paths from '@router/paths';
import { isRecordingUpdated } from '@state/slices/transcription';
import { RootType } from '@state/store';
import { useConfirm } from 'material-ui-confirm';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBlocker, useNavigate } from 'react-router-dom';

export default function Content() {
  const { t } = useTranslation();
  const {
    dialogues,
    interimResult,
    startSpeechToText,
    stopSpeechToText,
    isSpeechRecognitionRecordingEnable,
  } = useSpeechRecognitionWithHistory();
  const { minutes, seconds } = useTimer().getTime();
  const dispatch = useDispatch();
  const timer = useTimer();
  const confirm = useConfirm();
  const navigateTo = useNavigate();
  const isRecording = useSelector(
    (state: RootType) => state.transcription.isRecording,
  );
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isRecording && currentLocation.pathname !== nextLocation.pathname,
  );
  const { data: sentimentLabelsFromCDN } = useGetSentimentLabelsFromCDN();

  const chatRef = useRef(null);
  useScrollIntoView(chatRef, [dialogues.length]);

  useEffect(() => {
    if (!isSpeechRecognitionRecordingEnable) {
      startSpeechToText().catch((error) => console.error(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSpeechRecognitionRecordingEnable]);

  useEffect(() => {
    if (blocker.state === 'blocked' && isRecording) {
      (function () {
        confirm({
          title: t('page.sideKickSession.confirmLeave.title', {
            defaultValue: 'Leave Recording Session',
          }),
          description: t('page.sideKickSession.confirmLeave.content', {
            defaultValue: 'Are you sure you want to leave recording?',
          }),
          confirmationText: t('page.sideKickSession.confirmLeave.yes', {
            defaultValue: 'Yes, I want to leave',
          }),
          cancellationText: t('page.sideKickSession.confirmLeave.no', {
            defaultValue: 'No, I want to continue',
          }),
        })
          .then(() => {
            stopSpeechToText();
            dispatch(isRecordingUpdated(false));
            navigateTo(paths.sideKick.pathname);
            timer.stopTimer();
            blocker.proceed();
          })
          .catch(() => {
            blocker.reset();
          });
      })();
    }

    if (blocker.state === 'blocked' && !isRecording) {
      blocker.proceed();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker, blocker.state, isRecording]);

  if (dialogues.length === 0 && typeof interimResult === 'undefined') {
    return (
      <EmptyState
        title={t('emptyState.transcript', {
          defaultValue: 'Transcript will start generating as you are speaking',
        })}
        img={{
          src: 'https://assets.knowz.com/assets/svg/dialogue--empty-state.svg',
          width: 185,
          height: 155,
        }}
      />
    );
  }

  return (
    <List component={Stack} gap={6}>
      {dialogues.map((dialogue) => {
        const coloredSentiment =
          dialogue.tags?.map((tag) => ({
            color: sentimentLabelsFromCDN[tag.classification][tag.sentiment],
            label: tag.sentiment,
          })) || null;
        return (
          <ListItem
            key={dialogue.id}
            content={dialogue.content}
            time={dialogue.time}
            coloredSentiment={coloredSentiment}
            fact={dialogue.fact}
          />
        );
      })}
      <div ref={chatRef}>
        {interimResult && (
          <ListItem
            key="realtime result"
            content={interimResult}
            time={{ minutes, seconds }}
            coloredSentiment={null}
            fact={null}
          />
        )}
      </div>
    </List>
  );
}
