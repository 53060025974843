import { alpha } from '@mui/material';

export function createGradient(color1: string, color2: string, deg: string) {
  return `linear-gradient(${deg}, ${color1}, ${color2})`;
}

type GradientDeg = 'to left' | 'to bottom' | 'to top' | 'to right';

interface GradientsPaletteOptions {
  primary: string;
  neutral: string;
  info: string;
  success: string;
  warning: string;
  error: string;
  black: string;
}
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    neutral: typeof NEUTRAL;
    gradients: (deg: GradientDeg) => GradientsPaletteOptions;
    brand: typeof BRAND;
  }

  interface PaletteOptions {
    neutral?: typeof NEUTRAL;
    gradients?: (deg: GradientDeg) => GradientsPaletteOptions;
    brand?: typeof BRAND;
  }

  interface PaletteColor {
    lighter?: string;
    darker?: string;
  }

  interface TypeBackground {
    secondary: string;
    card: {
      main: string;
      light: string;
    };
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    secondary: false;
  }
}

const NEUTRAL = {
  paper: '#FFFFFF',
  lightGrey: '#F3F5F7',
  border: '#e8e8e8',
  grey: '#D1D3D6',
  darkGrey: '#6C757D',
  black: '#212529',
};

const PRIMARY = {
  lighter: '#E8F2FC',
  light: '#5BA1ED',
  main: '#3249FF',
  dark: '#2C3E65',
  darker: '#232E4C',
  contrastText: NEUTRAL.paper,
};

const INFO = {
  light: '#EBF3FF',
  main: '#0068FF',
  dark: '#2341e0',
  contrastText: NEUTRAL.paper,
};

const WARNING = {
  light: '#FFF1D6',
  main: '#FFC738',
  dark: '#f98621',
  contrastText: NEUTRAL.black,
};

const SUCCESS = {
  light: '#C6ECE2',
  main: '#00AB7D',
  dark: '#005228',
  contrastText: NEUTRAL.paper,
};

const ERROR = {
  lighter: '#FFD6D6',
  light: '#FFD6D6',
  main: '#fe3217',
  dark: '#F02911',
  darker: '#CA0000',
  contrastText: NEUTRAL.paper,
};

const BRAND = {
  darkBlue: {
    main: '#2C3E65',
    light: '#F2F4F8',
  },
  blue: {
    main: '#3C1AF9',
    light: '#E8F2FC',
  },
  purple: {
    main: '#773DFF',
    light: '#EDE5FF',
  },
  green: {
    main: '#00C394',
    light: '#EBFFFA',
  },
  lightBlue: {
    main: '#5BA1ED',
    light: '#E8F2FC',
  },
};

const GREY = {
  0: '#ededed',
  100: '#D1D3D6',
  200: '#b2b6bd',
  300: '#9399a5',
  400: '#7c8492',
  500: '#656f81',
  600: '#586170',
  700: '#474e5a',
  800: '#373c45',
  900: '#25282e',
  500_8: alpha('#656f81', 0.08),
  500_12: alpha('#656f81', 0.12),
  500_16: alpha('#656f81', 0.16),
  500_24: alpha('#656f81', 0.24),
  500_32: alpha('#656f81', 0.32),
  500_48: alpha('#656f81', 0.48),
  500_56: alpha('#656f81', 0.56),
  500_80: alpha('#656f81', 0.8),
};

const GRADIENTS = (deg: 'to left' | 'to bottom' | 'to top' | 'to right') => ({
  primary: createGradient(PRIMARY.light, PRIMARY.main, deg),
  neutral: createGradient(NEUTRAL.darkGrey, NEUTRAL.lightGrey, deg),
  info: createGradient(INFO.light, INFO.main, deg),
  success: createGradient(SUCCESS.light, SUCCESS.main, deg),
  warning: createGradient(WARNING.light, WARNING.main, deg),
  error: createGradient(ERROR.light, ERROR.main, deg),
  black: createGradient('#000', GREY[600], deg),
});

const COMMON = {
  common: { black: '#000', white: NEUTRAL.paper },
  primary: PRIMARY,
  info: INFO,
  neutral: NEUTRAL,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  gradients: GRADIENTS,
  grey: GREY,
  brand: BRAND,
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: {
      primary: '#000',
      secondary: NEUTRAL.black,
      disabled: NEUTRAL.darkGrey,
    },
    background: {
      paper: NEUTRAL.paper,
      secondary: alpha('#000', 0.05),
      default: NEUTRAL.paper,
      card: {
        main: '#e6e6e6',
        light: '#f2f2f2',
      },
    },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#fff',
      secondary: NEUTRAL.grey,
      disabled: NEUTRAL.darkGrey,
    },
    background: {
      paper: NEUTRAL.black,
      secondary: alpha('#fff', 0.05),
      default: '#141414',
      card: {
        light: '#1F1F1F',
        main: '#323232',
      },
    },
  },
} as const;

export default palette;
