type SyncStatusType = {
  isDigested: boolean;
  isDigestedFail: boolean;
};

function calculateSyncStatusLabel({
  isDigested,
  isDigestedFail,
}: SyncStatusType) {
  //? in progress
  if (!isDigested && !isDigestedFail) {
    return 'in_progress';
  }

  //? sync
  if (isDigested && !isDigestedFail) {
    return 'sync';
  }

  //? error ((!isDigested && isDigestedFail)
  return 'error';
}

export default calculateSyncStatusLabel;
