import { styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { HTMLMotionProps } from 'framer-motion';

export const StyledRoot = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.card.light,
  borderRadius: theme.shape.borderRadius * 5,
}));

export const StyledAnimatedBorder1 = styled(Box)<
  HTMLMotionProps<'div'> & BoxProps
>(({ theme }) => {
  const SIZE = 80;
  return {
    width: SIZE,
    height: SIZE,
    borderRadius: '25%',
    position: 'absolute',
    border: `solid 3px ${theme.palette.primary.main}`,
  };
});

export const StyledAnimatedBorder2 = styled(Box)<
  HTMLMotionProps<'div'> & BoxProps
>(({ theme }) => {
  const SIZE = 100;
  return {
    width: SIZE,
    height: SIZE,
    borderRadius: '25%',
    position: 'absolute',
    border: `solid 8px ${theme.palette.primary.light}`,
  };
});
