import Tooltip from '@mui/material/Tooltip';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StyledToolbarExtensionToggleButton from '@layouts/main/sections/toolbar/components/expand-button/styles';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import { useTranslation } from '@desygner/ui-common-translation';
import { forwardRef } from 'react';

const ToolbarExpansionButton = forwardRef((_, ref) => {
  const { handleToggleToolbar, toolbarExpansionStatus } = useToolbar();
  const { t } = useTranslation();
  const isToolbarExpanded = toolbarExpansionStatus === 'on';

  return (
    <Tooltip
      ref={ref}
      arrow
      placement="right"
      title={
        isToolbarExpanded
          ? t('layout.toolbar.buttons.collapse')
          : t('layout.toolbar.buttons.expand')
      }
    >
      <StyledToolbarExtensionToggleButton onClick={handleToggleToolbar}>
        {isToolbarExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </StyledToolbarExtensionToggleButton>
    </Tooltip>
  );
});

ToolbarExpansionButton.displayName = 'ExpandButton';

export default ToolbarExpansionButton;
