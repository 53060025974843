import StyledMarkdown from '@components/markdown/styles';
import { SxProps } from '@mui/material';
import { Options as MarkdownOptions } from 'react-markdown';
import { useState } from 'react';
import ActionDrawer from '@components/pages/knowledge-base/components/table/action-drawer';
import ButtonBase from '@mui/material/ButtonBase';
import { alpha } from '@mui/material';

export default function Markdown({
  children,
  sx,
  ...rest
}: React.PropsWithChildren<MarkdownOptions & { sx?: SxProps }>) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDrawerById, setOpenDrawerById] = useState<number | null>(null);

  function handleCloseModal() {
    setIsModalOpen(!isModalOpen);
  }

  function handleToggleActionDrawer(id: number | null) {
    setOpenDrawerById(id);
  }

  function handleOpenDrawer(href: string) {
    const url = new URLSearchParams(href);
    const sourceId = url.get('source');

    if (sourceId) {
      handleCloseModal();
      setOpenDrawerById(parseInt(sourceId));
    }
  }

  return (
    <>
      <ActionDrawer
        anchor="right"
        isDrawerOpenById={openDrawerById}
        onClose={() => handleToggleActionDrawer(null)}
        shouldShowInFolderButton={!!openDrawerById}
      />
      <StyledMarkdown
        sx={sx}
        components={{
          a(props) {
            const { children, node, ...rest } = props;
            const href = props.href;
            if (href?.includes('source=')) {
              return (
                <ButtonBase
                  sx={{
                    textDecoration: 'underline',
                    pb: 1,
                    color: (theme) =>
                      alpha(theme.palette.brand.lightBlue.main, 0.75),
                    '&:hover': {
                      color: (theme) =>
                        alpha(theme.palette.brand.lightBlue.main, 1),
                    },
                  }}
                  onClick={() => handleOpenDrawer(href)}
                >
                  knowledge source
                </ButtonBase>
              );
            }
            return (
              <a
                href={href}
                {...rest}
                target="_blank"
                rel="noopener noreferrer"
              >
                {children}
              </a>
            );
          },
        }}
        {...rest}
      >
        {children?.toString()}
      </StyledMarkdown>
    </>
  );
}
