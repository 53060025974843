const ADMIN_TABLE_HEADER = [
  'select',
  'name',
  'format',
  'location',
  'sync status',
  'permission',
  'action',
] as const;

const CONFIG = {
  ADMIN_TABLE_HEADER,
};

export default CONFIG;
