import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBox = styled(Box)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  overflow: 'auto',
  maxHeight: '100%',
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.card.light,
  borderRadius: theme.shape.borderRadius * 2,
  color: theme.palette.common.white,
}));

export const StyledLoadingBox = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
