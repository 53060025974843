import Content from '@layouts/main/sections/toolbar/mini/desktop/sections/credit-usage/tooltip/content';
import StyledMuiTooltip from '@layouts/main/sections/toolbar/mini/desktop/sections/credit-usage/tooltip/styles';

type Props = {
  children: JSX.Element;
};

export default function Tooltip({ children }: Props) {
  return (
    <StyledMuiTooltip title={<Content />} placement="right" arrow>
      {children}
    </StyledMuiTooltip>
  );
}
