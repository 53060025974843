import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';
import { assistants } from '@lib/agent';

export default function useGetAllAssistants() {
  return useQueryAfterWorkspaceLoaded({
    queryKey: ['assistants'],
    queryFn: async () => {
      try {
        const { data: assistantsData } = await assistants.getAll();

        return assistantsData || [];
      } catch (error) {
        console.error(error);
      }
    },
  });
}
