import { useTranslation } from '@desygner/ui-common-translation';
import { memberships } from '@lib/agent';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

type Props = {
  onSuccess:
    | ((
        data: unknown,
        variables: { membershipId: string },
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined;
};

export default function useAcceptInvitation({ onSuccess }: Props) {
  const { t } = useTranslation();

  const { mutateAsync: mutateAcceptInvitationAsync } = useMutation({
    mutationKey: ['acceptInvitation'],
    mutationFn: async ({ membershipId }) => {
      return await memberships.acceptInvitation({
        membershipId,
      });
    },
    onSuccess,
    onError: (error) => {
      if (isAxiosError(error) && error.response?.data.code === 403) {
        return toast.error(
          t('page.knowledgeBase.member.membership.invalid', {
            defaultValue:
              'You do not have permission to accept this invitation.',
          }),
        );
      }
      if (isAxiosError(error) && error.response?.data.code === 412) {
        return toast.error(
          t('page.knowledgeBase.member.membership.alreadyAccepted', {
            defaultValue: 'You have already accepted this invitation.',
          }),
        );
      }
      console.error(error);
    },
  });

  return { mutateAcceptInvitationAsync };
}
