import StyledContainer from '@components/pages/side-kick-session/styles';
import Stack from '@mui/material/Stack';

export default function Wrapper({ children }: React.PropsWithChildren) {
  return (
    <StyledContainer maxWidth="xl" disableGutters>
      <Stack direction="row">{children}</Stack>
    </StyledContainer>
  );
}
