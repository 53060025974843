import useTimer from '@hooks/useTimer';
import { DialogueType, MonologueType } from '@shared-types/sidekick';
import { dialogueUpdated } from '@state/slices/transcription';
import { RootType } from '@state/store';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSpeechToText from 'react-hook-speech-to-text';
import { v4 as uuidv4 } from 'uuid';

export default function useSpeechRecognitionWithHistory() {
  const {
    interimResult,
    results,
    startSpeechToText,
    stopSpeechToText,
    error,
    isRecording: isSpeechRecognitionRecordingEnable,
  } = useSpeechToText({
    continuous: true,
    useLegacyResults: false,
  });

  const dispatch = useDispatch();

  const isRecording = useSelector(
    (state: RootType) => state.transcription.isRecording,
  );

  const dialogues = useSelector(
    (state: RootType) => state.transcription.dialogue,
  ) as DialogueType;

  const lastDialogue = [] as DialogueType;

  const dialogueUUID = uuidv4();

  const { minutes, seconds } = useTimer().getTime();

  useEffect(() => {
    const lastResult = results[results.length - 1];
    if (!isRecording) return;
    if (typeof lastResult === 'object' && lastResult.transcript.length > 0) {
      const monologue: MonologueType = {
        id: dialogueUUID,
        content: lastResult.transcript,
        time: {
          minutes,
          seconds,
        },
        tags: null,
        fact: {},
      };

      lastDialogue.push(monologue);

      dispatch(dialogueUpdated({ reset: false, dialogue: lastDialogue }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results.length]);

  return {
    startSpeechToText,
    stopSpeechToText,
    interimResult,
    dialogues,
    results,
    isSpeechRecognitionRecordingEnable,
    error,
  };
}
