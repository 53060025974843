import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

export const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(6),
  backgroundColor: theme.palette.background.card.light,
  borderRadius: theme.spacing(4),
}));

export const StyledCheckboxIcon = styled('span')(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  width: 16,
  height: 16,
  backgroundColor: theme.palette.background.default,
  outline: `1px solid ${theme.palette.divider}`,
}));

export const StyledCheckbox = styled(StyledCheckboxIcon)(({ theme }) => ({
  width: 16,
  height: 16,
  '&::before': {
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    display: 'block',
    width: 16,
    height: 16,
    color: theme.palette.primary.contrastText,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
}));

export const StyledIntermittentCheckbox = styled(StyledCheckboxIcon)(
  ({ theme }) => ({
    width: 16,
    height: 16,
    '&::before': {
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.main,
      display: 'block',
      width: 16,
      height: 16,
      color: theme.palette.primary.contrastText,
      content: '""',
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M19 13H5v-2h14z'" +
        " fill='%23fff'/%3E%3C/svg%3E\")",
    },
  }),
);
