import CONFIG from '@components/pages/history/config';
import { StyledCard } from '@components/pages/history/sections/thread-listitem/styles';
import MuiSkeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const LINES_OF_DESCRIPTION = 2;
const DATE_WIDTH = 50;
const IMAGE_WIDTH = 32;
const NUMBER_OF_IMAGES = 5;

export default function Skeleton() {
  return (
    <StyledCard>
      <Stack gap={2}>
        <MuiSkeleton variant="rectangular" height={CONFIG.SIZES.TITLE_HEIGHT} />
        <MuiSkeleton
          variant="text"
          height={CONFIG.SIZES.DESCRIPTION_HEIGHT * LINES_OF_DESCRIPTION}
        />
        <Stack direction="row" gap={2}>
          {Array.from({ length: NUMBER_OF_IMAGES }, (_, i) => (
            <MuiSkeleton
              key={i}
              variant="rectangular"
              height={CONFIG.SIZES.IMAGE_STACK_HEIGHT}
              width={IMAGE_WIDTH}
            />
          ))}
        </Stack>
        <MuiSkeleton
          variant="rectangular"
          height={CONFIG.SIZES.DATE}
          width={DATE_WIDTH}
        />
      </Stack>
    </StyledCard>
  );
}
