import useSideKick from '@components/pages/side-kick-session/hooks/useSideKick';
import Feed from '@components/pages/side-kick-session/sections/drawer/feed';
import SmartReview from '@components/pages/side-kick-session/sections/drawer/smart-review';
import Wrapper from '@components/pages/side-kick-session/sections/drawer/wrapper';

export default function Drawer() {
  const { feedIsOpen, smartReviewIsOpen } = useSideKick();
  let DrawerToOpen;

  if (feedIsOpen) {
    DrawerToOpen = <Feed />;
  }

  if (smartReviewIsOpen) DrawerToOpen = <SmartReview />;

  return <Wrapper>{DrawerToOpen}</Wrapper>;
}
