import { KnowzStepDefinition } from '../../types';

const AutomateDesignStepDefinition: KnowzStepDefinition = {
  order: 404,
  group: 'Desygner Cloud',
  step: {
    componentType: 'task',
    type: 'design-automation',
    name: 'Automate Design'
  },
  propertyTypes: [
    {
      id: 'inputs',
      name: 'Payload context',
      description: 'Define the different inputs for the automation payload',
      usage: 'input',
      wrapper: 'list',
      types: ['ref-string', 'ref-source'],
    },
    {
      id: 'placeholders',
      name: 'Smart payload',
      description:
        'Produce a placeholders payload, inlining the inputs you defined',
      usage: 'definition',
      wrapper: 'item',
      types: ['string'],
    },
    {
      id: 'template',
      name: 'Template',
      description: 'Select the template you want to use for the design',
      usage: 'definition',
      wrapper: 'item',
      types: ['id-template'],
    },
    {
      id: 'output',
      name: 'New design reference',
      description: 'Assign a name to the new design for later reference it in your command',
      usage: 'output',
      wrapper: 'item',
      types: ['ref-design'],
    },
  ],
};

export default AutomateDesignStepDefinition;
