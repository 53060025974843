import { useSearchParams } from 'react-router-dom';

export default function useGetThreadIdFromQueryParam() {
  const [searchParams, setSearchParams] = useSearchParams();
  const threadId = searchParams.get('t');

  function setThreadId(threadId: string) {
    setSearchParams({ t: threadId });
  }

  return {
    threadId,
    setThreadId,
  };
}
