import Slot from '@components/pages/knowledge-base/components/table/action-drawer/panels/details/Slot';
import { format as formatDate } from 'date-fns';

type Props = {
  createdAt: string;
  updatedAt: string;
  name: string;
  format: string;
  location: string;
  size: number;
  createdBy: string;
};

export default function Details({
  createdAt,
  createdBy,
  name,
  format,
  location,
  size,
  updatedAt,
}: Props) {
  return (
    <Slot
      name={name}
      format={format}
      location={location}
      createdBy={createdBy}
      size={`${size.toFixed(2)} KB`}
      updatedAt={formatDate(new Date(updatedAt), 'MMM dd, yyyy')}
      createdAt={formatDate(new Date(createdAt), 'MMM dd, yyyy')}
    />
  );
}
