import { styled } from '@mui/material';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { HTMLMotionProps } from 'framer-motion';

export const StyledCharsTypography = styled(Typography)<
  HTMLMotionProps<'div'> & TypographyProps
>(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.text.primary,
  marginBlockStart: theme.spacing(5),
}));

export const StyledWrapper = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  borderRadius: theme.shape.borderRadius * 5,
  display: 'flex',
  flexDirection: 'column',
}));
