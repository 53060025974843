import { memberships } from '@lib/agent';
import { useMutation } from '@tanstack/react-query';

export default function useCreateInvitation(
  onSuccess?:
    | ((data: unknown, context: unknown) => Promise<unknown> | unknown)
    | undefined,
) {
  const { mutateAsync: createInvitationAsync } = useMutation({
    mutationKey: ['createInvitation'],
    mutationFn: async (data: { email: string; isAdmin: boolean }) => {
      return await memberships.createInvitation({
        email: data.email,
        isAdmin: data.isAdmin,
      });
    },
    onError: (error) => {
      console.error('useCreateInvitation', error);
    },
    onSuccess,
  });

  return {
    createInvitationAsync,
  };
}
