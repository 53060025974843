import { styled } from '@mui/material';
import { Dashboard, DragDrop } from '@uppy/react';

export const StyledDragDrop = styled(DragDrop)(({ theme }) => ({
  '.uppy-DragDrop-container': {
    backgroundColor: theme.palette.background.default,
  },
  '.uppy-DragDrop-label': {
    color: theme.palette.text.primary,
  },
  '.uppy-DragDrop-arrow': {
    display: 'none',
  },
}));

export const StyledDashboard = styled(Dashboard)(({ theme }) => ({
  '.uppy-DashboardTab-btn:active, .uppy-DashboardTab-btn:focus': {
    backgroundColor: theme.palette.background.secondary,
  },
  '.uppy-Dashboard-inner': {
    backgroundColor: 'transparent',
    border: 0,
  },
  '.uppy-u-reset uppy-c-btn uppy-Dashboard-browse': {
    color: theme.palette.info.dark,
  },
  '.uppy-DashboardTab-name': {
    color: theme.palette.text.primary,
  },
  '.uppy-DashboardTab-btn:hover': {
    backgroundColor: theme.palette.background.secondary,
  },
  '.uppy-Dashboard-AddFiles-title': {
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize,
  },
  '.uppy-Dashboard-AddFiles': {
    margin: 0,
    height: '100%',
    borderColor: theme.palette.background.secondary,
  },
  '.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload, .uppy-c-btn-primary':
    {
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius * 2,
    },
}));
