import { useCallback, useRef } from 'react';
import { alpha, useTheme } from '@mui/material';
import StyledCanvas from '@components/canvas-stars/styles';

const STARS_COUNT = 100;
const STAR_SPEED = 0.3;
const STAR_SIZE = 3;
const RADIUS = STAR_SIZE;

export default function CanvasStars() {
  const theme = useTheme();
  const stars = useRef<{ x: number; y: number; fill: string; speed: number }[]>(
    [],
  );

  const handleCanvas = useCallback(
    (canvas: HTMLCanvasElement) => {
      const context = canvas?.getContext('2d');
      if (context == null) return;
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      stars.current = Array.from({ length: STARS_COUNT }, () => ({
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        fill: alpha(theme.palette.neutral.paper, Math.random()),
        speed: STAR_SPEED,
      }));

      (function animate() {
        context.clearRect(0, 0, canvas.width, canvas.height);

        stars.current.forEach((star) => {
          context.fillStyle = star.fill;
          star.y += star.speed;
          context.beginPath();
          context.roundRect(star.x, star.y, STAR_SIZE, STAR_SIZE, RADIUS);
          if (star.y > canvas.height) {
            star.y = 0;
            star.x = Math.random() * window.innerWidth;
          }
          context.fill();
        });
        requestAnimationFrame(animate);
      })();
    },
    //? later we can add the theme to the dependencies for showing different colors of dots on different theme modes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return <StyledCanvas aria-hidden ref={handleCanvas} />;
}
