import { useTranslation } from '@desygner/ui-common-translation';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const STAR_SIZE = 20;

export default function Content() {
  const { t } = useTranslation();

  return (
    <Stack gap={1}>
      <Stack direction="row" gap={2}>
        <img
          src="https://assets.knowz.com/assets/images/star.png"
          alt="buy more credits"
          width={STAR_SIZE}
          height={STAR_SIZE}
          loading="lazy"
        />
        <Typography variant="body1">
          {t('layout.toolbar.tooltipBuyCredit.title', {
            defaultValue: 'Credits',
          })}
        </Typography>
      </Stack>
      <Typography variant="caption">
        {t('layout.toolbar.tooltipBuyCredit.subtitle', {
          defaultValue:
            'Use credits for tools like Smart Search—credits reset every month. Run out? Just grab a payment plan to keep going strong!',
        })}
      </Typography>
    </Stack>
  );
}
