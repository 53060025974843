import { BASE_FONT_SIZE } from '@lib/fonts';
import { alpha } from '@mui/material';
import { ThemeType } from '@shared-types/theme';

export default function CssBaseline(theme: ThemeType) {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
          fontFamily: theme.typography.fontFamily,
          '&::-webkit-scrollbar': {
            width: theme.spacing(2),
            height: theme.spacing(2),
          },

          '&::-webkit-scrollbar-thumb': {
            backgroundColor: alpha(theme.palette.text.primary, 0.5),
            borderRadius: theme.spacing(1),
          },
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitTapHighlightColor: 'transparent',
          fontSize: `${BASE_FONT_SIZE}px`,
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        li: {
          listStyleType: 'none',
        },
        a: {
          textDecoration: 'none',
        },
        '.grecaptcha-badge': { visibility: 'hidden' },

        // React Toastify
        '.Toastify': {
          fontSize: `${BASE_FONT_SIZE}px`,
        },
        ':root': {
          ' --toastify-color-light': theme.palette.common.white,
          ' --toastify-color-dark': theme.palette.common.black,
          ' --toastify-color-info': theme.palette.info.main,
          ' --toastify-color-success': theme.palette.success.main,
          ' --toastify-color-warning': theme.palette.warning.main,
          ' --toastify-color-error': theme.palette.error.main,

          '--toastify-icon-color-info': theme.palette.info.main,
          '--toastify-icon-color-success': theme.palette.success.main,
          '--toastify-icon-color-warning': theme.palette.warning.main,
          '--toastify-icon-color-error': theme.palette.error.main,
          '--toastify-font-family': theme.typography.fontFamily,
        },
      },
    },
  };
}
