import en from '@translations/en';
import * as Yup from 'yup';

const emailSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email(en.page.workspace.createWorkspaceModal.step3.form.email.invalid),
});

export default emailSchema;
