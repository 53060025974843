import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

const StyledStack = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  marginTop: theme.spacing(12),
  marginBottom: theme.spacing(8),
}));

export default StyledStack;
