import CONFIG from '@layouts/main/config';
import { Card } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

type Props = Partial<{
  prompt: React.ReactElement;
  links: React.ReactElement;
  answer: React.ReactElement;
  photos: React.ReactElement;
  info: React.ReactElement;
  followups: React.ReactElement;
  singlePhotosCol: React.ReactElement;
  searchForm: React.ReactElement;
}>;

export default function Layout({
  answer,
  info,
  links,
  photos,
  prompt,
  singlePhotosCol,
  followups,
  searchForm,
}: Props) {
  return (
    <Grid
      container
      spacing={5}
      sx={{
        scrollMarginTop: CONFIG.HEADER.HEIGHT,
        marginBottom: 5,
      }}
    >
      <Grid item xs={12} md={8}>
        {searchForm}
        <Stack gap={5}>
          <Card>
            <Stack gap={5}>
              {prompt}
              {links}
              {answer}
            </Stack>
          </Card>
          {singlePhotosCol}
          {info}
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack
          gap={5}
          sx={{
            position: {
              xs: 'static',
              md: 'sticky',
            },
            top: {
              xs: 'unset',
              md: CONFIG.HEADER.HEIGHT + CONFIG.HEADER.PADDING_Y,
            },
          }}
        >
          {photos}
          {followups}
        </Stack>
      </Grid>
    </Grid>
  );
}
