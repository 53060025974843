//TODO: we can later add UI to it
import useAuth from '@hooks/useAuth';
import { queryClient } from '@providers/ReactQueryProvider';
import { useEffect } from 'react';

export default function EnableQueriesAfterAuthentication() {
  const { isUserAuthenticated } = useAuth();

  useEffect(() => {
    queryClient.setDefaultOptions({
      queries: {
        enabled: isUserAuthenticated,
      },
    });
  }, [isUserAuthenticated]);

  return null;
}
