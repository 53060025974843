import { MinimalFolderType } from '@components/pages/knowledge-base/admin/types';
import Breadcrumb from '@components/pages/knowledge-base/components/breadcrumb';
import Header from '@components/pages/knowledge-base/components/header';
import AddKnowledgeBaseModal from '@components/pages/knowledge-base/components/modals/add-knowledge-base';
import CreateFolderModal from '@components/pages/knowledge-base/components/modals/create-folder';
import Table from '@components/pages/knowledge-base/components/table';
import { createContext, useCallback, useMemo, useState } from 'react';

type KnowledgeBaseContextType = {
  currentFolderId: MinimalFolderType['folderId'];
  isAddKnowledgeModalOpen: boolean;
  isCreateFolderModalOpen: boolean;
  handleCurrentFolderId: (folderId: MinimalFolderType['folderId']) => void;
  handleToggleAddKnowledgeModal: VoidFunction;
  handleToggleCreateFolderModal: VoidFunction;
};

export const KnowledgeBaseContext =
  createContext<KnowledgeBaseContextType | null>(null);

export default function KnowledgeBaseProvider({
  children,
}: React.PropsWithChildren) {
  const [currentFolderId, setCurrentFolderId] =
    useState<MinimalFolderType['folderId']>(null);

  const [isAddKnowledgeModalOpen, setIsAddKnowledgeModalOpen] = useState(false);

  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);

  const handleToggleCreateFolderModal = useCallback(() => {
    setIsCreateFolderModalOpen(!isCreateFolderModalOpen);
  }, [isCreateFolderModalOpen]);

  const handleToggleAddKnowledgeModal = useCallback(() => {
    setIsAddKnowledgeModalOpen(!isAddKnowledgeModalOpen);
  }, [isAddKnowledgeModalOpen]);

  const handleCurrentFolderId = useCallback(
    (folderId: MinimalFolderType['folderId']) => {
      setCurrentFolderId(folderId);
    },
    [],
  );

  const memoizedValue = useMemo(
    () => ({
      currentFolderId,
      isCreateFolderModalOpen,
      isAddKnowledgeModalOpen,
      handleCurrentFolderId,
      handleToggleAddKnowledgeModal,
      handleToggleCreateFolderModal,
    }),
    [
      handleCurrentFolderId,
      currentFolderId,
      handleToggleAddKnowledgeModal,
      handleToggleCreateFolderModal,
      isAddKnowledgeModalOpen,
      isCreateFolderModalOpen,
    ],
  );

  return (
    <KnowledgeBaseContext.Provider value={memoizedValue}>
      {children}
    </KnowledgeBaseContext.Provider>
  );
}

KnowledgeBaseProvider.Header = Header;
KnowledgeBaseProvider.Breadcrumb = Breadcrumb;
KnowledgeBaseProvider.CreateFolderModal = CreateFolderModal;
KnowledgeBaseProvider.AddKnowledgeBaseModal = AddKnowledgeBaseModal;
KnowledgeBaseProvider.Table = Table;
