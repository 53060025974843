import { useTranslation } from '@desygner/ui-common-translation';
import Typography from '@mui/material/Typography';

export default function Transcript() {
  const { t } = useTranslation();
  return (
    <Typography paragraph>
      {t('comingSoon', {
        defaultValue: 'Coming soon',
      })}
    </Typography>
  );
}
