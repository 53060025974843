import CommandDesigner from '@components/pages/command-designer';
import { StyledAnimatedBox } from '@components/pages/styles';
import FlowRunProvider from '@providers/FlowRunContext';

export default function CommandDesignerPage() {
  return (
    <FlowRunProvider>
      <StyledAnimatedBox aria-hidden />
      <CommandDesigner />
    </FlowRunProvider>
  );
}
