import StyledLoadingFolderButton from '@components/pages/knowledge-base/components/loading-folder-button/styles';
import { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { LinkProps } from 'react-router-dom';

export default function LoadingFolderButton({
  children,
  ...otherProps
}: LoadingButtonProps & LinkProps) {
  return (
    <StyledLoadingFolderButton {...otherProps} disableRipple>
      {children}
    </StyledLoadingFolderButton>
  );
}
