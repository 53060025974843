import { ThemeType } from '@shared-types/theme';

export default function Tooltip(theme: ThemeType) {
  const isLight = theme.palette.mode === 'light';

  const color = isLight
    ? theme.palette.grey[900]
    : theme.palette.background.card.main;

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: color,
          boxShadow: theme.customShadows.z8,
          padding: theme.spacing(1.5),
          fontSize: theme.typography.overline.fontSize,
        },
        arrow: {
          color: color,
        },
      },
    },
  };
}
