// TODO: Remove once hook up with APIs.
import DeleteIcon from '@mui/icons-material/Delete';
import PauseIcon from '@mui/icons-material/Pause';
import SettingsIcon from '@mui/icons-material/Settings';
import { useMemo } from 'react';
import List from '@components/list';
import {
  StyledAppOptionWrapper,
  StyledContainer,
  StyledCrawlingOptionWrapper,
  StyledDocumentsOptionWrapper,
  StyledDropdownContainer,
  StyledHeaderWrapper,
  StyledIconTextContainer,
  StyledIndexingOptionWrapper,
  StyledListItemContainer,
  StyledListItemWrapper,
} from '@components/pages/app-status/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import SimpleDropdown from '@components/dropdown/simple';
import type { DropdownOptionsProps } from '@components/dropdown/types';
import IconButton from '@mui/material/IconButton';
import mockData from './mockData';

export type Status = 'enabled' | 'error' | 'restricted' | 'synced' | 'progress';

export interface AppItemProps {
  app: {
    name: string;
    icon: string;
  };
  crawling: {
    display: string;
    icon: string;
    status: Status;
  };
  indexing: {
    display: string;
    icon: string;
    status: Status;
  };
  documents: number;
  dropdownItems: DropdownOptionsProps;
}

const OPTIONS: DropdownOptionsProps = [
  {
    text: 'App Settings',
    value: 'app-settings',
    icon: {
      iconStart: <SettingsIcon />,
    },
    onClick: (selectedValue) => {
      //console.info('value is: ', selectedValue);
    },
  },
  {
    text: 'Pause Sync',
    value: 'pause-sync',
    icon: {
      iconStart: <PauseIcon />,
    },
    onClick: (selectedValue) => {
      //console.info('value is: ', selectedValue);
    },
  },
  {
    text: 'Remove App',
    value: 'remove-app',
    icon: {
      iconStart: <DeleteIcon />,
    },
    onClick: (selectedValue) => {
      //console.info('value is: ', selectedValue);
    },
  },
];

export function AppStatus() {
  const items = useMemo(() => createAppItem(), []);

  return (
    <>
      <Typography variant="h1" component="h1">
        Status
      </Typography>
      <StyledContainer>
        <List
          children={items}
          header={<AppItemHeader />}
          hasHover={true}
        ></List>
      </StyledContainer>
    </>
  );
}

function AppItemHeader() {
  return (
    <StyledHeaderWrapper>
      <StyledListItemContainer>
        <StyledAppOptionWrapper>
          <StyledIconTextContainer>
            <Typography variant="body1">App</Typography>
          </StyledIconTextContainer>
        </StyledAppOptionWrapper>

        <StyledCrawlingOptionWrapper>
          <StyledIconTextContainer>
            <Typography variant="body1">Crawling</Typography>
          </StyledIconTextContainer>
        </StyledCrawlingOptionWrapper>

        <StyledIndexingOptionWrapper>
          <StyledIconTextContainer>
            <Typography variant="body1">Indexing status</Typography>
          </StyledIconTextContainer>
        </StyledIndexingOptionWrapper>

        <StyledDocumentsOptionWrapper>
          <Typography variant="body1">Documents</Typography>
        </StyledDocumentsOptionWrapper>
      </StyledListItemContainer>
    </StyledHeaderWrapper>
  );
}

function AppItem({
  app,
  crawling,
  indexing,
  documents,
  dropdownItems,
}: AppItemProps) {
  return (
    <StyledListItemWrapper>
      <StyledListItemContainer>
        <StyledAppOptionWrapper>
          <StyledIconTextContainer>
            {app.icon}
            <Typography variant="body1">{app.name}</Typography>
          </StyledIconTextContainer>
        </StyledAppOptionWrapper>

        <StyledCrawlingOptionWrapper>
          <StyledIconTextContainer>
            {crawling.icon}
            <Typography variant="body1">{crawling.display}</Typography>
          </StyledIconTextContainer>
        </StyledCrawlingOptionWrapper>

        <StyledIndexingOptionWrapper>
          <StyledIconTextContainer>
            {indexing.icon}
            <Typography variant="body1">{indexing.display}</Typography>
          </StyledIconTextContainer>
        </StyledIndexingOptionWrapper>

        <StyledDocumentsOptionWrapper>
          <Typography variant="body1">{documents}</Typography>
        </StyledDocumentsOptionWrapper>
      </StyledListItemContainer>

      <StyledDropdownContainer>
        <SimpleDropdown
          trigger={{
            element: (
              <IconButton>
                <MoreVertIcon />
              </IconButton>
            ),
          }}
          options={dropdownItems}
          placement="auto-start"
        />
      </StyledDropdownContainer>
    </StyledListItemWrapper>
  );
}

function loadData() {
  // TODO: Hook up with APIs.
  return mockData;
}

function createAppItem() {
  const data = loadData();
  const items: React.ReactNode[] = [];

  data.forEach((props, i) => {
    const itemProps = {
      ...props,
      crawling: {
        ...props.crawling,
        status: props.crawling.status as Status,
      },
      indexing: {
        ...props.indexing,
        status: props.indexing.status as Status,
      },
      dropdownItems: OPTIONS,
    };
    items.push(<AppItem key={i} {...itemProps} />);
  });

  return items;
}
