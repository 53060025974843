import useThemeSettings from '@hooks/useThemeSettings';
import React from 'react';
import { ToastContainer } from 'react-toastify';

export default function ToastifyContainer({
  children,
}: React.PropsWithChildren) {
  const { themeMode } = useThemeSettings();
  return (
    <>
      <ToastContainer theme={themeMode} />
      {children}
    </>
  );
}
