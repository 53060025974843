import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material';

const StyledLoadingFolderButton = styled(LoadingButton)(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'left',
  gap: theme.spacing(2),
  background: 'transparent',
  color: theme.palette.text.primary,
  border: 'none',
  cursor: 'pointer',
  textAlign: 'left',
  display: 'flex',
  padding: '0!important',
  '&:hover': {
    backgroundColor: 'transparent!important',
    color: theme.palette.primary.light,
  },
}));

export default StyledLoadingFolderButton;
