import { WorkspaceContext } from '@providers/WorkspaceProvider';
import { useContext } from 'react';

export default function useWorkspace() {
  const context = useContext(WorkspaceContext);

  if (!context) {
    throw new Error('useWorkspace must be used within an WorkspaceProvider');
  }

  return context;
}
