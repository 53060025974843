import { SideKickContext } from '@components/pages/side-kick-session/providers/SideKickProvider';
import { useContext } from 'react';

export default function useSideKick() {
  const context = useContext(SideKickContext);

  if (!context) {
    throw new Error('useSideKick must be used within a SideKickProvider');
  }

  return context;
}
