const CHAT = {
  AVATAR_SIZE: 25,
  MAX_WIDTH: '70%',
};

const CONFIG = {
  CHAT,
} as const;

export default CONFIG;
