import { useTranslation } from '@desygner/ui-common-translation';
import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';
import useWorkspace from '@hooks/useWorkspace';
import { accessConfigurations, auth } from '@lib/agent';
import { queryClient } from '@providers/ReactQueryProvider';
import { AccessConfigurationType } from '@shared-types/sso';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, isAxiosError } from 'axios';

const MAX_NAME_LENGTH = 60;
const DOTS_LENGTH = 3;

export function useEnabledAccessConfigurations() {
  const { t } = useTranslation();
  const {
    data: accessConfigurationsData,
    isLoading: isLoadingAccessConfigurations,
  } = useQueryAfterWorkspaceLoaded({
    queryKey: ['enabledAccessConfigurations'],
    queryFn: async () => {
      try {
        return await auth.getAccessConfigurations();
      } catch (error) {
        onError(t, error);
      }
    },
  });

  return { accessConfigurationsData, isLoadingAccessConfigurations };
}

export function useCreateOneAccessConfiguration() {
  const { t } = useTranslation();
  const { getNamespacedQueryKey } = useWorkspace();
  const queryKey = getNamespacedQueryKey('accessConfigurations');
  return useMutation({
    mutationFn: createOneAccessConfiguration,
    mutationKey: ['createOneAccessConfiguration'],
    onError: (error) => onError(t, error),
    onSuccess: (data, id) => {
      const accessConfigurationsCache = queryClient.getQueryData(queryKey) as {
        data: AccessConfigurationType[];
      };
      if (accessConfigurationsCache && accessConfigurationsCache.data) {
        const { data: accessConfigurationsData } = accessConfigurationsCache;
        accessConfigurationsData.push(data.data);
        queryClient.setQueryData(queryKey, accessConfigurationsCache);
      }
    },
  });
}

export function useDeleteOneAccessConfiguration() {
  const { t } = useTranslation();
  const { getNamespacedQueryKey } = useWorkspace();
  const queryKey = getNamespacedQueryKey('accessConfigurations');
  return useMutation({
    mutationFn: accessConfigurations.deleteOneAccessConfiguration,
    onError: (error) => onError(t, error),
    onSuccess: (data, id) => {
      const accessConfigurationsCache = queryClient.getQueryData(queryKey) as {
        data: AccessConfigurationType[];
      };
      if (accessConfigurationsCache && accessConfigurationsCache.data) {
        const { data: accessConfigurationsData } = accessConfigurationsCache;
        const accessConfigurationInCacheIndex =
          accessConfigurationsData.findIndex(
            (accessConfiguration) => accessConfiguration.id === id,
          );
        accessConfigurationInCacheIndex !== -1 &&
          accessConfigurationsData.splice(accessConfigurationInCacheIndex, 1);
        queryClient.setQueryData(queryKey, accessConfigurationsCache);
      }

      queryClient.setQueryData(['accessConfiguration', id], data);
    },
  });
}

export function useUpdateOneAccessConfiguration() {
  const { t } = useTranslation();
  const { getNamespacedQueryKey } = useWorkspace();
  const queryKey = getNamespacedQueryKey('accessConfigurations');
  return useMutation({
    mutationFn: updateOneAccessConfiguration,
    onError: (error) => onError(t, error),
    onSuccess: (data, variables) => {
      const accessConfigurationsCache = queryClient.getQueryData(queryKey) as {
        data: AccessConfigurationType[];
      };

      if (accessConfigurationsCache && accessConfigurationsCache.data) {
        const { data: accessConfigurationsData } = accessConfigurationsCache;
        const accessConfigurationInCache = accessConfigurationsData.find(
          (accessConfiguration) => accessConfiguration.id === variables.id,
        );

        if (accessConfigurationInCache) {
          const updatedAccessConfiguration =
            data.data as AccessConfigurationType;

          for (const key in updatedAccessConfiguration) {
            accessConfigurationInCache[key] = updatedAccessConfiguration[key];
          }
        }
        queryClient.setQueryData(queryKey, accessConfigurationsCache);
      }

      queryClient.setQueryData(['accessConfiguration', variables.id], data);
    },
  });
}

export function useGetAllAccessConfigurations() {
  const { t } = useTranslation();
  const {
    data: accessConfigurationsData,
    isLoading: isLoadingAccessConfigurations,
  } = useQueryAfterWorkspaceLoaded({
    queryKey: ['accessConfigurations'],
    queryFn: async () => {
      try {
        return await accessConfigurations.getAllAccessConfigurations();
      } catch (error) {
        onError(t, error);
      }
    },
  });

  return { accessConfigurationsData, isLoadingAccessConfigurations };
}

export function useGetAccessConfiguration(id: number) {
  const { t } = useTranslation();
  const {
    data: accessConfigurationData,
    isLoading: isLoadingAccessConfiguration,
  } = useQueryAfterWorkspaceLoaded({
    queryKey: ['accessConfiguration', id],
    queryFn: async () => {
      try {
        return await accessConfigurations.getAccessConfiguration(id);
      } catch (error) {
        if (!isAxiosError(error)) return;

        onError(t, error);
      }
    },
  });

  return { accessConfigurationData, isLoadingAccessConfiguration };
}

function onError(t, error: AxiosError) {
  //TODO: turn on toast.error later once patch is ready
  // toast.error(
  //   t('response.errors.code.520', {
  //     defaultValue: 'Unknown error. Please try again later.',
  //   }),
  // );
  console.error(error);
}

async function updateOneAccessConfiguration(payload: AccessConfigurationType) {
  const { id, ...rest } = payload;

  if (rest.name && rest.name.length > MAX_NAME_LENGTH) {
    rest.name = rest.name.slice(0, MAX_NAME_LENGTH - DOTS_LENGTH) + '...';
  }

  return await accessConfigurations.updateOneAccessConfiguration(id!, rest);
}

async function createOneAccessConfiguration({
  name,
  ...rest
}: AccessConfigurationType) {
  let truncatedName = undefined as string | undefined;

  if (name.length > MAX_NAME_LENGTH) {
    truncatedName = name.slice(0, MAX_NAME_LENGTH - DOTS_LENGTH) + '...';
  }

  return await accessConfigurations.createOneAccessConfiguration({
    name: truncatedName || name,
    ...rest,
  });
}
