import { styled, alpha, ListItemProps } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { NavLinkProps, To } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import CONFIG from '@components/workspace/config';
import { convertToRem } from '@lib/fonts';

export const StyledClickableAvatar = styled(Avatar)(({ theme }) => ({
  width: convertToRem(CONFIG.AVATAR_SIZE),
  height: convertToRem(CONFIG.AVATAR_SIZE),
  cursor: 'pointer',
  color: theme.palette.primary.contrastText,

  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
  },

  '& span': {
    marginTop: theme.spacing(1),
  },
}));

export const StyledStack = styled(Stack)(() => ({
  width: '100%',
  justifyContent: 'start',
  alignItems: 'center',
  cursor: 'pointer',
  paddingBottom: 0,
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadius * 100,
  padding: 0,
})) as React.ComponentType<
  ListItemProps & Omit<NavLinkProps, 'to'> & { to?: To }
>;
