import { styled } from '@mui/material';
import { FileInput } from '@uppy/react';
import Button from '@mui/material/Button';

export const StyledFileInput = styled(FileInput)(() => ({
  '& .uppy-FileInput-btn': {
    display: 'none', //? hide the default file input button for Uppy
  },
}));

export const StyledButton = styled(Button)(() => ({
  '.uppy-FileInput-btn': {
    display: 'none',
  },
})) as typeof Button;
