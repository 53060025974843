import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';

export const mockFileData = [
  {
    file: 'Q1 Payroll',
    format: <BorderAllIcon />,
    source: 'Desygner',
    lastAdded: '3 minutes ago',
  },
  {
    file: 'Marketing Strategy',
    format: <FormatColorTextIcon />,
    source: 'Google Drive',
    lastAdded: '5 minutes ago',
  },
  {
    file: 'Outreach Campaign',
    format: <DescriptionIcon />,
    source: 'Dropbox',
    lastAdded: '10 minutes ago',
  },
  {
    file: 'Stakeholder Presentation',
    format: <FormatColorTextIcon />,
    source: 'Confluence',
    lastAdded: '20 minutes ago',
  },
  {
    file: 'Project Concept',
    format: <FormatShapesIcon />,
    source: 'Slack',
    lastAdded: '1 hour ago',
  },
  {
    file: 'Euro Payroll ',
    format: <BorderAllIcon />,
    source: 'Google Drive',
    lastAdded: '1 hour ago',
  },
  {
    file: 'Example File',
    format: <FormatShapesIcon />,
    source: 'Desygner',
    lastAdded: '1 day ago',
  },
  {
    file: 'Example File',
    format: <FormatShapesIcon />,
    source: 'Desygner',
    lastAdded: '1 day ago',
  },
  {
    file: 'Example File',
    format: <FormatShapesIcon />,
    source: 'Desygner',
    lastAdded: '1 day ago',
  },
  {
    file: 'Example File',
    format: <FormatShapesIcon />,
    source: 'Desygner',
    lastAdded: '1 day ago',
  },
  {
    file: 'Example File',
    format: <FormatShapesIcon />,
    source: 'Desygner',
    lastAdded: '1 day ago',
  },
  {
    file: 'Example File',
    format: <FormatShapesIcon />,
    source: 'Desygner',
    lastAdded: '1 day ago',
  },
  {
    file: 'Example File',
    format: <FormatShapesIcon />,
    source: 'Desygner',
    lastAdded: '1 day ago',
  },
  {
    file: 'Example File',
    format: <FormatShapesIcon />,
    source: 'Desygner',
    lastAdded: '1 day ago',
  },
  {
    file: 'Example File',
    format: <FormatShapesIcon />,
    source: 'Desygner',
    lastAdded: '1 day ago',
  },
];

export const mockFileMoreData = Array(15).fill({
  file: 'Example File',
  format: <FormatShapesIcon />,
  source: 'Desygner',
  lastAdded: '1 day ago',
});

export const mockAccountData = [
  {
    workspaceName: 'Google Drive',
    workspaceIcon: <AddToDriveIcon />,
    userName: 'John Smith',
  },
  {
    workspaceName: 'OneDrive',
    workspaceIcon: <CloudCircleIcon />,
    userName: 'John Smith',
  },
];
