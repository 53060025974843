import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

export const StyledStack = styled(Stack)(({ theme }) => ({
  transform: 'translate(-50%, -50%)',
  top: '50%',
  left: '50%',
  position: 'fixed',
  width: 500,
  maxWidth: '75%',
  backgroundColor: theme.palette.background.card.light,
  borderRadius: theme.shape.borderRadius * 4,
  padding: theme.spacing(8),
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: '100%',
  marginLeft: theme.spacing(2),
  '&, &:hover': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
}));
