import { ThemeType } from '@shared-types/theme';

export default function ListItemIcon(theme: ThemeType) {
  return {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: theme.spacing(8),
        },
      },
    },
  };
}
