import Skeleton from '@mui/material/Skeleton';
import { ColoredSentiments } from '@shared-types/sidekick';
import { FactCheckResponseType } from '@shared-types/threads';
import Chip from '@mui/material/Chip';

type FactCheckType = FactCheckResponseType['response'];

type Props = {
  coloredSentiment: ColoredSentiments | null;
  fact: FactCheckType | null | false;
};

type FactCheckColorType = {
  [key: string]: 'success' | 'error' | 'info';
};

const FACT_CHECK_COLOR_MAP: FactCheckColorType = {
  true: 'success',
  false: 'error',
  mixed: 'info',
};

export default function LabelChip({ fact, coloredSentiment }: Props) {
  //TODO: we need to handle the case wiser
  //? we use null for loading, false for no fact like in the case of a credit limit
  if (fact === false) {
    return null;
  }

  if (fact == null || coloredSentiment == null) {
    return (
      <Skeleton
        width={44}
        height={24}
        variant="rectangular"
        sx={{ borderRadius: 1.5 }}
      />
    );
  }

  if (coloredSentiment.length > 0) {
    return coloredSentiment.map((tag, i) => (
      <Chip
        key={i}
        label={tag.label}
        size="small"
        variant="outlined"
        color={tag.color}
        sx={{
          borderRadius: 1.5,
        }}
      />
    ));
  }

  if (Object.values(fact).length > 0 && fact.outcome !== 'not_applicable') {
    return (
      <Chip
        label={fact.outcome}
        size="small"
        variant="outlined"
        color={FACT_CHECK_COLOR_MAP[fact.outcome]}
        sx={{
          borderRadius: 1.5,
        }}
      />
    );
  }

  return null;
}
