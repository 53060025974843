import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function usePromptHistoryState() {
  const { state } = useLocation() as { state?: { prompt: string } };
  const [historyStatePrompt, setHistoryStatePrompt] = useState(state?.prompt);

  const clearHistoryStatePrompt = useCallback(() => {
    window.history.replaceState(
      {
        ...state,
        prompt: undefined,
      },
      '',
    );
    setHistoryStatePrompt(undefined);
  }, [state]);

  const setNewHistoryStatePrompt = useCallback(
    (value: string) => {
      setHistoryStatePrompt(value);
      window.history.replaceState(
        {
          ...state,
          prompt: value,
        },
        '',
      );
    },
    [state],
  );

  return {
    historyStatePrompt,
    clearHistoryStatePrompt,
    setNewHistoryStatePrompt,
  };
}
