import CanvasStars from '@components/canvas-stars';
import { StyledAnimatedBox } from '@components/pages/styles';
import useWorkspace from '@hooks/useWorkspace';
import Header from '@layouts/main/sections/header';
import Toolbar from '@layouts/main/sections/toolbar';
import { StyledStack } from '@layouts/styles';
import { convertToRem } from '@lib/fonts';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import CONFIG_HEADER from '@layouts/main/config';

export default function MainLayout({ children }: React.PropsWithChildren) {
  const { alertHeight } = useWorkspace();

  return (
    <StyledStack>
      <Header />
      <Container maxWidth={false}>
        <StyledAnimatedBox aria-hidden />
        <CanvasStars />
        <Stack
          sx={{
            marginBlockStart: convertToRem(
              alertHeight
                ? alertHeight + CONFIG_HEADER.HEADER.PADDING_Y / 2
                : 0,
            ),
            flexDirection: {
              xs: 'none',
              md: 'row',
            },
            justifyContent: 'center',
            minHeight: '100%',
          }}
        >
          <Toolbar>{children}</Toolbar>
        </Stack>
      </Container>
    </StyledStack>
  );
}
