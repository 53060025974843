import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { anOldHope } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { useRef, useState } from 'react';
import { StyledTextArea } from './styles';

type ObjectBlockProps = {
  data: string;
  onChange: (e: Event | React.SyntheticEvent) => void;
};

export default function EditableCodeBlock({
  data,
  onChange,
}: ObjectBlockProps) {
  const theme = useTheme();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [code, setCode] = useState(data);

  return (
    <Box
      role="button"
      tabIndex={0}
      onKeyDown={() => textareaRef.current?.focus()}
      onClick={() => textareaRef.current?.focus()}
      sx={{
        minHeight: '6rem',
        position: 'relative',
        display: 'flex',
        borderRadius: theme.spacing(2),
        backgroundColor: theme.palette.background.secondary,
      }}
    >
      <StyledTextArea
        ref={textareaRef}
        value={code}
        onChange={(e) => {
          setCode(e.target.value);
          onChange(e);
        }}
        data-gramm="false"
        data-gramm_editor="false"
        data-enable-grammarly="false"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />

      <SyntaxHighlighter
        language="javascript"
        wrapLines={false}
        wrapLongLines={true}
        showLineNumbers={false}
        showInlineLineNumbers={false}
        style={anOldHope}
        customStyle={{
          flex: '1',
          color: theme.palette.common.white,
          background: 'transparent',
        }}
      >
        {code}
      </SyntaxHighlighter>
    </Box>
  );
}
