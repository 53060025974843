import { styled } from '@mui/material';

export const StyledContainer = styled('div')`
  display: flex;
  height: fit-content;
  max-height: 90vh;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.neutral.border};
  border-radius: ${({ theme }) => theme.shape.borderRadius * 3};
`;

export const StyledHeaderWrapper = styled('div')`
  display: flex;
  width: 100%;
  padding-right: ${({ theme }) => theme.spacing(4)};
`;

export const StyledListItemWrapper = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const StyledListItemContainer = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(3)};
  justify-content: space-between;
`;

export const StyledIconTextContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const StyledDropdownContainer = styled('div')`
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
`;

export const StyledAppOptionWrapper = styled('div')`
  display: flex;
  width: 260px;
`;

export const StyledCrawlingOptionWrapper = styled('div')`
  display: flex;
  width: 111px;
`;

export const StyledIndexingOptionWrapper = styled('div')`
  display: flex;
  width: 168px;
`;

export const StyledDocumentsOptionWrapper = styled('div')`
  display: flex;
  width: 120px;
`;

export const StyledDropdownItemWrapper = styled('div')`
  display: flex;
  user-select: none;
  padding: ${({ theme }) => theme.spacing(2)};
  margin-right: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.shape.borderRadius * 2};

  &:hover {
    transition: all 500ms;
    cursor: pointer;
  }
`;
