import { KnowzStepDefinition } from '@lib/step/types';

const MatchStepDefinition: KnowzStepDefinition = {
  order: 209,
  group: 'AI',
  step: {
    componentType: 'task',
    type: 'match',
    name: 'RAG Match',
  },
  propertyTypes: [
    {
      id: 'input',
      name: 'Context',
      description: 'Look for semantic similarities in the given context',
      usage: 'input',
      wrapper: 'item',
      types: ['ref-json', 'ref-source', 'ref-string'],
    },
    {
      id: 'output',
      name: 'Results reference',
      description:
        'Matches found in the knowledge base, for later referencing in your command',
      usage: 'output',
      wrapper: 'item',
      types: ['ref-matches'],
    },
  ],
};

export default MatchStepDefinition;
