import { ThemeType } from '@shared-types/theme';

export default function ImageListItem(theme: ThemeType) {
  return {
    MuiImageListItem: {
      styleOverrides: {
        root: {
          img: {
            borderRadius: theme.shape.borderRadius * 2,
          },
        },
      },
    },
  };
}
