import { List, styled } from '@mui/material';
import Carousel from 'react-multi-carousel';

export const StyledList = styled(List)(() => ({
  padding: 0,
}));

export const StyledCarousel = styled(Carousel)(({ theme }) => ({
  '.carousel-item-padding': {
    padding: theme.spacing(2),
  },
}));
