import CanvasStars from '@components/canvas-stars';
import { StyledAnimatedBox } from '@components/pages/styles';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

export default function Main({ children }: React.PropsWithChildren) {
  return (
    <Container maxWidth={false}>
      <StyledAnimatedBox aria-hidden />
      <CanvasStars />
      <Stack
        sx={{
          flexDirection: {
            xs: 'none',
            md: 'row',
          },
          justifyContent: 'center',
        }}
      >
        {children}
      </Stack>
    </Container>
  );
}
