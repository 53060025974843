/* eslint-disable import/prefer-default-export */
import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

const CONFIG = {
  BOX_SIZE: 610,
  ANIMATION_DISTANCE: 80,
};

export const StyledStack = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(5),
  left: `-${CONFIG.ANIMATION_DISTANCE}px`,
  zIndex: theme.zIndex.snackbar,
  backgroundColor: theme.palette.background.default,
  width: `${CONFIG.BOX_SIZE}pX`,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 6,
  boxShadow: theme.customShadows.card,
  opacity: 0,
  transition: '.6s opacity .2s, .6s left, .6s bottom',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    bottom: `-${CONFIG.ANIMATION_DISTANCE}px`,
    left: 0,
    borderRadius: 0,
  },
  [theme.breakpoints.down('sm')]: {
    '& img': {
      display: 'none',
    },
  },

  '&.state-entered': {
    opacity: 1,
    left: theme.spacing(5),

    [theme.breakpoints.down('md')]: {
      left: 0,
      bottom: 0,
    },
  },
}));
