import { alpha, styled } from '@mui/material';
import Button from '@mui/material/Button';

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'sticky',
  bottom: theme.spacing(24),
  right: theme.spacing(3),
  width: '180px',
  left: '100%',
  marginTop: 'auto',
  backgroundColor: alpha(theme.palette.background.card.light, 0.9),
  [theme.breakpoints.up('md')]: {
    bottom: theme.spacing(3),
  },
}));

export default StyledButton;
