import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

const StyledStack = styled(Stack)(({ theme }) => ({
  // borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(3),
  alignItems: 'start',
  borderTop:
    theme.palette.mode === 'dark' ? '1px solid #3e3e40' : '1px solid #dfdfdf',

  background:
    theme.palette.mode === 'dark'
      ? `radial-gradient(circle 800px at 110% 280%,
        rgba(242, 124, 179, 1) 35%,
        rgba(67, 70, 203, 1) 42%,
        rgba(67, 70, 203, 0) 50%
      )`
      : `radial-gradient(circle 800px at 110% 280%,
        rgba(249, 235, 248, 1) 35%,
        rgba(208, 247, 254, 1) 40%,
        rgba(208, 247, 254, 0) 48%
      )`,

  '&.mini': {
    background:
      theme.palette.mode === 'dark'
        ? `radial-gradient(circle 800px at 110% 560%,
          rgba(242, 124, 179, 1) 35%,
          rgba(67, 70, 203, 1) 40%,
          rgba(67, 70, 203, 0) 48%
        )`
        : `radial-gradient(circle 800px at 110% 560%,
          rgba(249, 235, 248, 1) 35%,
          rgba(208, 247, 254, 1) 40%,
          rgba(208, 247, 254, 0) 48%
        )`,
  },
}));

export default StyledStack;
