import Drawer from '@layouts/assistants-session/drawer';
import Header from '@layouts/assistants-session/sections/header';
import Main from '@layouts/assistants-session/sections/main';
import { createContext, useState } from 'react';

type AssistantsSessionContextType = {
  isHistoryDrawerOpen: boolean;
  toggleHistoryDrawer: VoidFunction;
};

export const AssistantsSessionContext =
  createContext<AssistantsSessionContextType | null>(null);

export default function AssistantsSessionProvider({
  children,
}: React.PropsWithChildren) {
  const [isHistoryDrawerOpen, setIsHistoryDrawerOpen] = useState(false);

  function toggleHistoryDrawer() {
    setIsHistoryDrawerOpen(!isHistoryDrawerOpen);
  }

  return (
    <AssistantsSessionContext.Provider
      value={{
        isHistoryDrawerOpen,
        toggleHistoryDrawer,
      }}
    >
      {children}
    </AssistantsSessionContext.Provider>
  );
}

AssistantsSessionProvider.Header = Header;
AssistantsSessionProvider.Main = Main;
AssistantsSessionProvider.Drawer = Drawer;
