import {
  StyledMainWrapper,
  StyledPaper,
} from '@components/pages/side-kick-session/main-wrapper/styles';
import Container from '@mui/material/Container';

export default function MainWrapper({ children }: React.PropsWithChildren) {
  return (
    <StyledMainWrapper>
      <Container maxWidth={false}>
        <StyledPaper elevation={2}>{children}</StyledPaper>
      </Container>
    </StyledMainWrapper>
  );
}
