import SectionHeading from '@components/pages/search/components/SectionHeading';
import Card from '@components/pages/search/components/shared-blocks/links/sections/card';
import { alpha } from '@mui/material';
import MuiCard from '@mui/material/Card';
import Drawer from '@mui/material/Drawer';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import LinksResponseType from '@shared-types/search-result/links';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useTranslation } from '@desygner/ui-common-translation';

const DISPLAY_LIMIT = 3;
const COLS_IN_MD_SCREEN = 3;
const COLS_IN_XS_SCREEN = 6;
const SPACE_BETWEEN = 4;
const DESKTOP_DRAWER_WIDTH = 700;
const MOBILE_DRAWER_WIDTH = 320;

type Props = {
  links: LinksResponseType;
};

export default function Links({ links }: Props) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { state } = useLocation();
  const { t } = useTranslation();
  const prompt = state?.name as string | undefined;

  function toggleMoreLinks() {
    setIsDrawerOpen(!isDrawerOpen);
  }

  if (!links || links.length === 0) return null;

  return (
    <SectionHeading
      title={t('page.search.sections.sources', {
        defaultValue: 'Sources',
      })}
      icon={
        <Icon>
          <FormatListBulletedIcon />
        </Icon>
      }
    >
      <Stack gap={5}>
        <Grid2
          container
          spacing={{
            xs: 6,
            sm: 6,
            md: 3,
          }}
          columnSpacing={SPACE_BETWEEN}
        >
          {links.slice(0, DISPLAY_LIMIT).map((link, i) => (
            <Grid2
              component="a"
              target="_blank"
              href={link.link}
              rel="noreferrer"
              md={COLS_IN_MD_SCREEN}
              xs={COLS_IN_XS_SCREEN}
              key={i}
            >
              <Card link={link} />
            </Grid2>
          ))}
          <Grid2 md={COLS_IN_MD_SCREEN} xs={COLS_IN_XS_SCREEN}>
            <MuiCard
              sx={{ height: '100%' }}
              disableRipple
              disableTouchRipple
              focusRipple={false}
              component={ListItemButton}
              onClick={toggleMoreLinks}
            >
              <Typography>View {links.length - DISPLAY_LIMIT} More</Typography>
            </MuiCard>
          </Grid2>
        </Grid2>
        <Drawer
          variant="temporary"
          PaperProps={{
            sx: {
              padding: 6,
              width: {
                xs: MOBILE_DRAWER_WIDTH,
                md: DESKTOP_DRAWER_WIDTH,
              },
              backgroundColor: (theme) =>
                alpha(theme.palette.common.white, 0.05),
              backdropFilter: 'blur(90px)',
            },
          }}
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleMoreLinks}
        >
          <Stack gap={5}>
            <Stack>
              <Typography component="h2" variant="h2">
                {links.length} Sources
              </Typography>
              <Typography variant="body1" color="neutral.grey">
                {prompt}
              </Typography>
            </Stack>
            <Stack gap={2}>
              {links.slice(DISPLAY_LIMIT).map((link, i) => (
                <Grid2
                  component="a"
                  target="_blank"
                  href={link.link}
                  rel="noreferrer"
                  md={COLS_IN_MD_SCREEN}
                  xs={COLS_IN_XS_SCREEN}
                  key={i}
                >
                  <Card link={link} showDescription />
                </Grid2>
              ))}
            </Stack>
          </Stack>
        </Drawer>
      </Stack>
    </SectionHeading>
  );
}
