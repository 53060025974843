/* eslint-disable import/prefer-default-export */
import requests from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import {
  AccessConfigurationType,
  AccessConfigurationMinType,
} from '@shared-types/sso';

export const accessConfigurations = {
  createOneAccessConfiguration: (body: AccessConfigurationType) =>
    requests(axiosApiInstance).post<AccessConfigurationType>(
      '/v1/entities/access-configurations',
      body,
    ),
  updateOneAccessConfiguration: (
    id: number | string,
    body: AccessConfigurationType,
  ) =>
    requests(axiosApiInstance).patch<AccessConfigurationType>(
      `/v1/entities/access-configurations/${id}`,
      body,
    ),
  deleteOneAccessConfiguration: (id: number) =>
    requests(axiosApiInstance).delete(
      `/v1/entities/access-configurations/${id}`,
    ),
  getAllAccessConfigurations: () =>
    requests(axiosApiInstance).get<AccessConfigurationMinType[]>(
      '/v1/entities/access-configurations',
    ),
  getAccessConfiguration: (id: number) =>
    requests(axiosApiInstance).get<AccessConfigurationType>(
      `/v1/entities/access-configurations/${id}`,
    ),
};
