import { KnowzStepDefinition } from '@lib/step/types';

const CronTriggerStepDefinition: KnowzStepDefinition = {
  order: 101,
  group: 'Triggers',
  step: {
    componentType: 'task',
    type: 'cron-trigger',
    name: 'Cron',
  },
  triggerProperties: [
    {
      ref: 'date',
      type: 'ref-string',
    },
    {
      ref: 'runCount',
      type: 'ref-number',
    },
  ],
  propertyTypes: [
    {
      id: 'interval',
      name: 'Interval',
      description:
        'Select an interval for the cron trigger. ' +
        'The trigger will fire at the specified interval.',
      usage: 'definition',
      options: [
        '30 minutes',
        '1 hour',
        '3 hours',
        '6 hours',
        '12 hours',
        '1 day',
        '3 days',
        '1 week',
        '2 weeks',
        '1 month',
      ],
      wrapper: 'item',
      types: ['string'],
    },
  ],
};

export default CronTriggerStepDefinition;
