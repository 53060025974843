import UserAuthentication from '@components/user-authentication';
import useAuth from '@hooks/useAuth';
import useResponsive from '@hooks/useResponsive';
import useWorkspace from '@hooks/useWorkspace';
import HeaderWrapper from '@layouts/components/header-wrapper';
import SetupMode from '@layouts/main/sections/header/sections/setup-mode';
import CONFIG from '@layouts/main/sections/header/config';
import NavbarDrawer from '@layouts/main/sections/header/navbar-drawer';
import LogoZone from '@layouts/main/sections/header/sections/logo-zone';
import UserAccountPopover from '@layouts/main/sections/header/sections/user-account-popover';
import { StyledStack } from '@layouts/main/sections/header/styles';

export default function Header() {
  const isItDesktop = useResponsive('up', 'md');
  const isItTabletOrMobile = !isItDesktop;

  const { me } = useAuth();

  const { isWorkspaceAdmin } = useWorkspace();

  const hasSecuredAccount =
    me && (me.auth_type === 'email' || me.auth_type === 'oauth2');

  return (
    <HeaderWrapper>
      <LogoZone />

      <StyledStack
        direction="row"
        sx={{
          gap: {
            xs: 2,
            md: 3,
          },
        }}
      >
        {isWorkspaceAdmin && <SetupMode />}

        <StyledStack direction="row" gap={2}>
          {!hasSecuredAccount && <UserAuthentication />}
        </StyledStack>

        <UserAccountPopover />

        {isItTabletOrMobile && <NavbarDrawer navListItems={CONFIG.NAV_ITEMS} />}
      </StyledStack>
    </HeaderWrapper>
  );
}
