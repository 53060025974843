import EllipsisTypography from '@components/ellipsis-typography';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import MuiCard from '@mui/material/Card';

const AVATAR_SIZE = 25;

type Props = {
  link: any;
  showDescription?: boolean;
};

export default function Card({ link, showDescription = false }: Props) {
  return (
    <MuiCard
      sx={{
        height: '100%',
        backgroundColor: (theme) => theme.palette.background.card.main,
        p: 3,
      }}
      component={ListItemButton}
    >
      <Stack
        direction="column"
        gap={3}
        sx={{
          height: '100%',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <EllipsisTypography variant="body2" title={link.title} lineClamp={2}>
          {link.title}
        </EllipsisTypography>
        <Stack
          direction="row"
          gap={2}
          sx={{ width: '100%', alignItems: 'center' }}
        >
          {link &&
          link.pagemap &&
          link.pagemap.cse_thumbnail &&
          link.pagemap.cse_thumbnail.length > 0 &&
          link.pagemap.cse_thumbnail[0].src ? (
            <Avatar
              sx={{
                width: AVATAR_SIZE,
                height: AVATAR_SIZE,
              }}
              alt={link.title}
              src={link.pagemap.cse_thumbnail[0].src}
            />
          ) : (
            <Avatar
              sx={{
                width: AVATAR_SIZE,
                height: AVATAR_SIZE,
                bgcolor: (theme) => theme.palette.neutral.grey,
              }}
            >
              &nbsp;
            </Avatar>
          )}
          <EllipsisTypography
            variant="overline"
            title={link.displayLink}
            lineClamp={1}
          >
            {(link?.pagemap?.metatags &&
              link?.pagemap?.metatags[0] &&
              link?.pagemap?.metatags[0]['og:site_name']) ||
              'SOURCE'}
          </EllipsisTypography>
        </Stack>
        {showDescription && (
          <EllipsisTypography
            variant="body2"
            title={link.snippet}
            lineClamp={2}
          >
            {link.snippet}
          </EllipsisTypography>
        )}
      </Stack>
    </MuiCard>
  );
}
