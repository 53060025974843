import { useState } from 'react';
import { useLocation } from 'react-router';

export default function useRedirectHistoryState() {
  const { state } = useLocation() as { state?: { isUserRedirected: boolean } };
  const [isUserRedirected, setIsUserRedirected] = useState(
    state?.isUserRedirected ?? false,
  );

  function clearHistoryStateRedirect() {
    window.history.replaceState(
      {
        ...state,
        isUserRedirected: false,
      },
      '',
    );
    setIsUserRedirected(false);
  }

  return {
    isUserRedirected,
    clearHistoryStateRedirect,
  };
}
