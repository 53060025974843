import { threads } from '@lib/agent';
import { Dispatch, ListenerEffectAPI } from '@reduxjs/toolkit';
import { MonologueType } from '@shared-types/sidekick';
import { dialogueUpdated, initialStateType } from '@state/slices/transcription';
import { produce } from 'immer';

export default async function processAsyncSentimentsOnlyAndDispatchDialogueUpdated(
  currentDialogue: MonologueType,
  listenerApi: ListenerEffectAPI<
    { transcription: initialStateType },
    Dispatch,
    unknown
  >,
) {
  const matchAIResponse = await threads.getAIMatch({
    persist: true,
    prompt: currentDialogue.content,
    thread: new URL(window.location.href).searchParams.get('t')!,
  });

  const theMostUpdatedDialogue = listenerApi.getState().transcription.dialogue;

  const updatedDialogue = produce(theMostUpdatedDialogue, (draft) => {
    const index = draft.findIndex((d) => d.id === currentDialogue.id);
    if (index !== -1) {
      draft[index].tags = matchAIResponse?.data?.result || null;
    }
  });

  listenerApi.dispatch(
    dialogueUpdated({ reset: true, dialogue: updatedDialogue }),
  );
}
