type Props<T> = {
  totalCount: string;
  pageParam: number;
  limit: number;
  data: T;
};

export default function getDataWithPagination<T>({
  data,
  totalCount,
  pageParam,
  limit,
}: Props<T>) {
  return {
    data,
    meta: {
      pagination: {
        page: pageParam,
        pageSize: limit,
        pageCount: Math.ceil(parseInt(totalCount) / limit),
        total: parseInt(totalCount),
      },
    },
  };
}
