import { ThemeType } from '@shared-types/theme';

export default function Input(theme: ThemeType) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: any }) => ({
          borderRadius: theme.shape.borderRadius * 2,
          backgroundColor: theme.palette.background.secondary,
          fontSize:
            ownerState.size === 'small'
              ? theme.typography.body2.fontSize
              : theme.typography.body1.fontSize,
        }),
      },
    },
  };
}
