import { styled } from '@mui/material';
import Markdown from 'react-markdown';

const StyledMarkdown = styled(Markdown)(({ theme }) => ({
  '*': {
    revert: 'all',
  },
  a: {
    color: theme.palette.mode === 'dark' && theme.palette.primary.light,
  },
  p: {
    fontSize: theme.typography.body2.fontSize,
    lineHeight: 2,
  },
  strong: {
    fontSize: theme.typography.subtitle2.fontSize,
    lineHeight: 1.8,
  },
}));

export default StyledMarkdown;
