import { FullMembershipType } from '@shared-types/memberships';

export const RING_FENCED_CONNECTORS: {
  [key: string]: {
    name: string;
    type: string;
  };
} = {
  webrand: {
    name: 'We Brand',
    type: 'webrand_membership_id',
  },
  slack: {
    name: 'Slack',
    type: 'slack_user_id',
  },
  confluence: {
    name: 'Confluence', // TODO
    type: '',
  },
  ms_sharepoint: {
    name: 'SharePoint', // TODO
    type: '',
  },
  ms_teams: {
    name: 'Microsoft Teams',
    type: 'msteams_user_id',
  },
  // 'email': '',
  // 'website': '',
};

export function getConnectedId(
  membership: FullMembershipType,
  type: string,
): string | null {
  const id = membership.aliases.find((a) => a.type === type)?.reference;
  return id ? `#${id}` : null;
}

export function getEmailAlias(membership: FullMembershipType): string | null {
  const email = membership.aliases.find(
    (a) => a.type === 'membership_email',
  )?.reference;
  return email || null;
}
