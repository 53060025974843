import SideKickProvider from '@components/pages/side-kick-session/providers/SideKickProvider';
import { ConfirmProvider } from 'material-ui-confirm';

export default function SideKickSession() {
  return (
    <ConfirmProvider
      defaultOptions={{
        confirmationButtonProps: {
          variant: 'contained',
        },
        cancellationButtonProps: {
          variant: 'outlined',
        },
        allowClose: false,
      }}
    >
      <SideKickProvider>
        <SideKickProvider.MainWrapper>
          <SideKickProvider.AppBar />
          <SideKickProvider.Content />
          <SideKickProvider.StopRecordingButton />
        </SideKickProvider.MainWrapper>
        <SideKickProvider.Drawer />
      </SideKickProvider>
    </ConfirmProvider>
  );
}
