import Timer from '@components/pages/side-kick-session/utils/timer';
import { useCallback, useEffect, useMemo } from 'react';

export default function useTimer() {
  const timer = useMemo(() => new Timer(), []);

  const startTimer = useCallback(() => {
    timer.startTimer();
  }, [timer]);

  const resetTimer = useCallback(() => {
    timer.resetTimer();
  }, [timer]);

  useEffect(() => {
    startTimer();

    return () => {
      resetTimer();
    };
  }, [resetTimer, startTimer]);

  return timer;
}
