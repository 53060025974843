import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DialogueType } from '@shared-types/sidekick';

export type initialStateType = {
  isRecording: boolean;
  dialogue: DialogueType;
  shouldRunFactCheck: boolean;
  shouldRunSentimentCheck: boolean;
};

const initialState: initialStateType = {
  isRecording: false,
  dialogue: [],
  shouldRunFactCheck: true,
  shouldRunSentimentCheck: true,
};

const transcription = createSlice({
  name: 'transcription',
  initialState,
  reducers: {
    isRecordingUpdated: (state, action) => {
      return { ...state, isRecording: action.payload };
    },
    dialogueUpdated: (
      state,
      action: PayloadAction<{ reset: boolean; dialogue: DialogueType }>,
    ) => {
      return {
        ...state,
        dialogue: action.payload.reset
          ? action.payload.dialogue
          : [...state.dialogue, ...action.payload.dialogue],
      };
    },
    shouldRunFactCheckUpdated: (state, action) => {
      return { ...state, shouldRunFactCheck: action.payload };
    },
    shouldRunSentimentCheckUpdated: (state, action) => {
      return { ...state, shouldRunSentimentCheck: action.payload };
    },
  },
});

export const {
  isRecordingUpdated,
  dialogueUpdated,
  shouldRunFactCheckUpdated,
  shouldRunSentimentCheckUpdated,
} = transcription.actions;

export default transcription.reducer;
