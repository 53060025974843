import { UserAuthenticationContext } from '@components/user-authentication/providers/UserAuthenticationProvider';
import { useContext } from 'react';

export default function useUserAuth() {
  const context = useContext(UserAuthenticationContext);

  if (!context) {
    throw new Error(
      'useUserAuth must be used within a UserAuthenticationProvider',
    );
  }

  return context;
}
