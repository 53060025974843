import useAssistantsDrawer from '@components/pages/assistants/hooks/useAssistantsDrawer';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type Props = {
  title: string;
  subTitle?: string;
};

export default function Heading({ title, subTitle }: Props) {
  const { handleSetIntegrateAssistantsDrawer } = useAssistantsDrawer();

  return (
    <>
      <Stack
        direction="row"
        sx={{ p: 4, justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Stack gap={2}>
          <Typography variant="subtitle1">{title}</Typography>
          {subTitle && <Typography variant="body2">{subTitle}</Typography>}
        </Stack>
        <IconButton onClick={() => handleSetIntegrateAssistantsDrawer(null)}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
    </>
  );
}
