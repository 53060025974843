import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import React from 'react';

type Props = React.PropsWithChildren<{
  icon: React.ReactNode;
  title: React.ReactNode;
}>;

export default function SectionHeading({ children, icon, title }: Props) {
  return (
    <Stack gap={2}>
      <Stack direction="row" gap={2} sx={{ alignItems: 'center' }}>
        {icon}
        <Typography component="h2" variant="subtitle1">
          {title}
        </Typography>
      </Stack>
      <Box component="section">{children}</Box>
    </Stack>
  );
}
