import ActionButton from '@components/pages/knowledge-base/components/action-button';
import '@cyntler/react-doc-viewer/dist/index.css';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import {
  StyledModal,
  StyledBox,
} from '@components/pages/knowledge-base/components/table/cells/action/source-viewer/styles';
import { useState } from 'react';
import Doc from '@components/pages/knowledge-base/components/table/cells/action/source-viewer/doc-viewer';

type Props = {
  id: number;
};

export default function SourceViewer({ id }: Props) {
  const [isSourceModalOpen, setIsSourceModalOpen] = useState(false);

  function toggleModal() {
    setIsSourceModalOpen(!isSourceModalOpen);
  }

  return (
    <>
      <ActionButton onClick={toggleModal}>
        <FullscreenIcon />
      </ActionButton>
      <StyledModal
        open={isSourceModalOpen}
        keepMounted={false}
        onClose={toggleModal}
      >
        <StyledBox>{isSourceModalOpen && <Doc id={id} />}</StyledBox>
      </StyledModal>
    </>
  );
}
