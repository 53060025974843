import ConversationHistory from '@layouts/assistants-session/drawer/conversation-history';
import Wrapper from '@layouts/assistants-session/drawer/wrapper';
import useAssistantsSession from '@layouts/assistants-session/hooks/useAssistantsSession';

export default function Drawer() {
  const { isHistoryDrawerOpen } = useAssistantsSession();
  let DrawerToOpen;

  if (isHistoryDrawerOpen) DrawerToOpen = <ConversationHistory />;

  return <Wrapper>{DrawerToOpen}</Wrapper>;
}
