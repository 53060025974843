import useResponsive from '@hooks/useResponsive';
import StyledDrawer from '@layouts/assistants-session/drawer/wrapper/styles';
import useAssistantsSession from '@layouts/assistants-session/hooks/useAssistantsSession';
import CONFIG from '@layouts/main/config';
import { convertToRem } from '@lib/fonts';
import Stack from '@mui/material/Stack';

export default function Wrapper({ children }: React.PropsWithChildren) {
  const { isHistoryDrawerOpen, toggleHistoryDrawer } = useAssistantsSession();
  const isItTabletOrMobile = useResponsive('down', 'md');
  const isDrawerOpen = isHistoryDrawerOpen;

  function handleCloseDrawerOnMobile() {
    toggleHistoryDrawer();
  }

  return (
    <StyledDrawer
      open={isDrawerOpen}
      variant={isItTabletOrMobile ? 'temporary' : 'permanent'}
      anchor="right"
      onClose={handleCloseDrawerOnMobile}
      sx={{
        height: `calc(100vh - ${convertToRem(
          CONFIG.HEADER.HEIGHT + CONFIG.HEADER.PADDING_Y * 2,
        )})`,
      }}
      PaperProps={{
        sx: {
          position: isItTabletOrMobile ? 'inherit' : 'sticky',
          border: 0,
          backgroundColor: 'background.card.light',
          borderRadius: isItTabletOrMobile ? 0 : 4,
          zIndex: 0,
        },
      }}
    >
      <Stack>{children}</Stack>
    </StyledDrawer>
  );
}
