import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';

const StyledTypography = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
})) as typeof Typography;

export default StyledTypography;
