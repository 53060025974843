import CanvasStars from '@components/canvas-stars';
import {
  StyledAnimatedBox,
  StyledContainerForFixedHeader,
} from '@components/pages/styles';
import Header from '@layouts/main/sections/header';
import { StyledStack } from '@layouts/styles';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

export default function KnowledgeBaseLayout({
  children,
}: React.PropsWithChildren) {
  return (
    <StyledStack>
      <Header />
      <Container maxWidth={false}>
        <StyledAnimatedBox aria-hidden />
        <CanvasStars />
        <Stack
          sx={{
            flexDirection: {
              xs: 'none',
              md: 'row',
            },
            justifyContent: 'center',
            minHeight: '100%',
          }}
        >
          <StyledContainerForFixedHeader
            sx={{ pb: 0 }}
            maxWidth={false}
            disableGutters
          >
            {children}
          </StyledContainerForFixedHeader>
        </Stack>
      </Container>
    </StyledStack>
  );
}
