//TODO: we might need to consider lazy loading the knowledge base component
import AdminKnowledgeBase from '@components/pages/knowledge-base/admin';
import MemberKnowledgeBase from '@components/pages/knowledge-base/member';
import { StyledContainerForFixedHeader } from '@components/pages/styles';
import useWorkspace from '@hooks/useWorkspace';

const KNOWLEDGE_BASE_TYPES = {
  admin: <AdminKnowledgeBase />,
  member: <MemberKnowledgeBase />,
};

export default function KnowledgeBase() {
  const { setupMode } = useWorkspace();

  if (setupMode === null) return null;

  return (
    <StyledContainerForFixedHeader
      disableGutters
      maxWidth={false}
      sx={{ pb: 0 }}
    >
      {KNOWLEDGE_BASE_TYPES[setupMode === 'on' ? 'admin' : 'member']}
    </StyledContainerForFixedHeader>
  );
}
