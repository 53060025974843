import StyledBox from '@components/workspace/setup/components/heading/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import Stack from '@mui/material/Stack';
import Typography, { TypographyProps } from '@mui/material/Typography';

type Props = {
  title: string;
  subtitle?: string;
  isOptional?: boolean;
};

const SHARED_PROPS: TypographyProps = {
  variant: 'body2',
  color: 'text.disabled',
};

export default function Heading({ title, subtitle, isOptional }: Props) {
  const { t } = useTranslation();

  return (
    <StyledBox
      sx={{
        my: 4,
        mx: 0,
        mt: !subtitle ? 0 : undefined,
      }}
    >
      <Stack gap={2} direction="row">
        <Typography variant="body1">{title}</Typography>
        {isOptional && (
          <Typography {...SHARED_PROPS}>
            {t(
              'page.knowledgeBase.workspace.createWorkspaceModal.optionalLabel',
              {
                defaultValue: '(Optional)',
              },
            )}
          </Typography>
        )}
      </Stack>
      {subtitle && <Typography {...SHARED_PROPS}>{subtitle}</Typography>}
    </StyledBox>
  );
}
