import useTimer from '@hooks/useTimer';
import { queryClient } from '@providers/ReactQueryProvider';
import {
  dialogueUpdated,
  isRecordingUpdated,
} from '@state/slices/transcription';
//import useMediaRecorder from '@wmik/use-media-recorder';
import { useDispatch } from 'react-redux';
import useSpeechToText from 'react-hook-speech-to-text';

export default function useTranscriptionService() {
  const dispatch = useDispatch();
  const { startSpeechToText, stopSpeechToText } = useSpeechToText({
    continuous: true,
    useLegacyResults: false,
  });

  // const { startRecording, stopRecording } = useMediaRecorder({
  //   blobOptions: {
  //     type: 'audio/wav',
  //   },
  //   recordScreen: false,
  //   mediaStreamConstraints: {
  //     audio: true,
  //     video: false,
  //   },
  // });
  const timer = useTimer();

  function startTranscript() {
    queryClient.invalidateQueries({ queryKey: ['credits'] });
    startSpeechToText();
    timer.startTimer();
    dispatch(isRecordingUpdated(true));
    // startRecording();
  }

  function stopTranscript() {
    stopSpeechToText();
    dispatch(dialogueUpdated({ dialogue: [], reset: true }));
    timer.stopTimer();
    dispatch(isRecordingUpdated(false));
    // stopRecording();
  }

  return {
    startTranscript,
    stopTranscript,
  };
}
