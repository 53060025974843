import useAssistantsSession from '@layouts/assistants-session/hooks/useAssistantsSession';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type Props = {
  title: string;
};

export default function Heading({ title }: Props) {
  const { toggleHistoryDrawer } = useAssistantsSession();

  return (
    <>
      <Stack
        direction="row"
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography variant="subtitle1">{title}</Typography>
        <IconButton onClick={toggleHistoryDrawer}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider orientation="horizontal" sx={{ my: 4 }} />
    </>
  );
}
