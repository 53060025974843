import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

type Props = {
  title: string;
  img: {
    src: string;
    width: number;
    height: number;
  };
};

const MAX_SIZE = 320;

export default function EmptyState({ title, img }: Props) {
  return (
    <Stack
      gap={2}
      sx={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}
    >
      <img src={img.src} width={img.width} height={img.height} alt={title} />
      <Typography
        sx={{ maxWidth: MAX_SIZE, textAlign: 'center' }}
        variant="body2"
      >
        {title}
      </Typography>
    </Stack>
  );
}
