import CopyButton from '@components/code-block/sections/copy-block';
import { useTheme } from '@mui/material';
import SyntaxHighlighter from 'react-syntax-highlighter';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { convertToRem } from '@lib/fonts';

type Props = {
  code: string;
  language: string;
  style: {
    [key: string]: React.CSSProperties;
  };
};

export default function CodeBlock({ code, language, style }: Props) {
  const theme = useTheme();

  return (
    <Stack
      className="CodeBlockClass"
      sx={{
        borderRadius: convertToRem(theme.shape.borderRadius * 2),
        backgroundColor: 'background.card.main',
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          p: theme.spacing(3, 2),
          alignItems: 'center',
        }}
      >
        <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
          {language}
        </Typography>
        <CopyButton code={code} />
      </Stack>
      <SyntaxHighlighter
        language={language}
        style={style}
        wrapLines={true}
        wrapLongLines={true}
        showLineNumbers={false}
        showInlineLineNumbers={false}
        customStyle={{
          backgroundColor: theme.palette.background.secondary,
          color: theme.palette.common.white,
        }}
      >
        {code}
      </SyntaxHighlighter>
    </Stack>
  );
}
