import EnableQueriesAfterAuthentication from '@components/EnableQueriesAfterAuthentication';
import ToastifyContainer from '@components/toastify';
import CookiesNotice from '@layouts/components/cookies-notice';
import isEmbedded from '@lib/loaderHelper';
import Providers from '@providers/index';
import router from '@router/index';
import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

export function App() {
  useEffect(() => {
    isEmbedded && router.navigate(window.location.pathname);
  }, []);

  return (
    <Providers>
      <ToastifyContainer />
      <CookiesNotice />
      <EnableQueriesAfterAuthentication />
      <RouterProvider router={router} />
    </Providers>
  );
}

export default App;
