import { BASE_FONT_SIZE, convertToRem, responsiveFontSize } from '@lib/fonts';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    display1: true;
  }
}

const FONT_FAMILY = 'Work Sans Variable, sans-serif';

const typography = {
  fontFamily: FONT_FAMILY,
  fontSize: BASE_FONT_SIZE,
  caption: {
    fontSize: convertToRem(12),
    fontWeight: 400,
    lineHeight: 1.5,
  },
  overline: {
    fontSize: convertToRem(12),
    fontWeight: 600,
    lineHeight: 1.5,
  },
  subtitle1: {
    fontSize: convertToRem(18),
    fontWeight: 600,
    lineHeight: 1.5,
  },
  subtitle2: {
    fontSize: convertToRem(14),
    fontWeight: 600,
    lineHeight: 1.5,
  },
  body1: {
    fontSize: convertToRem(16),
    fontWeight: 400,
    lineHeight: 1.5,
  },
  body2: {
    fontSize: convertToRem(14),
    fontWeight: 400,
    lineHeight: 1.5,
  },
  h1: {
    fontSize: convertToRem(41.8),
    fontWeight: 600,
    lineHeight: 1.5,
    ...responsiveFontSize({ xs: 35, sm: 38, md: 40, lg: 41.8 }),
  },
  h2: {
    fontSize: convertToRem(34.84),
    fontWeight: 600,
    lineHeight: 1.5,
    ...responsiveFontSize({ xs: 30, sm: 32, md: 33, lg: 34.84 }),
  },
  h3: {
    fontSize: convertToRem(29.03),
    fontWeight: 600,
    lineHeight: 1.5,
    ...responsiveFontSize({ xs: 25, sm: 27, md: 28, lg: 29.03 }),
  },
  h4: {
    fontSize: convertToRem(24.19),
    fontWeight: 600,
    lineHeight: 1.5,
    ...responsiveFontSize({ xs: 20, sm: 22, md: 23, lg: 24.19 }),
  },
  h5: {
    fontSize: convertToRem(20.16),
    fontWeight: 600,
    lineHeight: 1.5,
    ...responsiveFontSize({ xs: 16, sm: 18, md: 19, lg: 20.16 }),
  },
  h6: {
    fontSize: convertToRem(16.8),
    fontWeight: 600,
    lineHeight: 1.5,
    ...responsiveFontSize({ xs: 13, sm: 15, md: 16, lg: 16.8 }),
  },
  display1: {
    fontSize: convertToRem(64),
    fontWeight: 600,
    lineHeight: 1.5,
    ...responsiveFontSize({ xs: 40, sm: 45, md: 60, lg: 64 }),
  },
} as const;

export default typography;
