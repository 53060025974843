import Logo from '@components/logo';
import {
  StyledIconButton,
  StyledStack,
} from '@components/upgrade-modal/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import usePaymentWithStripe from '@hooks/usePaymentWithStripe';
import useSearchParamsState from '@hooks/useSearchParamsState';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function UpgradeModal() {
  const { t } = useTranslation();

  const [actionParams, setActionParams] = useSearchParamsState('action', '');
  const { isLoading, handleRedirectToStripe } = usePaymentWithStripe();
  const theme = useTheme();

  function onClose() {
    setActionParams('');
  }

  return (
    <Modal
      open={actionParams.includes('upgrade-account')}
      closeAfterTransition
      onClose={onClose}
    >
      <Fade in={actionParams.includes('upgrade-account')}>
        <StyledStack>
          <StyledIconButton onClick={onClose}>
            <CloseIcon />
          </StyledIconButton>
          <Stack gap={8} sx={{ alignItems: 'center' }}>
            <Logo />
            <Stack gap={2} sx={{ alignItems: 'center' }}>
              <Typography variant="h4" sx={{ mt: 8 }}>
                {t('layout.toolbar.buyAICredit.modal.title', {
                  defaultValue: 'Get More AI Credits!',
                })}
              </Typography>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                {t('layout.toolbar.buyAICredit.modal.subtitle', {
                  defaultValue:
                    'You’re on the FREE plan. Upgrade now to receive more credits and keep using our powerful AI features!',
                })}
              </Typography>
            </Stack>
            <img
              src={
                theme.palette.mode === 'dark'
                  ? 'https://assets.knowz.com/assets/svg/upgrade-dark.svg'
                  : 'https://assets.knowz.com/assets/svg/upgrade-light.svg'
              }
              alt="upgrade knowz plan"
              width={100}
              height={139}
            />
            <LoadingButton
              variant="contained"
              fullWidth
              loading={isLoading}
              onClick={handleRedirectToStripe}
            >
              {t('layout.toolbar.buyAICredit.modal.button', {
                defaultValue: 'Upgrade now!',
              })}
            </LoadingButton>
          </Stack>
        </StyledStack>
      </Fade>
    </Modal>
  );
}
