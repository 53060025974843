import useSideKick from '@components/pages/side-kick-session/hooks/useSideKick';
import StyledDrawer from '@components/pages/side-kick-session/sections/drawer/wrapper/styles';
import useResponsive from '@hooks/useResponsive';

export default function Wrapper({ children }: React.PropsWithChildren) {
  const { feedIsOpen, smartReviewIsOpen, handleResetDrawers } = useSideKick();
  const isItTabletOrMobile = useResponsive('down', 'md');
  const isDrawerOpen = feedIsOpen || smartReviewIsOpen;

  function handleCloseDrawerOnMobile() {
    handleResetDrawers();
  }

  return (
    <StyledDrawer
      open={isDrawerOpen}
      variant={isItTabletOrMobile ? 'temporary' : 'permanent'}
      anchor="right"
      onClose={handleCloseDrawerOnMobile}
      PaperProps={{
        sx: {
          position: isItTabletOrMobile ? 'inherit' : 'sticky',
          border: 0,
          backgroundColor: 'background.card.light',
          borderRadius: isItTabletOrMobile ? 0 : 4,
          p: 6,
          overflow: 'hidden',
        },
      }}
    >
      {children}
    </StyledDrawer>
  );
}
