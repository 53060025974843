import UserAuthenticationProvider from '@components/user-authentication/providers/UserAuthenticationProvider';
import useQueryParams from '@hooks/useQueryParams';
import useResponsive from '@hooks/useResponsive';
import { StripePaymentStatus } from '@shared-types/payment';
import { useEffect } from 'react';

export default function Authentication() {
  const isItDesktop = useResponsive('up', 'md');
  const { getQueryParamByKey, setQueryParam } = useQueryParams();
  const subscriptionParam =
    getQueryParamByKey<StripePaymentStatus>('subscription');

  useEffect(() => {
    if (subscriptionParam === 'success') {
      setQueryParam('action', 'secure-account');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserAuthenticationProvider>
      {isItDesktop ? (
        <>
          <UserAuthenticationProvider.DesktopAuthButton />
          <UserAuthenticationProvider.DesktopModalStepper>
            <UserAuthenticationProvider.ActiveForm />
          </UserAuthenticationProvider.DesktopModalStepper>
        </>
      ) : (
        <>
          <UserAuthenticationProvider.MobileAuthButton />
          <UserAuthenticationProvider.MobileModalStepper>
            <UserAuthenticationProvider.ActiveForm />
          </UserAuthenticationProvider.MobileModalStepper>
        </>
      )}
    </UserAuthenticationProvider>
  );
}
