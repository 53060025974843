import CreditQuantity from '@layouts/main/sections/toolbar/components/credit-quantity';
import PurchasedAICredit from '@layouts/main/sections/toolbar/expanded/sections/purchased-ai-credit';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export default function CreditUsage() {
  return (
    <Box
      sx={{
        backgroundColor: 'background.secondary',
        borderRadius: 3,
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Stack
        direction="row"
        gap={2}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          p: 3,
        }}
      >
        <CreditQuantity variant="expanded" />
      </Stack>
      <PurchasedAICredit />
    </Box>
  );
}
