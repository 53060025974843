import { styled } from '@mui/material';
import Card from '@mui/material/Card';

const StyledCard = styled(Card)(() => ({
  maxWidth: 800,
  width: '90%',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

export default StyledCard;
