import Box, { BoxProps } from '@mui/material/Box';
import { useTheme } from '@mui/material';
import {
  ArcElement,
  Chart,
  ChartData,
  PolarAreaController,
  RadialLinearScale,
  Legend,
  Tooltip,
  Title,
} from 'chart.js';
import { useEffect, useRef } from 'react';

type Props = {
  title?: string;
  data: ChartData;
} & BoxProps;

// polarArea https://www.chartjs.org/docs/latest/samples/other-charts/polar-area-center-labels.html
Chart.register(PolarAreaController, RadialLinearScale, ArcElement);
Chart.register(Legend, Tooltip, Title);

export default function PolarAreaChart(props: Props) {
  const { data, title, ...rest } = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>(null);
  const theme = useTheme();

  useEffect(() => {
    if (!canvasRef.current) return;
    const ctx = canvasRef.current.getContext('2d');
    if (ctx) {
      chartRef.current = new Chart(ctx, {
        type: 'polarArea',
        data,
        options: {
          interaction: {
            mode: 'dataset',
          },
          scales: {
            r: {
              ticks: { display: false },
              grid: {
                color: theme.palette.background.card.light,
                lineWidth: 0.5,
              },
            },
          },
          plugins: {
            title: {
              display: !!title,
              text: title,
              color: theme.palette.text.primary,
            },
            legend: {
              position: 'bottom',
              labels: {
                color: theme.palette.text.primary,
              },
            },
            tooltip: {
              mode: 'index',
              intersect: false,
            },
          },
        },
      });
    }

    return () => {
      chartRef.current?.destroy();
    };
  }, []);

  return (
    <Box {...rest}>
      <canvas ref={canvasRef}></canvas>
    </Box>
  );
}
