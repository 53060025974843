import { ThemeType } from '@shared-types/theme';

export default function Accordion(theme: ThemeType) {
  return {
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          alignItems: 'start',
          '&.Mui-expanded': {
            minHeight: 0,
          },
        },
        content: {
          margin: 0,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2),
        },
      },
    },
  };
}
