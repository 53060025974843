import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

export const StyledContainer = styled(Stack)`
  width: 326px;
  height: fit-content;
  border: 1px solid ${({ theme }) => theme.palette.neutral.border};
  border-radius: ${({ theme }) => theme.spacing(12)};
  padding: ${({ theme }) => theme.spacing(6)};
  gap: ${({ theme }) => theme.spacing(8)};
`;

export const StyledHeaderContainer = styled(Stack)`
  align-items: center;
  justify-content: space-between;
`;

export const StyledButtonWrapper = styled('div')`
  display: flex;
  height: 100%;
`;

export const StyledListItemContainer = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(3)};
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const StyledListWorkspaceIconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: content;
  padding: ${({ theme }) => theme.spacing(3)};
  border: 1px solid ${({ theme }) => theme.palette.neutral.border};
  border-radius: ${({ theme }) => theme.shape.borderRadius * 4};
`;

export const StyledRightIconWrapper = styled('div')`
  display: flex;
  margin-left: auto;
`;
