import LineAxisIcon from '@mui/icons-material/LineAxis';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CloudDoneIcon from '@mui/icons-material/CloudDone';

export const mockData = [
  {
    app: {
      name: 'Google Drive',
      icon: <AddToDriveIcon />,
    },
    crawling: {
      display: 'Enabled',
      icon: <LineAxisIcon />,
      status: 'enabled',
    },
    indexing: {
      display: 'Job in progress',
      icon: <AutorenewIcon />,
      status: 'progress',
    },
    documents: 1234,
  },
  {
    app: {
      name: 'Confluence',
      icon: <AddToDriveIcon />,
    },
    crawling: {
      display: 'Restricted',
      icon: <CloseIcon />,
      status: 'restricted',
    },
    indexing: {
      display: 'Synced',
      icon: <CheckBoxIcon />,
      status: 'synced',
    },
    documents: 68549,
  },
  {
    app: {
      name: 'OneDrive',
      icon: <CloudDoneIcon />,
    },
    crawling: {
      display: 'Error',
      icon: <ReportProblemIcon />,
      status: 'error',
    },
    indexing: {
      display: 'Error',
      icon: <ReportProblemIcon />,
      status: 'error',
    },
    documents: 34378,
  },
  {
    app: {
      name: 'Google Drive',
      icon: <AddToDriveIcon />,
    },
    crawling: {
      display: 'Enabled',
      icon: <LineAxisIcon />,
      status: 'enabled',
    },
    indexing: {
      display: 'Job in progress',
      icon: <AutorenewIcon />,
      status: 'progress',
    },
    documents: 1234,
  },
  {
    app: {
      name: 'Google Drive',
      icon: <AddToDriveIcon />,
    },
    crawling: {
      display: 'Enabled',
      icon: <LineAxisIcon />,
      status: 'enabled',
    },
    indexing: {
      display: 'Job in progress',
      icon: <AutorenewIcon />,
      status: 'progress',
    },
    documents: 1234,
  },
  {
    app: {
      name: 'Google Drive',
      icon: <AddToDriveIcon />,
    },
    crawling: {
      display: 'Enabled',
      icon: <LineAxisIcon />,
      status: 'enabled',
    },
    indexing: {
      display: 'Job in progress',
      icon: <AutorenewIcon />,
      status: 'progress',
    },
    documents: 1234,
  },
];

export default mockData;
