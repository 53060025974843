import BasicBreadcrumb from '@components/pages/knowledge-base/components/breadcrumb/BasicBreadcrumb';
import CondensedBreadcrumb from '@components/pages/knowledge-base/components/breadcrumb/CondensedBreadcrumb';
import { BreadcrumbItemType } from '@components/pages/knowledge-base/components/breadcrumb/types';
import useGetFolderDataByPath from '@hooks/useGetFolderDataByPath';
import useQueryParams from '@hooks/useQueryParams';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Stack } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useMemo } from 'react';

const BASE_BREADCRUMB: BreadcrumbItemType = {
  name: 'Knowledge Base',
  path: '',
  icon: <HomeOutlinedIcon />,
};

const BREADCRUMB_HEIGHT = 35;

export default function Breadcrumb() {
  const { getQueryParamByKey } = useQueryParams();

  const path = getQueryParamByKey('path');

  const { folderData, isFolderDataPending } = useGetFolderDataByPath({
    path,
  });

  const isItRootFolder = path === '';

  const calculatedBreadcrumb: BreadcrumbItemType[] = useMemo(
    () => {
      if (isItRootFolder || folderData == null) {
        return [BASE_BREADCRUMB];
      }
      const path = folderData.path;
      const breadcrumb = folderData.breadcrumb.split('/');
      return [
        BASE_BREADCRUMB,
        ...(folderData && path.length > 0
          ? path.split('/').map((path, i) => ({
              name: breadcrumb[i] || folderData.name,
              path,
            }))
          : []),
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderData?.id],
  );

  if (isFolderDataPending) {
    return <Skeleton width={250} height={BREADCRUMB_HEIGHT} />;
  }

  return (
    <Stack
      direction="row"
      gap={2}
      sx={{ height: BREADCRUMB_HEIGHT, alignItems: 'center' }}
    >
      {calculatedBreadcrumb.length > 3 ? (
        <CondensedBreadcrumb items={calculatedBreadcrumb} />
      ) : (
        <BasicBreadcrumb items={calculatedBreadcrumb} />
      )}
    </Stack>
  );
}
