import StyledTypography from '@components/ellipsis-typography/styles';
import { TypographyProps } from '@mui/material';

type Props = TypographyProps & {
  lineClamp: number;
};

export default function EllipsisTypography({
  children,
  lineClamp,
  sx,
  ...otherProps
}: Props) {
  return (
    <StyledTypography
      sx={{
        ...sx,
        WebkitLineClamp: lineClamp,
      }}
      {...otherProps}
    >
      {children}
    </StyledTypography>
  );
}
