import AsyncAutocomplete, {
  OptionType,
} from '@components/pages/command-designer/entity-selectors/AsyncAutocomplete';
import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';
import { templates } from '@lib/agent';
import { TemplateType } from '@shared-types/desygner';

const DEFAULT_WORKSPACE_ID = String(import.meta.env.VITE_WORKSPACE_ID);

export interface TemplateProps {
  value: number | null;
  onChange: (value: TemplateType | null) => void;
}

export default function Template(props: TemplateProps) {
  const { data, isLoading } = useQueryAfterWorkspaceLoaded({
    queryFn: () => templates.getAll({ workspaceId: DEFAULT_WORKSPACE_ID }),
    queryKey: ['templates'],
  });

  return (
    <AsyncAutocomplete
      options={(data?.data || []) as OptionType[]}
      isLoading={isLoading}
      placeholder="Select a template"
      {...props}
    />
  );
}
