import { useTranslation } from '@desygner/ui-common-translation';
import Card from '@layouts/assistants-session/drawer/conversation-history/skeleton/Card';
import Heading from '@layouts/assistants-session/drawer/heading';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';

const SKELETON_LIMIT = 3;

export default function Skeleton() {
  const { t } = useTranslation();

  return (
    <Stack sx={{ p: 6 }}>
      <Heading
        title={t('page.assistantsSession.drawer.title', {
          defaultValue: 'Conversation',
        })}
      />
      <List component={Stack} gap={5}>
        {Array.from({ length: SKELETON_LIMIT }, (_, i) => {
          return <Card key={i} />;
        })}
      </List>
    </Stack>
  );
}
