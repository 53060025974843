import useUserAuth from '@components/user-authentication/hooks/useUserAuth';
import EnterEmail from '@components/user-authentication/sections/steps/enter-email';
import EnterPassword from '@components/user-authentication/sections/steps/enter-password';
import SetupName from '@components/user-authentication/sections/steps/setup-name';
import SetupPassword from '@components/user-authentication/sections/steps/setup-password';
import VerifyEmail from '@components/user-authentication/sections/steps/verify-email';

const SIGN_UP_JOURNEY = [
  <EnterEmail />,
  <VerifyEmail />,
  <SetupPassword />,
  <SetupName />,
];

const LOGIN_JOURNEY = [<EnterEmail />, <EnterPassword />];

export default function ActiveForm() {
  const { activeStep, isUserAlreadyRegistered } = useUserAuth();

  const journey = isUserAlreadyRegistered ? LOGIN_JOURNEY : SIGN_UP_JOURNEY;

  const validStepIndex = Math.min(activeStep, journey.length - 1);

  return journey[validStepIndex];
}
