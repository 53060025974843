export default function Autocomplete() {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        loading: {
          padding: 0,
        },
      },
    },
  };
}
