import { auth } from '@lib/agent';
import { AuthResponseType } from '@shared-types/auth';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

export default function useCreateRefreshToken(
  onSuccess:
    | ((
        data: AxiosResponse<AuthResponseType, any>,
        variables: void,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined,
) {
  const token = window.localStorage.getItem('token');

  const {
    mutateAsync: mutateCreateRefreshTokenAsync,
    isPending: isRefreshTokenPending,
    isError: isRefreshTokenError,
    status: refreshTokenStatus,
  } = useMutation({
    mutationKey: ['refreshToken'],
    mutationFn: async () => await auth.refreshToken(token!),
    onSuccess,
    onError: (error) => {
      console.error('Failed to refresh token:', error);
    },
  });

  return {
    mutateCreateRefreshTokenAsync,
    isRefreshTokenPending,
    isRefreshTokenError,
    refreshTokenStatus,
  };
}
