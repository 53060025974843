import CONFIG_HEADER from '@layouts/main/config';
import { convertToRem } from '@lib/fonts';
import { styled } from '@mui/material';
import Paper from '@mui/material/Paper';

export const StyledMainWrapper = styled('main')(() => ({
  flexGrow: 1,
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  minHeight: `calc(100vh - ${convertToRem(
    CONFIG_HEADER.HEADER.HEIGHT + CONFIG_HEADER.HEADER.PADDING_Y,
  )})`,
  backgroundColor: theme.palette.background.card.light,
  display: 'flex',
  flexDirection: 'column',
}));
