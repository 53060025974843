import ActionButton from '@components/pages/knowledge-base/components/action-button';
import SourceViewer from '@components/pages/knowledge-base/components/table/cells/action/source-viewer';
import useDeleteFolderById from '@components/pages/knowledge-base/hooks/useDeleteFolderById';
import useDeleteSourceById from '@components/pages/knowledge-base/hooks/useDeleteSourceById';
import { useTranslation } from '@desygner/ui-common-translation';
import useWorkspace from '@hooks/useWorkspace';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Stack from '@mui/material/Stack';
import { FolderType } from '@shared-types/folders';
import { SourceType } from '@shared-types/sources';
import { CellContext } from '@tanstack/react-table';
import { useConfirm } from 'material-ui-confirm';
import { useState } from 'react';

export default function Action({
  row,
  handleToggleActionDrawer,
}: CellContext<FolderType | SourceType, unknown> & {
  handleToggleActionDrawer: (id: number) => void;
}) {
  const id = row.original.id;

  const isSourceOptimisticallyAdded = typeof id === 'undefined';

  const [folderOrSourceIdToDelete, setFolderOrSourceIdToDelete] = useState<
    number | null
  >(null);

  const { t } = useTranslation();

  const confirm = useConfirm();

  const { mutateFolder } = useDeleteFolderById();

  const { mutateSource } = useDeleteSourceById();

  const { setupMode } = useWorkspace();

  function handleDeleteSourceOrFolder({
    id,
    type,
  }: {
    id: number;
    type: 'folder' | 'source';
  }) {
    setFolderOrSourceIdToDelete(id);
    if (type === 'folder') {
      confirm({
        title: t('page.knowledgeBase.admin.actions.delete.folder.title', {
          defaultValue: 'Delete Folder',
        }),
        description: t(
          'page.knowledgeBase.admin.actions.delete.folder.content',
          {
            defaultValue: 'Are you sure you want to delete this folder?',
          },
        ),
      })
        .then(() => {
          mutateFolder({
            id,
          });
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setFolderOrSourceIdToDelete(null);
        });
      return;
    }

    //? then it is a source
    confirm({
      title: t('page.knowledgeBase.admin.actions.delete.source.title', {
        defaultValue: 'Delete Source',
      }),
      description: t('page.knowledgeBase.admin.actions.delete.source.content', {
        defaultValue: 'Are you sure you want to delete this source?',
      }),
    })
      .then(() => {
        mutateSource({
          id,
        });
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setFolderOrSourceIdToDelete(null);
      });
  }

  if (row.original.isCollected) return '-';

  //? if it is a folder
  if ('breadcrumb' in row.original) {
    //? non admin users can not create or delete folders
    if (setupMode === 'off') return '-';

    return (
      <Stack direction="row" gap={2}>
        <ActionButton
          aria-label="delete"
          disabled={
            id === folderOrSourceIdToDelete || isSourceOptimisticallyAdded
          }
          onClick={() => {
            handleDeleteSourceOrFolder({
              id: id,
              type: 'folder',
            });
          }}
        >
          <DeleteOutlineOutlinedIcon />
        </ActionButton>
      </Stack>
    );
  }

  //? then it is a source
  const isItWorkspaceOwnerButNotAdmin =
    row.original.owner.ownerType === 'workspace' && setupMode !== 'on';

  return (
    <Stack direction="row" gap={2}>
      <ActionButton
        aria-label="settings"
        onClick={() => handleToggleActionDrawer(id)}
        disabled={isSourceOptimisticallyAdded}
      >
        <SettingsOutlinedIcon />
      </ActionButton>

      {isItWorkspaceOwnerButNotAdmin ? null : (
        <ActionButton
          aria-label="delete"
          disabled={
            id === folderOrSourceIdToDelete || isSourceOptimisticallyAdded
          }
          onClick={() => {
            handleDeleteSourceOrFolder({
              id,
              type: 'source',
            });
          }}
        >
          <DeleteOutlineOutlinedIcon />
        </ActionButton>
      )}
      <SourceViewer id={id} />
    </Stack>
  );
}
