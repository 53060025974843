import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    return Promise.reject(error || 'Something went wrong');
  },
);

axiosInstance.interceptors.request.use(
  (request) => request,
  (error) => {
    console.error(error);
    return Promise.reject(error || 'Something went wrong');
  },
);

export default axiosInstance;
