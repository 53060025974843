//TODO: for translation service we will have an issue with Yup validation messages
import en from '@translations/en';
import * as Yup from 'yup';

const createFolderSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(60, en.page.knowledgeBase.modals.createFolder.form.name.max)
    .required(en.page.knowledgeBase.modals.createFolder.form.name.required),
});

export default createFolderSchema;
