import ListboxComponent from '@components/autocomplete-with-carousel/listbox';
import CardSkeleton from '@components/pages/assistants/sections/loading-skeleton/CardSkeleton';

const NUMBER_OF_CARDS = 5;

export default function LoadingSkeleton() {
  return (
    <ListboxComponent>
      {Array.from({ length: NUMBER_OF_CARDS }, (_, index) => (
        <CardSkeleton key={index} />
      ))}
    </ListboxComponent>
  );
}
