import usePaymentWithStripe from '@hooks/usePaymentWithStripe';
import CreditQuantity from '@layouts/main/sections/toolbar/components/credit-quantity';
import {
  StyledBox,
  StyledStack,
} from '@layouts/main/sections/toolbar/mini/desktop/sections/credit-usage/styles';
import Tooltip from '@layouts/main/sections/toolbar/mini/desktop/sections/credit-usage/tooltip';
import { ButtonBase } from '@mui/material';

export default function CreditUsage() {
  const { isLoading, handleRedirectToStripe } = usePaymentWithStripe();

  return (
    <ButtonBase
      disableRipple
      disableTouchRipple
      onClick={handleRedirectToStripe}
      disabled={isLoading}
      sx={{
        cursor: 'pointer',
      }}
    >
      <Tooltip>
        <StyledBox>
          <StyledStack direction="column" gap={2}>
            <CreditQuantity variant="miniDesktop" />
          </StyledStack>
        </StyledBox>
      </Tooltip>
    </ButtonBase>
  );
}
