import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

const StyledStackSocials = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: 'inherit',
    justifyContent: 'inherit',
  },
}));

export default StyledStackSocials;
