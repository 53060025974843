import type { ThemeType } from '@shared-types/theme';
import { alpha } from '@mui/material/styles';

export default function Button(theme: ThemeType) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius * 2,
          textTransform: 'none',
          '&, &:hover, &:focus': {
            boxShadow: 'none',
          },
          '&.MuiButton-text, &.MuiButton-outlined': {
            '&.MuiButton-colorPrimary': {
              '&:hover, &:active': {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            },
            '&.MuiButton-colorError': {
              '&:hover, &:active': {
                backgroundColor: alpha(theme.palette.error.main, 0.08),
              },
            },
            '&.MuiButton-colorSuccess': {
              '&:hover, &:active': {
                backgroundColor: alpha(theme.palette.success.main, 0.08),
              },
            },
            '&.MuiButton-colorWarning': {
              '&:hover, &:active': {
                backgroundColor: alpha(theme.palette.warning.main, 0.08),
              },
            },
            '&.MuiButton-colorInfo': {
              '&:hover, &:active': {
                backgroundColor: alpha(theme.palette.info.main, 0.08),
              },
            },
            '&.MuiButton-colorInherit': {
              backgroundColor: alpha(
                isLight
                  ? theme.palette.common.black
                  : theme.palette.common.white,
                0.05,
              ),
              borderColor: alpha(theme.palette.text.primary, 0.23),
              color: theme.palette.text.primary,
            },
          },
          '&.MuiButton-sizeLarge': {
            padding: theme.spacing(2.75, 5.5),
          },
          '&.MuiButton-sizeSmall': {
            padding: theme.spacing(1, 2.5),
          },
          '&.MuiButton-sizeMedium': {
            padding: theme.spacing(1.5, 4),
          },
        },
      },
    },
  };
}
