import { styled } from '@mui/material';
import { useStepEditor } from 'sequential-workflow-designer-react';
import { BranchedStep, Branches } from 'sequential-workflow-model';
import Typography from '@mui/material/Typography';
import InputBlock from './InputBlock';
import { BagItem } from './InputBlockWithContext';
import { getStepPropertiesDefinition } from '@components/pages/command-designer/config';
import Providers from '@providers/index';

export const StyledHeader = styled('div')`
  text-transform: capitalize;
  padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(6)};
`;

export const StyledModifiableTitle = styled('input')(({ theme }) => ({
  appearance: 'none',
  boxSizing: 'border-box',
  background: theme.palette.background.card.light,
  color: theme.palette.text.primary,
  outline: 'none',
  width: '100%',
  border: 'none',
  padding: theme.spacing(2, 0, 3, 0),
  fontSize: theme.typography.subtitle2.fontSize,
  lineHeight: 1.8,

  '&::placeholder': {
    color: theme.palette.text.primary,
  },
}));

export const StyledProperties = styled('div')(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  padding: theme.spacing(4, 6),
  borderTop: `1px solid ${theme.palette.background.card.main}`,
}));

export default function StepEditor() {
  const {
    type,
    componentType,
    name,
    setName,
    properties,
    setProperty,
    step,
    notifyChildrenChanged,
  } = useStepEditor();

  const stepPropertiesDefinition = getStepPropertiesDefinition({
    componentType,
    type,
    name,
  });

  function onNameChanged(value: string) {
    setName(value);
  }

  function onPropertyChanged(propertyId: string, value: BagItem | BagItem[]) {
    if (step.componentType === 'switch' && propertyId === 'branches') {
      value = (value as BagItem[]).filter((v) => v.data);

      const { branches } = step as BranchedStep;
      const existingBranches = Object.keys(branches);

      if (existingBranches.length < value.length) {
        for (const { data } of value) {
          if (data && !branches[data]) branches[data] = [];
        }
      } else if (existingBranches.length > value.length) {
        if (value.length >= 2) {
          for (const branch of existingBranches) {
            if (!value.find((v) => v.data === branch)) {
              delete branches[branch];
            }
          }
        } else {
          renameBranch(branches, value);
        }
      } else {
        renameBranch(branches, value);
      }

      setTimeout(() => notifyChildrenChanged());
    }

    setProperty(propertyId, value);
  }

  function renameBranch(branches: Branches, value: BagItem[]) {
    const existingBranches = Object.keys(branches);

    let newBranchName: string | null = null;
    let oldBranchName: string | null = null;

    for (const { data } of value) {
      if (data && !branches[data]) {
        newBranchName = data as string;
        break;
      }
    }

    for (const branch of existingBranches) {
      if (!value.find((v) => v.data === branch)) {
        oldBranchName = branch;
        break;
      }
    }

    if (newBranchName && oldBranchName) {
      branches[newBranchName] = branches[oldBranchName];
      delete branches[oldBranchName];
    }
  }

  return (
    <Providers>
      <StyledHeader>
        <Typography variant="body1">{type}</Typography>
      </StyledHeader>

      <StyledProperties>
        <StyledModifiableTitle
          value={name}
          onChange={(e) => onNameChanged(e.target.value)}
        />

        {stepPropertiesDefinition
          .filter((p) => !p.triggerArgument)
          .map((property) => (
            <InputBlock
              {...property}
              key={`property-${property.id}`}
              value={(properties && properties[property.id]) || ''}
              onChange={(value: BagItem | BagItem[]) =>
                onPropertyChanged(property.id, value)
              }
            />
          ))}
      </StyledProperties>
    </Providers>
  );
}
