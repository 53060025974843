import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { convertToRem } from '@lib/fonts';
import { styled } from '@mui/material';

const StyledTextarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    resize: none;
    width: 100%;
    padding: ${theme.spacing(2, 3)};
    font-size: ${theme.typography.body2.fontSize};
    line-height: ${theme.typography.body2.lineHeight};
    border-radius: ${convertToRem(theme.shape.borderRadius * 2.5)};
    color: ${theme.palette.text.primary};
    background: ${theme.palette.background.paper};
    border: none;
    &:hover {
      box-shadow: 0 0 0 1px ${theme.palette.neutral.black};
    }
    &:focus {
      box-shadow: 0 0 0 2px ${theme.palette.primary.main};
    }
    &:focus-visible {
      outline: 0;
    }
`,
);

export default StyledTextarea;
