import { ThemeType } from '@shared-types/theme';

export default function AppBar(theme: ThemeType) {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          padding: theme.spacing(3, 3),
          flexDirection: 'row',
          alignItems: 'center',
        },
      },
    },
  };
}
