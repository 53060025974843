import { styled } from '@mui/material';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { RunnerResult } from './run.service';
import { StyledHeader, StyledProperties as Properties } from '@components/pages/command-designer/sections/step-editor';

export type RunnerProps = {
  ongoingRun: Promise<RunnerResult>|null;
  onClose: () => void;
};

const StyledProperties = styled(Properties)(({ theme }) => ({
  '& > button': {
    marginBottom: theme.spacing(4),
    width: '100%',
  },
}));

const StyledSpinnerWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export function Runner ({ ongoingRun, onClose }: RunnerProps) {
  const [runOutput, setRunOutput] = useState<string|null>(null);

  useEffect(() => {
    if (ongoingRun) {
      const currentRun = ongoingRun;

      ongoingRun.then((result) => {
        if (currentRun !== ongoingRun) return;
        setRunOutput(result.output.join('\n'));
      });
    }
  }, [ongoingRun]);

  return (
    <>
      <StyledHeader>
        <Typography variant="body1">Command Run</Typography>
      </StyledHeader>

      <StyledProperties>
        {runOutput
          ? <>
            <pre>{runOutput}</pre>
            <Button startIcon={<CloseIcon />} onClick={onClose}>Close</Button>
          </>
          : <>
            <StyledSpinnerWrapper><CircularProgress /></StyledSpinnerWrapper>
            <Button startIcon={<StopCircleIcon />} onClick={onClose}>Interrupt</Button>
          </>
        }
      </StyledProperties>
    </>
  );
}
