// components/DocViewer/CustomFileRender.tsx
import { DocRenderer } from '@cyntler/react-doc-viewer';
import Box from '@mui/material/Box';
import {
  FILETYPE as FileType,
  WEIGHT,
} from '@components/pages/knowledge-base/components/table/cells/action/source-viewer/custom-file-render/utils';

type Document = {
  uri: string;
  fileName: string;
};

type MainState = {
  currentDocument: Document;
};

export default function CustomFileRender({
  mainState: { currentDocument },
}: {
  mainState: MainState;
}): React.ReactElement {
  const googleDocsViewerUrl = `https://docs.google.com/gview?url=${encodeURIComponent(
    currentDocument.uri,
  )}&embedded=true`;

  return (
    <Box sx={{ width: '100%' }}>
      <iframe
        className="doc"
        style={{
          width: '100%',
          height: '75svh',
          border: 'none',
          backgroundColor: 'white',
        }}
        src={googleDocsViewerUrl}
        title={`Document Viewer - ${currentDocument.fileName}`}
      />
    </Box>
  );
}

CustomFileRender.fileTypes = FileType;
CustomFileRender.weight = WEIGHT;
