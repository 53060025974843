/* eslint-disable import/prefer-default-export */
import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import ListItem from '@mui/material/ListItem';

export const StyledStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.card.light,
  padding: theme.spacing(3),
  borderRadius: theme.spacing(4),
  minHeight: theme.spacing(70.2),
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  height: '100%',
  borderRadius: theme.shape.borderRadius * 2,
  borderWidth: 1,
  borderStyle: 'solid',
  cursor: 'pointer',
  borderColor: theme.palette.background.secondary,
  backgroundColor: theme.palette.background.paper,

  '&:hover': {
    borderColor: theme.palette.primary.light,
  },
}));
