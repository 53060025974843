import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function GroupWrapper({
  title,
  children,
}: React.PropsWithChildren<{ title: React.ReactNode }>) {
  return (
    <Stack>
      <Typography variant="body2" color="text.disabled">
        {title}
      </Typography>
      {children}
    </Stack>
  );
}
