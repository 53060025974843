export const FILETYPE = [
  'xls',
  'application/vnd.ms-excel',
  'xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'ppt',
  'application/vnd.ms-powerpoint',
  'pptx',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'doc',
  'application/msword',
  'docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const WEIGHT = 1;
