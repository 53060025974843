import getRefreshToken from '@lib/auth/email';
import { signUp } from '@lib/auth/fingerprint';
import {
  checkUserAuthType,
  getDecodedToken,
  isValidToken,
  sendTokenToExtension,
  setSession,
} from '@lib/jwt';
import { UserType } from '@shared-types/auth';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';

const THRESHOLD_TIME_TO_REFRESH_TOKEN = 120_000;

export const AuthContext = createContext<{
  me: UserType | null;
  isUserAuthenticated: boolean;
  handleUpdateMe: (token: string) => void;
  handleAttemptedToLogin: (value: boolean) => void;
  attemptedToLogin: boolean | null;
  handleIsUserAuthenticated: (value: boolean) => void;
} | null>(null);

export default function AuthProvider({ children }: React.PropsWithChildren) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  const [me, setMe] = useState<UserType | null>(() => {
    return getDecodedToken(
      window.localStorage.getItem('token'),
    ) as UserType | null;
  });

  const [attemptedToLogin, setAttemptedToLogin] = useState<boolean | null>(
    null,
  );

  const intervalIDRef = useRef<NodeJS.Timeout>();

  const handleUpdateMe = useCallback((token: string) => {
    setMe(getDecodedToken(token));
  }, []);

  const handleAttemptedToLogin = useCallback((value: boolean) => {
    setAttemptedToLogin(value);
  }, []);

  const handleIsUserAuthenticated = useCallback((value: boolean) => {
    setIsUserAuthenticated(value);
  }, []);

  useEffect(() => {
    if (me == null) return;
    const currentTimeInMS = Date.now();
    const tokenExpirationInMS = me.exp * 1000;
    const differenceInMS = tokenExpirationInMS - currentTimeInMS;
    const timeToRefreshToken = differenceInMS - THRESHOLD_TIME_TO_REFRESH_TOKEN;
    if (timeToRefreshToken <= 0) return;

    async function refreshTokenIfNeeded() {
      try {
        const response = await getRefreshToken();
        if (response?.data.status === 'Ok') {
          const token = response.data.token;
          setSession(token);
          handleUpdateMe(token);
        }
      } catch (error) {
        console.error('Failed to refresh token:', error);
      }
    }

    intervalIDRef.current = setInterval(
      refreshTokenIfNeeded,
      timeToRefreshToken,
    );

    return () => clearInterval(intervalIDRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me?.uuid]);

  useEffect(() => {
    (async function onLoad() {
      try {
        //? on user entering the site, check if the user is authenticated with which method
        const existingToken = window.localStorage.getItem('token')!;

        //? if there is not a token in local storage sign up and login the user with web_fingerprint method
        const authType = checkUserAuthType(existingToken);
        const isTokenValid = existingToken && isValidToken(existingToken);

        //? if user is authenticated with all methods rather than web_fingerprint method
        if (authType === 'oauth2' || authType === 'email') {
          if (isTokenValid) {
            setSession(existingToken);
            setIsUserAuthenticated(true);
            handleUpdateMe(existingToken);
            sendTokenToExtension(existingToken);
            return;
          }

          //? if user token is expired and user has not attempted to login yet
          if (attemptedToLogin == null) {
            setAttemptedToLogin(false);
            return;
          }
        }

        //? if user has not attempted to login yet we wait for the user to attempt to login before we proceed with web_fingerprint method
        if (attemptedToLogin === false) return;

        if (authType === 'web_fingerprint') {
          const isTokenValidForWebFingerprintMethod =
            isTokenValid && authType === 'web_fingerprint';

          if (isTokenValidForWebFingerprintMethod) {
            setSession(existingToken);
            setIsUserAuthenticated(true);
            handleUpdateMe(existingToken);
            sendTokenToExtension(existingToken);
            return;
          }
        }

        if (!executeRecaptcha) return;

        const recaptchaToken = await executeRecaptcha();
        //? or user has cleared the local storage and we need to login the user again
        const response = await signUp(recaptchaToken);
        if (response && response.data.status === 'Ok') {
          const webFingerToken = response.data.token;
          setSession(webFingerToken);
          handleUpdateMe(webFingerToken);
          setIsUserAuthenticated(true);
          sendTokenToExtension(webFingerToken);
        } else {
          setAttemptedToLogin(false);
          toast.warn(
            `We couldn't confirm you as a human. ` +
              'Please try your request again, or sign in with your email.',
          );
        }
      } catch (err: unknown) {
        setIsUserAuthenticated(false);
        console.error(err);
      }
    })();
  }, [attemptedToLogin, executeRecaptcha, handleUpdateMe]);

  const value = useMemo(
    () => ({
      me,
      handleUpdateMe,
      isUserAuthenticated,
      handleAttemptedToLogin,
      attemptedToLogin,
      handleIsUserAuthenticated,
    }),

    [
      attemptedToLogin,
      handleAttemptedToLogin,
      handleIsUserAuthenticated,
      handleUpdateMe,
      isUserAuthenticated,
      me,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
