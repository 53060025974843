import paths from '@router/paths';

const NAV_ITEMS = [
  {
    label: 'layout.main.sections.header.navigation.first',
    defaultLabel: 'Enterprise Solutions',
    link: paths.enterprise.pathname,
  },
];

const CONFIG = {
  DRAWER_WIDTH: 280,
  NAV_ITEMS,
};

export default CONFIG;
