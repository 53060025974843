export default function Dialog() {
  return {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingTop: 0,
          paddingRight: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingTop: 0,
          paddingRight: 0,
        },
      },
    },
  };
}
