import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
// import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { format } from 'date-fns';
// import CheckIcon from '@mui/icons-material/Check';
// import CONFIG from '@components/pages/users/config';
import {
  StyledTab,
  StyledTabList,
  StyledTabPanel,
} from '@components/pages/knowledge-base/components/table/action-drawer/styles';
import TabContext from '@mui/lab/TabContext';
import { useEffect, useState } from 'react';
import calculateFullName from '@lib/calculateFullName';
import { useTranslation } from '@desygner/ui-common-translation';
import GroupWrapper from '@components/pages/knowledge-base/components/table/action-drawer/panels/details/group-wrapper';
import Folder from '@components/pages/users/side-panel/Folder';
import useGetAllSourceConfigurations from '@hooks/useGetAllSourceConfigurations';
import {
  RING_FENCED_CONNECTORS,
  getConnectedId,
  getEmailAlias,
} from '@components/pages/users/side-panel/ringfenceHelper';
import useGetMembershipById from '@components/pages/users/hooks/useGetMembershipById';
import Engagement from '@components/pages/users/engagement';

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  membershipId: string | null;
};

type TabValueType = 'details' | 'access';

export default function SidePanel({ isOpen, setIsOpen, membershipId }: Props) {
  const { t } = useTranslation();
  const { sourceConfigs = [] } = useGetAllSourceConfigurations();
  const [activeTab, setActiveTab] = useState<TabValueType>('details');
  const handleChange = (_event: React.SyntheticEvent, tab: TabValueType) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setActiveTab('details');
  }, [membershipId]);

  const { membershipData: membership } = useGetMembershipById({
    membershipId,
  });

  let name = '';

  if (membership) {
    if (!membership.user?.firstName && !membership.user?.lastName) {
      name =
        membership.user?.email ||
        membership.emailInvite ||
        getEmailAlias(membership) ||
        'Unknown User';
    }

    name = calculateFullName({
      firstName: membership.user?.firstName,
      lastName: membership.user?.lastName,
    });
  } else {
    return <></>; // TODO
  }

  const emailAlias = getEmailAlias(membership);

  return (
    <Drawer open={isOpen} anchor="right" onClose={() => setIsOpen(false)}>
      <Stack
        direction="row"
        sx={{ p: 4, justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Stack gap={2}>
          <Typography variant="subtitle1">{name}</Typography>
        </Stack>

        <IconButton onClick={() => setIsOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Stack>

      <Divider />

      <Box sx={{ p: 4, width: '460px', maxHeight: '100%', overflow: 'auto' }}>
        <TabContext value={activeTab}>
          <StyledTabList
            variant="fullWidth"
            onChange={handleChange}
            aria-label="action drawer tabs"
            TabIndicatorProps={{
              style: {
                bottom: '50%',
              },
              sx: {
                height: 'calc(100% - 5px)',
                transform: 'translateY(50%)',
                backgroundColor: 'background.card.light',
                borderRadius: 2,
              },
            }}
          >
            <StyledTab label={'User Details'} value="details" />
            <StyledTab label={'Knowledge Access'} value="access" />
          </StyledTabList>

          <StyledTabPanel value="details">
            <Card
              sx={{ marginTop: 4, backgroundColor: 'background.card.main' }}
            >
              <Stack direction="row-reverse" justifyContent="space-between">
                <Stack gap={4}>
                  <Avatar
                    sx={{ width: 64, height: 64 }}
                    src={membership?.user?.profilePicture}
                    alt={`${name} avatar`}
                  />
                  {/* TODO: status */}
                  {/* <Badge color="secondary" badgeContent={<CheckIcon />} /> */}
                </Stack>
                <Stack gap={4}>
                  <GroupWrapper
                    title={t('page.users.sidePanel.details.id', {
                      defaultValue: '#id',
                    })}
                  >
                    {membership?.id}
                  </GroupWrapper>
                  <GroupWrapper
                    title={t('page.users.sidePanel.details.firstName', {
                      defaultValue: 'First Name',
                    })}
                  >
                    {membership?.user?.firstName || '-'}
                  </GroupWrapper>
                  <GroupWrapper
                    title={t('page.users.sidePanel.details.lastName', {
                      defaultValue: 'Last Name',
                    })}
                  >
                    {membership?.user?.lastName || '-'}
                  </GroupWrapper>
                  <GroupWrapper
                    title={t('page.users.sidePanel.details.email', {
                      defaultValue: 'Email',
                    })}
                  >
                    {membership?.user?.email ||
                      membership?.emailInvite ||
                      emailAlias ||
                      '-'}
                  </GroupWrapper>

                  {emailAlias &&
                    emailAlias !==
                      (membership?.user?.email || membership?.emailInvite) && (
                      <GroupWrapper title={'Secondary Email'}>
                        {emailAlias}
                      </GroupWrapper>
                    )}

                  <GroupWrapper
                    title={t('page.users.sidePanel.details.inviteDate', {
                      defaultValue: 'Invite Date',
                    })}
                  >
                    {membership?.inviteDate &&
                      format(new Date(membership?.inviteDate), 'MMM dd, yyyy')}
                  </GroupWrapper>
                  <GroupWrapper
                    title={t('page.users.sidePanel.details.role', {
                      defaultValue: 'Role',
                    })}
                  >
                    {membership &&
                    'admin' in membership.permissions &&
                    membership.permissions.admin
                      ? 'Admin'
                      : 'Member'}
                  </GroupWrapper>
                </Stack>
              </Stack>
            </Card>

            <Card
              sx={{ marginTop: 4, backgroundColor: 'background.card.main' }}
            >
              <Typography variant="h5" component="h5">
                Engagement
              </Typography>
              <Engagement sx={{ marginTop: 4 }} membershipId={membershipId} />
            </Card>
          </StyledTabPanel>

          <StyledTabPanel value="access">
            <Card sx={{ backgroundColor: 'background.card.main' }}>
              <Typography variant="h5" component="h5">
                Linked identities
              </Typography>
              {sourceConfigs
                .filter((sourceConfig) =>
                  Boolean(RING_FENCED_CONNECTORS[sourceConfig.handler]),
                )
                .map((sourceConfig) => (
                  <GroupWrapper
                    key={`connector-${sourceConfig.id}`}
                    title={RING_FENCED_CONNECTORS[sourceConfig.handler].name}
                  >
                    {getConnectedId(
                      membership,
                      RING_FENCED_CONNECTORS[sourceConfig.handler].type,
                    ) || '-'}
                  </GroupWrapper>
                ))}
            </Card>

            <Card
              sx={{ marginTop: 4, backgroundColor: 'background.card.main' }}
            >
              <Typography variant="h5" component="h5">
                Knowledge Base access
              </Typography>
              {membershipId && <Folder membershipId={membershipId} />}
            </Card>
          </StyledTabPanel>
        </TabContext>
      </Box>
    </Drawer>
  );
}
