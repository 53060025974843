import Markdown from '@components/markdown';
import SectionHeading from '@components/pages/search/components/SectionHeading';
import TextToSpeechIconButton from '@components/pages/search/components/TextToSpeechIconButton';
import { useTranslation } from '@desygner/ui-common-translation';
import useGetThreadIdFromQueryParam from '@hooks/useGetThreadFromQueryParam';
import PauseCircleOutlinedIcon from '@mui/icons-material/PauseCircleOutlined';
import RecordVoiceOverRoundedIcon from '@mui/icons-material/RecordVoiceOverRounded';
import Icon from '@mui/material/Icon';
import { RootType } from '@state/store';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  answer?: string;
  id: string;
  type: 'websocket' | 'history';
};

const ORB_ICON_ELEMENT = (
  <Icon>
    <img
      src="https://assets.knowz.com/assets/svg/orb---small.png"
      alt="answer section"
    />
  </Icon>
);

export default function Answer({ answer, id, type }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { threadId } = useGetThreadIdFromQueryParam();
  const currentAudioPlayingId = useSelector(
    (state: RootType) => state.searchResult.currentAudioPlayingId,
  );
  const audioMetadata = useSelector(
    (state: RootType) => state.searchResult.audioMetadata,
  );
  const WSTextToSpeechIsLoading = useSelector(
    (state: RootType) => state.searchResult.WSTextToSpeechIsLoading,
  );

  if (answer == null || answer.length === 0) return null;

  async function togglePlayPause() {
    dispatch({
      type: 'searchResult/textToSpeechEmitted',
      payload: {
        id,
        threadId,
        text: answer,
      },
    });
  }

  return (
    <SectionHeading
      title={
        <>
          {t('page.search.sections.answer', {
            defaultValue: 'Answer',
          })}
          &nbsp;
          {typeof window.MediaSource === 'function' && (
            <TextToSpeechIconButton
              disabled={WSTextToSpeechIsLoading || type === 'websocket'}
              text={answer}
              onClick={togglePlayPause}
            >
              {audioMetadata &&
              currentAudioPlayingId &&
              audioMetadata[id]?.isPlaying ? (
                <PauseCircleOutlinedIcon />
              ) : (
                <RecordVoiceOverRoundedIcon />
              )}
            </TextToSpeechIconButton>
          )}
        </>
      }
      icon={ORB_ICON_ELEMENT}
    >
      <Markdown>{answer}</Markdown>
    </SectionHeading>
  );
}
