import { KnowledgeBaseContext } from '@components/pages/knowledge-base/providers/KnowledgeBaseProvider';
import { useContext } from 'react';

export default function useKnowledgeBaseData() {
  const context = useContext(KnowledgeBaseContext);

  if (!context) {
    throw new Error(
      'useKnowledgeBaseData must be used within an KnowledgeBaseProvider',
    );
  }

  return context;
}
