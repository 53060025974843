import ListboxComponent from '@components/autocomplete-with-carousel/listbox';
import PaperComponent from '@components/autocomplete-with-carousel/paper';
import PopperComponent from '@components/autocomplete-with-carousel/popper';
import useResponsive from '@hooks/useResponsive';
import { ChipTypeMap, PopperProps } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import React from 'react';

export default function AutocompleteWithCarousel<
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>({
  sx,
  ListboxProps,
  firstSlide,
  ...otherProps
}: AutocompleteProps<
  Value,
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
> & { firstSlide?: React.ReactNode }) {
  const isItMobileOrTablet = useResponsive('down', 'md');

  return (
    <Autocomplete
      ListboxComponent={ListboxComponent}
      ListboxProps={{ ...ListboxProps, firstSlide }} //TODO: Augmented this type into autocomplete
      PaperComponent={PaperComponent}
      PopperComponent={
        PopperComponent as React.JSXElementConstructor<PopperProps> | undefined
      }
      fullWidth
      open
      popupIcon={null}
      clearOnEscape
      disableListWrap
      disabledItemsFocusable
      selectOnFocus
      onClose={() => null}
      limitTags={isItMobileOrTablet ? 2 : 4}
      getLimitTagsText={(more) => `+${more}`}
      sx={{
        transform: 'none!important',
        ...sx,
      }}
      {...otherProps}
    />
  );
}
